import React from "react";

// Customizable Area Start
import {
  Box,
  TextField,
  InputAdornment,
  Table,
  TableHead,
  TableBody,
  TableContainer,
  TableCell,
  TableRow,
  Paper,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Chip,
  Grid,
  Drawer,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Tooltip,
  CircularProgress
} from "@material-ui/core";
import { styled ,createTheme,MuiThemeProvider} from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { logo, userpic, right, file, filter, backk,Services, request ,tooltipIconpage} from "./assets";
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import MenuIcon from '@material-ui/icons/Menu';
import ArrowForwardIosOutlinedIcon from "@material-ui/icons/ArrowForwardIosOutlined";
import EditIcon from "@material-ui/icons/Edit";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import SearchIcon from '@material-ui/icons/Search';
import JobListing from "../../../blocks/joblisting/src/JobListing.web";
import StarRateIcon from '@material-ui/icons/StarRate';
import VpnLockIcon from '@material-ui/icons/VpnLock';
import { StyledChip } from "./Tasks.web";
import TasksTableContent from "./components/TasksTableContent.web";
// Customizable Area End

import TaskListController, { configJSON, Props } from "./TaskListController";

// Customizable Area Start
const theme = createTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: "2em",
        backgroundColor: "#fff",
        zIndex:999,
        boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
        width:"450px",
        borderRadius:"12px",
        padding:"10px"
      }
    }
  }
});

const DeadlineTypography = styled(Typography)({
  color: "#262626",
  '&[data-state="false"]': {
    color: "#DC2626"
  },
})
// Customizable Area End


export default class TaskList extends TaskListController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  getStatus = (task:any) => {
    return task?.attributes?.status?.replace(/_/g, " ") || "unknown_status";
  };
  getSeeMoreButtonText = () => {
    return this.state.isExpanded ? "See less" : "See more...";
  };
  shouldShowSeeMoreButton = () => {
    const description = this.state.taskView?.attributes?.description;
    return this.checkIfContentIsLong(description);
  };
  fullText = () => {
    const desc = this.state.taskView?.attributes?.description;
    return desc;
  };
  renderDescription = () => {
    const fullText = this.fullText();

    if (this.state.isExpanded) {
      return fullText; }
 const truncatedText = `${fullText?.substring(0, 200)}...`;
    return (
      <>
        {truncatedText}{this.shouldShowSeeMoreButton() && this.renderSeeMoreButton()}
      </>); };

  renderSeeMoreButton = () => (
    <button onClick={this.handleSeeMoreClick}
      style={{
        position: "absolute",
        bottom: "2px",
        right: "-8px",
        padding: "3px",
        color: "#355EE7",
        border: "none",
        background: "white",
        cursor: "pointer",
        fontSize: "11px",
        fontWeight: 400,
        zIndex: 1,
        width: "12%",
        display: "flex",
        justifyContent: "flex-start",}}>
      {this.getSeeMoreButtonText()}</button>
  );

  checkIfContentIsLong = (description: string) => {
    const maxLength = 200;
    return description && description.length > maxLength;};
  drawer = () => (
    <DrawerBox style={{ display: this.state.mobileOpen ? 'block' : '' }
    }>
      <div className="toolbar" />
      <Divider />
      <div className="logobox">
        <img src={logo} alt="logo" className="logoimag" data-test-id="logoImg" /></div>
      {this.state.mobileOpen && <CloseIcon style={{ position: 'fixed', top: '30px', left: '210px', cursor: 'pointer' }}
       onClick={this.handleclosesidebar} />}
      <div className="settingbox"></div>
      <List className="MainListBox">
        <ListItem onClick={this.handleNavigateDashboard} className="listbox1">
          <ListItemIcon><HomeOutlinedIcon className="icondrawer1" />
          </ListItemIcon>
          <ListItemText className="listtile1" data-test-id="Dashboard">
            Dashboard</ListItemText>
        </ListItem>
        <ListItem className="listbox" onClick={this.handleSettingIn}> <ListItemIcon>
          <SettingsOutlinedIcon className="icondrawer2" /></ListItemIcon>
          <ListItemText className="listtile" data-test-id="Setting">Settings
          </ListItemText> </ListItem>
      </List>
      <Box className="userProfile">
        <Box><img src={userpic} className="imageBox" />
        </Box>
        <Box><Typography className="userName">sass
        </Typography>
          <Typography className="userContent">Side subnav item</Typography></Box>
      </Box>
    </DrawerBox>)
  buttonMenu = () => (
    <ButtonBox className="buttonbox"> <IconButton
        className="iconbtn"
        color="inherit"
        aria-label="open drawer"
        edge="start"
        onClick={this.handleDrawerToggle}
        style={{ marginRight: '24px', color: '#000' }} >
        <MenuIcon /></IconButton>
    </ButtonBox>)
  sidebarr = () => {
    return (this.state.mobileOpen &&
      <> <div className="offcanvascss" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
          {this.drawer()}  </div>
      </> )}

  sideBarr2 = () => {
    return (
      this.state.mobileOpen && <div className="offcanvasbackdrop" onClick={this.handleclosesidebar}></div> )
  }

  formatDate = () => {
    const createdAt = this.state.taskView.attributes?.created_at;
    const formattedDate = createdAt
      ? new Date(createdAt).toLocaleDateString('en-GB', {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
      })
      : '';
    return formattedDate;
  };
  seeMore = () => {
    const description = this.state.taskView?.attributes?.description;
    return this.checkIfContentIsLong(description);
  };
  getEditIconStyle = () => {
    return this.state?.taskView?.attributes?.status === "draft"
      ? { display: "block" }
      : { display: "none" };
  };
 

  drawerContents = () => (
    <Box style={{
        width: "100vh",
        maxWidth: "812px",
        padding: "16px",
        paddingLeft: "31px",
        height: "auto",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        fontSize: "Dm sans", }} >
      <Grid  container
        style={{ display: "flex", justifyContent: "space-between",marginBottom:"11px" }} >
        <Box style={{
          display:"flex"
        }}> 
          <IconButton style={{
            cursor: "pointer",padding:"7px"}} onClick={this.toggleDrawerClose}>
            <img src={backk} alt=""
             style={{
              width: "18px",
              height: "18px"}} />
          </IconButton>
          <IconButton style={this.getEditIconStyle()}
            onClick={this.handleEditProject}>
            <EditIcon style={{
              color: "#BFBFBF",
              width: "18px",
              height: "18px" }} />
          </IconButton> </Box>
        <Box style={{ display: "flex", alignItems: "center" }}>
          <Typography style={{ color: "#8C8C8C", fontSize: "14px",
           fontWeight: 400, 
           fontFamily: "Dm sans" }}>
            Created: {this.formatDate()}</Typography>
          <IconButton
            className={"closeButton"}
            onClick={this.toggleDrawerClose} >
            <CloseIcon style={{
              color: "#BFBFBF",
              width: "18px",
              height: "18px"  }} />
          </IconButton></Box>
      </Grid>

      <Box style={{ width: "100%",
       height: "105px", 
       gap: "12px" ,marginBottom:"9px"}}>
        <StyledChip data-state={this.state.taskView.attributes.state.toLowerCase()} label={this.state.taskView.attributes.state} />
        <Grid container style={{ display: "flex", 
        marginTop: "24px" }}>
          <Grid item> <img src={file} alt="file" /> </Grid>
          <Grid item style={{ marginLeft: '10px' }}> <Typography
              variant="body1"
              style={{fontFamily: "DM Sans",
                fontSize: "18px",
                fontWeight: "400",
                lineHeight: "23.44px",
                textAlign: "left",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: "70ch",
                textTransform: "capitalize",
                whiteSpace: "nowrap",}}
              title={this.state.taskView?.attributes?.task_name} >
              {this.state.taskView?.attributes?.task_name}
            </Typography>
            <StyledChip 
              style={{ 
                width: "auto",
                padding: "2px 12px",
                minWidth: 72,
                fontWeight: 400,
                backgroundColor: "#F5F5F5"
              }}
              label={this.state?.taskView?.attributes?.status?.replace(/_/g, " ")}
            />
            </Grid>
        </Grid>  </Box>
      <Box style={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-around", }}  >
        <Box style={{ margin: "8px 0" }}>
          <Typography style={{ color: "#8C8C8C",
           fontSize: "12px", fontWeight: 400,
           fontFamily: "Dm sans" }}>
            Trademark Name </Typography>
          <Typography
            title={this.state.taskView?.attributes?.trademark_name}
            style={{ fontSize: "16px", 
            fontWeight: 500, fontFamily: "Dm sans",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "calc(30ch + 1em)",
              textTransform: "capitalize"}}>
            {this.state.taskView?.attributes?.trademark_name}</Typography>  </Box>
        <Box style={{ margin: "8px 0" }}>
          <Typography style={{ color: "#8C8C8C",
           fontSize: "12px", fontWeight: 400,
            fontFamily: "Dm sans" }}>
            Trademark Application Number  </Typography>

          <Typography title={this.state.taskView?.attributes?.trademark_application_number}
            style={{ fontSize: "16px", fontWeight: 500, fontFamily: "Dm sans", textTransform: "capitalize" }}>
            {this.state.taskView?.attributes?.trademark_application_number}
            </Typography>
        </Box><Divider style={{
          margin: "16px 0px"
        }} />
        <Box style={{ margin: "8px 0" }}>
          <Typography style={{ color: "#8C8C8C", fontSize: "12px", fontWeight: 400, 
          fontFamily: "Dm sans" }}>
            Jurisdiction
          </Typography>
          <Typography
            style={{ fontSize: "16px", fontWeight: 500,
             fontFamily: "Dm sans" }}
          >{this.state.taskView?.attributes?.jurisdiction}
          </Typography>
        </Box>
        <Box style={{ margin: "8px 0" }}>
          <Typography style={{ color: "#8C8C8C", fontSize: "12px", fontWeight: 400,
           fontFamily: "Dm sans" }}>
            Legal Service Type
          </Typography>
          <Typography style={{ fontSize: "16px",
           fontWeight: 500, fontFamily: "Dm sans",
            textTransform: "capitalize" }} >
              {this.state.taskView?.attributes?.legel_service_type?.name}</Typography>
        </Box> 
        {
          this.state.taskView.attributes.created_at && (
            <Box
              data-test-id={`task-${this.state.taskView.id}-deadline`}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                margin: "8px 0",}} >
              <Typography
                style={{ color: "#8C8C8C", fontSize: "12px", fontWeight: 400, fontFamily: "Dm sans" }} >
                Deadline:
              </Typography>
              <Box style={{ display: "flex", gap: "4px" }}>
                <DeadlineTypography data-state={new Date(this.state.taskView.attributes.created_at).getTime() < new Date().getTime()} style={{ fontSize: "16px", fontWeight: 500, fontFamily: "Dm sans" }}>
                  { new Date(this.state.taskView.attributes.created_at).toISOString().split("T")[0] }
                </DeadlineTypography>
                <Box style={{ color: "#8C8C8C" }}>
                  <CalendarTodayIcon 
                  style={{
                    color: "#BFBFBF",
                    width: "18px",
                    height: "18px" }} />
                </Box>
              </Box>
            </Box>
          )
        }
        <Divider style={{
          margin: "16px 0px"
        }} />
        <Box style={{ width: "100%", marginTop: "16px" }}>
          <Typography
            style={{ color: "#8C8C8C", fontSize: "12px", fontWeight: 400, fontFamily: "Dm sans" }}
          >
            Description
          </Typography>
          <Typography
            variant="subtitle2"
            style={{
              color: "#8C8C8C",
              marginLeft: 4,
              display: "flex",
              justifyContent: "flex-end",
              paddingRight: "1vw",
              textTransform: "capitalize"
            }}
          >
            {this.formatDate()}
          </Typography>
          <Typography
            style={{
              fontSize: "16px",
              fontWeight: 500,
              fontFamily: "Dm sans",
              wordWrap: 'break-word',
              whiteSpace: 'normal',
              overflow: 'hidden',
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: this.state.isExpanded ? 'none' : 2,
              position: "relative",
            }}
            className="description-text"
          >
            {this.renderDescription()}
          </Typography>

        </Box>

        <Box style={{ width: "100%", marginTop: "16px" }}>
          <Typography
            style={{ color: "#8C8C8C", fontSize: "12px", fontWeight: 400, fontFamily: "Dm sans" }}
          >
            Attachments
          </Typography>
          <Box 
            data-test-id="documents-folder" 
            onClick={()=>this.handleDocumentFolderNavigation(this.state.taskView.id)}
            style={{
              display: "flex",
              alignItems: "center",
              backgroundColor: "#4872FF",
              marginTop: "16px",
              cursor: "pointer",
              width: "30%",
              color: "white",
              padding: "10px",
              borderRadius: "12px",
            }}
          >
            <AttachFileIcon style={{ marginRight: 8 }} />
            <Typography>Documents Folder</Typography>
          </Box>
        

          {this.state.selectedLawyers && this.state.selectedLawyers.length > 0 && (
  <>
    <Divider style={{
      marginTop: "15px",
      marginBottom: "18px"}} />
    <Box>
      <Box 
      style={{
        display: "flex",
        justifyContent: "space-between"}}>
        <Typography
         style={{
          fontSize: "14px",
          fontFamily: "Dm sans",
          color: "#8C8C8C",
          marginTop: "18px"
        }}>
          Assigned Lawyers
          </Typography>
        <Typography 
        onClick={this.handleLawerDialog}
         style={{
          display: "flex",
          alignItems: "center",
          gap: "4px",
          cursor: "pointer",
          marginTop: "18px"}}>
          <span 
          style={{
            fontSize: "12px",
            fontWeight: 400,
            fontFamily: "Dm sans",
            color: "#355EE7"}}>
            See all
          </span><img src={request} alt="" />
        </Typography></Box>
      <Box style={{
        maxHeight: "85px",
        overflowY: "scroll" }}>
            {this.state.selectedLawyers?.map((lawyer, index) => (
                    <Box key={index}
                     mt={1} 
                     style={{
                      border: '1px solid #F0F0F0', borderRadius: '10px',
                      backgroundColor:"#FAFAFA",
                      padding:"6px", }}>
                  
                        <Box data-test-id={`mapFunction${index}`}
                          style={{ display: "flex",
                            flexDirection: "row",
                            border: '1px solid #C3D1FF',
                            borderRadius: "10px",
                            alignItems: "center",
                            gap: "4px",
                            padding: "6px",
                            fontFamily: "Dm sans", }}>
                          <img src={lawyer.attributes.associated_lawyer_details.data.attributes.profile_image?.url || userpic} style={{
                            width: "39px",
                            height: "39px",
                            marginLeft: "3px",borderRadius:"50px"}} />
                          <Box style={{
                            width:"100%" }}>
                            <Box
                              style={{ display: "flex",
                                alignItems: "center",
                                gap: "15px",
                                fontFamily: "Dm sans",
                                width: "100%",
                                justifyContent:"space-between" }}>
                              <Typography style={{ fontWeight: 400, fontSize: "14px",
                               fontFamily: "Dm sans", 
                               marginLeft: "2px" }}>
                                {lawyer.attributes.associated_lawyer_details.data.attributes.first_name}
                              </Typography>
                              <Typography 
                              style={{ fontWeight: 400, 
                              fontSize: "16px", 
                              fontFamily: "Dm sans", color: "#8C8C8C", gap: "3px", display: "flex" }}>
                                4.2 <Typography>
                                  <StarRateIcon style={{
                                  color: "#F59E0B",
                                  width: "19px",
                                  height: "25px"
                                }} /></Typography>
                              </Typography> </Box>
                            <Box style={{
                              display: "flex",
                              justifyContent:"space-between"  }}>   
                            <Typography style={{
                              display:"flex",
                              gap:"4px"}}>
                               {lawyer?.attributes?.associated_lawyer_details?.data?.attributes?.legel_service_type?.slice(0, 2)?.map((service, index) => (
                                <Typography
                                  style={{
                                    color: "#355EE7",
                                    backgroundColor: "#ECF1FF",
                                    borderRadius: "6px",
                                    padding: "2px 6px",
                                    fontFamily: "Dm sans",
                                    marginTop: "3px",
                                    marginLeft: "2px",
                                    width: "auto",
                                    height: "auto",
                                    fontSize: "11px",
                                    fontWeight: 400,
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "4px",
                                  }}
                                  key={index}
                                >

                                  <img
                                    src={Services}
                                    style={{
                                      width: "14px",
                                      backgroundColor: "none",
                                      height: "14px",
                                      marginRight: "4px",
                                    }}
                                    alt=""
                                  />
                                  <span>{service.legal_service_name}</span>
                                </Typography>
                             ))}
                                   { lawyer && lawyer.attributes.associated_lawyer_details.data.attributes.legel_service_type?.length > 2 && (
                      <MuiThemeProvider theme={theme}>
                      <Tooltip
                        title={
                          <div  style={{ width:"100%"}} >
                            <div style={{display:"flex",gap:"20px",width:"100%"}}>
                              <div style={{display: "grid",gridTemplateColumns:" repeat(2, 1fr)",gap:"10px",width:"80%",marginTop:"40px"}}>
                                {lawyer?.attributes.associated_lawyer_details.data.attributes.legel_service_type?.slice(2).map((service, index) => (
                                  <Chip
                                    key={index}
                                    label={service.legal_service_name}
                                    className="popup-chip"
                                    style={{ backgroundColor: '#ECF1FF ',
                                    color: '#355EE7 ',fontSize:"14px",textTransform:"capitalize",wordWrap: "break-word"}}
                                  />
                                ))}
                              </div>
                                    <div className="popup-image" style={{ justifyContent: "flex-end", display: "flex"}}>
                                    <img
                                  src={tooltipIconpage}
                                  style={{
                                    width: "40px",
                                    height: "40px",
                                    marginBottom:"1px",
                                  }}
                                  alt=""
                                />
                                    </div>
                            </div>
                          </div>
                        }
                      >
                        <Typography
                          style={{
                            color: "#355EE7",
                            backgroundColor: "#ECF1FF",
                            borderRadius: "6px",
                            padding: "2px 6px",
                            fontFamily: "Dm sans",
                            marginTop: "3px",
                            marginLeft: "2px",
                            width: "auto",
                            height: "auto",
                            fontSize: "11px",
                            fontWeight: 400,
                            display: "flex",
                            alignItems: "center",
                            gap: "4px",
                          }}
                        >
                          <img
                            src={Services}
                            style={{
                              width: "14px",
                              backgroundColor: "none",
                              height: "14px",
                              marginRight: "4px",
                            }}
                            alt="Service"
                          />
                          <span>+{lawyer?.attributes.associated_lawyer_details.data.attributes?.legel_service_type.length - 2}</span>
                        </Typography>
                      </Tooltip>
                    </MuiThemeProvider>
                    )}
                              </Typography>   
                              <Typography
                                variant="body2" style={{
                                  display: "flex",
                                  alignItems: "center",
                                  padding: "2px",
                                  fontFamily: "Dm sans",
                                  gap: "3px"}} >
                                <VpnLockIcon style={{
                                  height: "18px",
                                  width: "18px",
                                  color: "#355EE7",
                                }} />
                                 <span style={{
                                  fontSize: "11px",
                                  fontFamily: "Dm sans",
                                  fontWeight: 400 }}>
                                    {lawyer.attributes.associated_lawyer_details.data.attributes.jurisdiction}
                                    </span></Typography>
                              </Box></Box>
                        </Box> </Box>
                  ))} </Box>
          </Box>
           </>
)}
          {this.state.isTaskPosted  && (
          <Box style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "10px",
                top: "108%",
                right: "0%",
                gap: "12px",
                position:"relative" }} >  
              <button style={{
                  color: "#F87171",
                  width: "145px",
                  height: "44px",
                  textTransform: "none",
                  borderRadius: "8px",
                  fontSize: "16px",
                  fontWeight: "400",
                  fontFamily: "DM Sans",
                  cursor: "pointer",
                  border: "1px solid #F87171",
                  backgroundColor: "white",}}
                onClick={this.handleRejectTask} >
                Reject Offer
              </button>
              <button onClick={this.handleAcceptTask}
                style={{ backgroundColor: "#355EE7",
                  color: "white",
                  width: "145px",
                  height: "44px",
                  textTransform: "none",
                  borderRadius: "8px",
                  fontSize: "16px",
                  fontWeight: "400",
                  fontFamily: "DM Sans",
                  cursor: "pointer",
                  border: "1px"}}>
                Accept Offer
              </button></Box>)}
        </Box>
      </Box>
    </Box>
     );
  renderTableContent = () => {
    const { loading, taskProfile } = this.state;
    if (loading) {
      return (
        <TableRow><TableCell colSpan={7} align="center">
            <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "50vh" }}>
              <CircularProgress />  </Box>
          </TableCell></TableRow>
      ); }
    if (taskProfile.length === 0) {
      return (
        <TableRow>
          <TableCell colSpan={7} 
          align="center">
            <Box style={{ display: "flex",
             justifyContent: "center",
              alignItems: "center",
               height: "50vh" }}>
              <Typography style={{ textAlign: 'center', padding: '20px', 
              fontWeight: 'bold', display: "flex", 
              flexDirection: "column", fontFamily: "Dm sans", }}>
                No current tasks
                <span style={{
                  fontSize: "12px",
                  fontWeight: "normal",
                  color: "grey",
                  marginTop: "10px",
                  fontFamily: "Dm sans",
                }}>
                  When you create a new task, you can find it here</span>
              </Typography> </Box>
          </TableCell> </TableRow>
      );}

    return taskProfile?.map((task, index) => (
      <TableRow
        component="tr"
        key={index}
        style={{ borderSpacing: '0px !important',
          borderCollapse: 'unset',}}
        data-test-id={`task-${task.id}`}
        onClick={() => this.toggleDrawer(task)} >
        <TableCell
          style={{
            width: "12%",
            fontSize: "12px",
            fontWeight: 700,
            fontFamily: "Dm sans",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            maxWidth: "calc(17ch + 1em)",
            textTransform: "capitalize"}}
          className="tableFont"
          title={task.attributes.task_name} >
          {task.attributes.task_name}
        </TableCell>

        <TableCell style={{
            width: "15%",
            fontSize: "12px",
            fontWeight: 400,
            fontFamily: "Dm sans",
            color: "#0F172A",
            textTransform: "capitalize" }}
        >
          {task.attributes.legel_service_type.name}
        </TableCell>
        <TableCell
          title={task.attributes.trademark_name}
          style={{width: "15%",
            fontSize: "12px",
            fontWeight: 400,
            fontFamily: "Dm sans",
            color: "#0F172A",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            maxWidth: "calc(17ch + 1em)",
            textTransform: "capitalize" }} >
          {task.attributes.trademark_name}
        </TableCell>

        <TableCell
          title={task.attributes.trademark_application_number}
          style={{ width: "13.6%",
            fontSize: "12px",
            fontWeight: 400,
            fontFamily: "Dm sans",
            color: "#0F172A",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            maxWidth: "100px",
            textTransform: "capitalize"}} >
          {task.attributes.trademark_application_number}
        </TableCell>

        <TableCell style={{ width: "14.8%" }}>
          {task.attributes.state === 'new_task' && (
            <Chip
              label='NEW'
              className={'chipNew'}
              style={{ width: '102px',
                height: '22px',
                borderRadius: '40px',
                padding: '11px 8px 8px 8px', fontFamily: "Dm sans", }} />
          )}
          {task.attributes.state === 'pending' && (
            <Chip
              label='PENDING'
              className={'chipPending'}
              style={{
                width: '102px',
                height: '22px',
                borderRadius: '40px',
                fontFamily: "Dm sans",
                padding: '11px 8px 8px 8px',}} />
          )}
          {task.attributes.state === 'in_progress' && (
            <Chip
              label='IN PROGRESS'
              className={'chipInProgress'}
              style={{
                width: '102px',
                height: '22px',
                borderRadius: '40px',
                fontFamily: "Dm sans",
                padding: '11px 8px 8px 8px', }} />
          )}
          {task.attributes.state === 'closed' && (
            <Chip
              label='CLOSED'
              className={'chipClosed'}
              style={{
                width: '102px',
                height: '22px',
                borderRadius: '40px',
                fontFamily: "Dm sans",
                padding: '11px 8px 8px 8px', }} />
          )}
        </TableCell>

        <TableCell style={{ width: "24%" }}>
          <Chip
            label={this.getStatus(task).replace(/_/g, " ")}
            className="chipStatus"
            style={{
              textTransform: "uppercase", fontFamily: "Dm sans",}} />
        </TableCell>

        <TableCell>
          <IconButton className={'iconButton'}>
            <ArrowForwardIosOutlinedIcon />  </IconButton>
        </TableCell>
      </TableRow> ));
  };


  // Customizable Area End

  render() {
    // Customizable Area Start   
    return (
      <><MainDiv data-test-id="projectTemplatesProfiles" style={{
        maxHeight: this.state.mobileOpen ? '100vh' : '',
        overflow: this.state.mobileOpen ? "hidden" : "" }}>
        <Box className="sidebar">
          {this.drawer()}
          {this.buttonMenu()}
          {this.sidebarr()}</Box>
        {this.sideBarr2()}
        <Box className="rightbar">
          <Box className="topbox">
          <JobListing projectId={this.state.taskView.id} handleUpdateCall={this.handleUpdateCalls} closeLawyer={this.handleCloseLawyerDialog} handleClose={this.handleCloseAccept} rejectClose={this.handleRejectClose} taskId={this.state.taskViewId} rejectDialog={this.state.rejectTask} lawyerDialog={this.state.lawyerDialog} acceptDialog={this.state.acceptTask} navigation={this.props.navigation} id={""} handleNavigate={undefined}/>
            <Typography data-test-id="backbtn" className="titleuser2">
              <img src={file} className="imageBox2" alt="" /> Tasks</Typography>
          </Box>
          <Box style={{ width: "92.5%",
            display: "flex",
            marginLeft: "60px",
            marginBottom: "30px",
            marginTop: "20px",
            justifyContent: "flex-start",
            alignItems: "center",
            gap: "10px" }}>

            <TextField variant="outlined"
              placeholder="Search by task name or trademark name or number "
              style={{
                width: "100%"}}
              InputProps={{style: {
                  height: '44px',
                  borderRadius: '12px',
                  fontSize: '16px',
                  fontWeight: 400,  },
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon style={{
                      color: "#BFBFBF",
                      width: "24px",
                      height: "24px"}} />
                  </InputAdornment> ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton>
                      <img src={filter} alt="" /> </IconButton>
                  </InputAdornment> ),
              }} />
            <button onClick={this.handleNavigationProject} data-test-id="signup" className="landingSignupBtn"  >
              Add New task </button>
          </Box>
          <TasksTableContent data-test-id="tasks-client" loading={this.state.loading} tasksProfile={this.state.taskProfile} toggleDrawer={this.toggleDrawer}  />
        </Box>
             <Drawer  
              anchor="right"
              open={this.state.drawerOpen}
              onClose={this.toggleDrawerClose}
              data-test-id="task-view-drawer" 
            >
              {this.drawerContents()} </Drawer>
        <Dialog
          PaperProps={{ style: {
              width: '600px',
              maxWidth: '600px',
              height: "239px",
              borderRadius: "12px",
              padding: "12px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center" }
          }} style={{}} open={this.state.openDialog} 
          onClose={this.handleCloseDelete}
          aria-labelledby="responsive-dialog-title" >
          <Box style={{ border: "1px solid #D1FAE5",
            width: "576px",
            height: "215px",
            borderRadius: "8px", }}>
            <DialogTitle id="responsive-dialog-title" 
            style={{
              textAlign: 'right',
              padding: "7px"
            }}>
              <CloseIcon style={{ cursor: 'pointer' }} onClick={this.handleCloseDelete} /></DialogTitle>
            <DialogContent
              style={{ padding: "0px"
              }} >
              <DialogContentText  >
                <Typography style={{
                  backgroundColor: '#ffffff',
                  color: '#0F172A',
                  fontSize: '19px',
                  fontWeight: 400,
                  fontFamily: 'DM Sans',
                  marginBottom: '20px',}}>
                  <Box style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center" }}>
                    <img src={right}
                     className="imageBox2" alt="success" />
                  </Box>
                  <Typography 
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "25px",
                    fontSize: "22px",
                    fontFamily: "Dm sans",
                    fontWeight: 400 }}>
                  </Typography>
                </Typography>
              </DialogContentText>
            </DialogContent>
            </Box></Dialog>
      </MainDiv>
      </>);
    // Customizable Area End
  }
}

// Customizable Area Start
const MainDiv = styled('div')({
 "& .MuiTypography-body1 ":{
    fontFamily: 'DM Sans !important',
  },
  
  "& .selectedUser": {
    border: '1px solid #F0F0F0', borderRadius: '10px', display: 'flex', flexDirection: 'column', gap: '10px',
    maxHeight: '200px',
    overflowY: 'auto',
    backgroundColor: " #FAFAFA",
    scrollbarWidth: 'thin',
    scrollbarColor: '#355EE7 #F0F0F0',
    fontFamily: "Dm sans",
    width: "97.5%",
    padding: "6px"
  },
  "& .drawerResp": {
    width: "100vh",
    maxWidth: "812px",
    padding: "16px",
    height: "auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    fontSize: "Dm sans",
    "@media (max-width:900px)": {
      width: "30vh",
    },
  },
  "& .scrollable-box": {
    height: '54.5%',
    overflowY: 'auto',
    display: 'block',
    marginBottom: "10px",
    flexGrow: 1,
    paddingRight: '0px',
    "@media (max-width:800px)": {
      display: 'inline-block',
    },
  }, "& .scrollable-box::-webkit-scrollbar": {
    width: "8px",
  },
  "& .scrollable-box::-webkit-scrollbar-track": {
    background: "#f1f1f1",
  },
  "& .scrollable-box::-webkit-scrollbar-thumb": {
    backgroundColor: "#91AAFF",
    borderRadius: '10px',
    border: '2px solid #f1f1f1',
  },
  "& .scrollable-box::-webkit-scrollbar-thumb:hover": {
    backgroundColor: "#5D85FF",
  },
  "& .tableFont": {
    width: "12%",
    fontSize: "12px",
    fontWeight: 700,
    fontFamily: "Dm sans"
  },
  "& .custom-datepicker-input": {
    width: "100%",
    border: "1px solid #C3D1FF",
    borderRadius: "12px",
    fontSize: "16px",
    fontWeight: 400,
    outline: "none",
    height: "44px",
    padding: "10px",
  },
  "& .custom-datepicker-input.error": {
    border: "1px solid red"
  },

  "& .custom-datepicker-input::placeholder ": {
    color: '#BFBFBF',
    fontFamily: 'DM Sans',
    fontSize: "16px",
    fontWeight: 400
  },
 
  width: '100%',
  height: '100%',
  display: 'flex',
  "@media (max-width:991px)": {
    display: "block",
  },
  "& .selectCountry": {
    width: '100%',
    border: '1px solid #C3D1FF', borderRadius: "12px",
    marginBottom: '8px', outline: 'none', backgroundColor: '#fff',
    fontSize: '16px', height: "44px",
    backgroundRepeat: 'no-repeat', backgroundPositionX: '95%',
    backgroundImage: 'url("data:image/svg+xml;utf8,<svg fill=\'%23c5cbc6\' height=\'20\' viewBox=\'0 0 24 24\' width=\'20\' xmlns=\'http://www.w3.org/2000/svg\'><path d=\'M7 10l5 5 5-5z\'/></svg>")',
    fontWeight: 400, fontFamily: "DM Sans",
    backgroundPositionY: 'center', appearance: 'none',
    '&:hover': {
      border: '1px solid #C3D1FF', borderRadius: "12px"
    },
    "&:focus": {
      border: '2px solid #C3D1FF;', borderRadius: "12px"
    },
  },
  "& .offcanvascss": {
    position: 'fixed',
    top: '0px !important',
    bottom: '0px !important',
    left: '0% !important',
    width: '252px !important',
    height: '100vh !important',
    backgroundColor: '#fff',
    zIndex: 9999,
  },
  "& .offcanvasbackdrop": {
    position: "fixed",
    top: '0px',
    left: '252px',
    width: 'calc(100% - 252px)',
    height: '100vh',
    zIndex: 999,
    backgroundColor: '#000',
    opacity: 0.5,
  },
  "& .selectlegal": {
    width: '100% !important',
    borderRadius: '12px', fontSize: '16px',
    fontWeight: 400,
    fontFamily: "DM Sans",
    height: "44px",
    "& .css-13cymwt-control": {
      borderColor: "#C3D1FF !important",
      border: "1px solid",
      borderRadius: "12px",
      fonSize: "16px",
      fontweight: 400,
      outline: "none",
      position: "relative",
      bottom: "3px",
      height: "44px"

    },
    "& .css-t3ipsp-control": {
      borderColor: "#C3D1FF !important",
      border: "1px solid",
      borderRadius: "12px",
      fonSize: "16px",
      fontweight: 400,
      outline: "none",
      position: "relative",
      bottom: "3px",
      height: "44px",
      boxShadow: "0px 0px 0px 0px"

    },
    "& .css-1u9des2-indicatorSeparator": {
      backgroundColor: "white"
    },
    "&:focus": {
      border: '1px solid #C3D1FF;', borderRadius: "12px"
    },
    "@media (max-width:500px)": {
      width: '100% !important',
      borderRadius: '12px', fontSize: '8px',
      fontWeight: 200,
      fontFamily: "DM Sans",
      height: "44px",
    },
  },

  "& .landingSignupBtn": {
    backgroundColor: "#355EE7",
    color: "white",
    width: "145px",
    height: '44px',
    textTransform: "none" as "none",
    borderRadius: "8px",
    fontSize: "16px",
    fontWeight: "400",
    fontFamily: "DM Sans",
    cursor: "pointer",
    border: 'none',
    '&:hover': {
      border: '2px solid #B9C5FF',
      boxShadow: 'none !important'
    }
  },
  "& .document": {
    backgroundColor: "#355EE7",
    color: "white",
    width: "179px",
    height: '46px',
    textTransform: "none" as "none",
    borderRadius: "8px",
    padding: "10px",
    fontSize: "16px",
    fontWeight: "400",
    fontFamily: "DM Sans",
    cursor: "pointer",
    border: 'none',

  },
  "& .landingLoginBtn": {
    backgroundColor: "#fff",
    width: "145px",
    height: '46px',
    textTransform: "none" as "none",
    borderRadius: "8px",
    padding: "10px",
    color: "#355ee7",
    fontSize: "16px",
    fontWeight: "400",
    fontFamily: "DM Sans",
    cursor: "pointer",
    border: '1px solid #d9d9d9',
    boxShadow: 'none !important',
    '&:hover': {
      border: '2px solid #B9C5FF',
      boxShadow: 'none !important'
    }

  },
  "& .firstNamePlaceHolder": {
    fontSize: "16px",
    fontWeight: 400
  },
  "& .sidebar": {
    height: '100%',
    position: "fixed",

    margin: 0,
    padding: 0,
    top: '0',
    left: '0',
    zIndex: 9999,
    "@media (max-width: 1100px)": {
      width: "300px"
    },
    "@media (max-width: 991px)": {
      width: '100vw',
      height: '80px'
    }
  },
  "& .mainheader": {
    position: 'fixed',
    left: '350px',
    width: '100%'
  },
  "& .logtxtdialog": {
    backgroundColor: "#355EE7",
    color: '#ffffff',
    borderRadius: '8px',
    fontSize: '16px',
    fontWeight: 400,
    fontFamily: 'DM Sans',
    marginLeft: '15px',
    border: 'none',
    cursor: 'pointer',
    transition: 'all 0.3s ease',
    '&:hover': {
      border: '2px solid #B9C5FF',
      boxShadow: 'none !important',
    },
  },
  "& .Header": {
    height: '50px',
    fontSize: '20px', padding: '20px',
  },
  "& .rightbar": {
    padding: "10px 0px",
    width: "100%",
    marginLeft: '253px',
    backgroundColor: "#fafafa",
    height: "auto",
    "@media (max-width: 1100px)": {
      padding: '20 40px', width: 'calc(100% - 300px)',
      marginLeft: '300px',
    },
    "@media (max-width: 991px)": {
      width: 'auto !important',
      marginLeft: '0px', marginTop: '50px',
      padding: "10px 50px",
    }
  },
  "& .userdetailbox": {
    width: '90%',
    padding: "20px",
    backgroundColor: "#ffffff",
    height: "auto",
    marginLeft: "61px",
    borderRadius: "20px",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;",
    marginBottom: "15px",
    "@media (max-width: 1424px)": {
      width: '89%',
      height: "auto",
      marginLeft: "51px",
      borderRadius: "20px",
      boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;",
      padding: "20px",
      backgroundColor: "#ffffff",
    },
    "@media (max-width: 1366px)": {
      width: '87%',
      padding: "20px",
      backgroundColor: "#ffffff",
      marginLeft: "61px",
      borderRadius: "20px",
      boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;",
      height: "auto",
    },
    "@media (max-width: 1280px)": {
      width: '87%',
      height: "auto",
      marginLeft: "61px",
      boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;",
      borderRadius: "20px",
      fontSize: "20px",
      padding: "20px",
      backgroundColor: "#ffffff",

    },
    "@media (max-width: 780px)": {
      width: '87%',
      fontSize: "20px",
      height: "auto",
      marginLeft: "31px",
      borderRadius: "20px",
      padding: "20px",
      backgroundColor: "#ffffff",

      boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;",
    },
    "@media (max-width: 600px)": {

      height: "auto",
      marginLeft: "24px",
      borderRadius: "20px",
      boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;",
      width: '87%',
      fontSize: "20px",
      padding: "20px",
      backgroundColor: "#ffffff",
    },
    "@media (max-width: 520px)": {
      width: '87%',
      height: "auto",
      marginLeft: "24px",
      borderRadius: "20px",
      boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;",
      fontSize: "20px",
      padding: "20px",
      backgroundColor: "#ffffff",
    },
  },
  "& .profilebox": {
    height: '156px',
    display: 'flex', borderBottom: '1px solid #D9D9D9',
    "@media (max-width: 450px)": {
      height: '120px',
    },
  },
  "& .profileImg": {
    display: 'flex',
    justifyContent: "center", width: "80%",
  },
  "& .profiletext": {
    fontSize: '16px',
    fontWeight: 400, color: '#8C8C8C', fontFamily: 'DM Sans',
  },
  "& .userimg": {
    height: '120px',
    width: '120px',
    border: '4px solid #ECF1FF',
    borderRadius: '50%',
    "@media (max-width: 450px)": {
      height: '80px',
      width: '80px',
    },
  },
  '& .topbox': {
    display: 'flex',
    width: "auto",
    height: '70px',
    justifyContent: 'space-between',
    "@media (max-width: 991px)": {
      width: "auto !important",
    },
  },
  "& .titleuser": {
    height: '30px',
    width: '230px',
    fontSize: '22px',
    fontWeight: 400,
    fontFamily: "DM Sans",
    color: '#000',
    "@media (max-width: 450px)": {
      width: '200px',
      fontSize: '16px',
    },
  },
  "& .titleuser2": {
    height: '30px',
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "13px",
    width: '230px',
    fontSize: '27px',
    fontWeight: 700,
    fontFamily: "DM Sans",
    color: '#0F172A',
    marginLeft: "60px",
    marginTop: "13px",
    "@media (max-width: 450px)": {
      width: '200px',
      fontSize: '16px',
    },
  },
  "& .titleuser3": {
    height: '30px',
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "5px",
    width: '230px',
    fontSize: '22px',
    fontWeight: 400,
    fontFamily: "DM Sans",
    marginLeft: "80px",
    color: '#000',
    "@media (max-width: 450px)": {
      width: '200px',
      fontSize: '16px',
    },
  },
  "& .titlbtn": {
    height: '44px',
    fontSize: '14px', fontWeight: 400, color: '#355EE7', fontFamily: 'DM Sans',
    width: '163px', border: '1px solid #355EE7', borderRadius: '8px', padding: '10px 12px',
    "@media (max-width: 450px)": {
      height: '40px',
      width: '140px', padding: '7px 9px',
    },
  },
  "& .detailsbox": {
    display: 'flex', marginTop: '20px', paddingBottom: '40px',
    borderBottom: '1px solid #D9D9D9', "@media (max-width: 600px)": {
      display: 'block'
    },

  },
  "& .detailsbox2": {
    display: 'flex', marginTop: '20px',
    "@media (max-width: 600px)": {
      display: 'block'
    },

  },
  "& .personaldetailtext": {
    width: '15%', fontSize: '20px', fontWeight: 700,
    fontFamily: 'DM Sans', color: 'black',
    "@media (max-width: 600px)": {
      width: '100%', marginBottom: '20px'
    },
  },
  "& .personalform": {
    width: '88%',
    position: 'relative',
    "@media (max-width: 600px)": { width: '100%' },
  },
  "& .fullName": {
    width: '100%', display: 'flex', paddingBottom: '30px',
    "@media (max-width: 650px)": {
      display: 'block', width: '100%'
    }
  },
  "& .firstname": {
    width: "49%", marginRight: "10px",
    "@media (max-width: 650px)": {
      width: "91% !important", marginBottom: '15px',
    },
  },
  "& .lastname": {
    width: "49%",

    "@media (max-width: 650px)": { width: "91% !important", },
  },
  "& .lawFirm": {
    width: "99%", marginBottom: '15px',
  },
  "& .country": {
    width: "91%", marginBottom: '15px',
  },
  "& .PhoneNumer": {
    width: "91%", marginBottom: '15px',
  },
  "& .Email": {
    width: "91%", marginBottom: '15px',
  },
  "& .Password": {
    width: "91%", marginBottom: '15px', height: "28px",
  },
  "& .passtext": {
    color: "#434343", fontSize: '14px',
    fontWeight: 400, fontFamily: 'DM Sans',
  },
  "& .changepasstext": {
    color: "#6200EA", fontSize: '14px',
    fontWeight: 700, fontFamily: 'DM Sans', cursor: 'pointer', marginTop: '4px',
  },
  "& .accountsbox": {
    display: 'flex', marginTop: '20px',
    "@media (max-width: 600px)": {
      display: 'block',
      marginTop: '10px',
    },
  },
  "& .accountdetailtext": {
    width: '20%', color: "#8C8C8C",
    fontSize: '16px', fontWeight: 400, fontFamily: 'DM Sans',
    "@media (max-width: 600px)": {
      width: '100%',
      marginBottom: '20px'
    },
  },
  "& .accountsactions": {
    width: '84.3%', marginBottom: '40px',
    display: "flex", justifyContent: "flex-end",
    "@media (max-width: 600px)": { width: '100%', },
  },
  "& .logouttxt": {
    display: 'flex', alignItems: 'center', margin: '0px 0px 20px 0px', gap: "20px",
    color: "#434343", fontSize: '16px', fontWeight: 400, fontFamily: 'DM Sans',
    cursor: 'pointer',
  },
  "& .deletetxt": {
    display: 'flex', alignItems: 'center',
    margin: '20px 0px', color: "#DC2626", fontSize: '16px', fontWeight: 400, fontFamily: 'DM Sans',
    cursor: 'pointer',
  },
  "& .errorOutline": {
    '& .MuiFormControl-root': {
      '& fieldset': {
        borderColor: 'red 1px solid',
      },
    },
  },
  "& .MuiInputBase-input": {
    padding: '18.5px 14px',
  },
  "& .tableContainer": {
    marginTop: " theme.spacing(4)",
    "& .MuiPaper-elevation1": {
      boxShadow: "none !important"
    }
  },

  "& .tableHeader": {
    color: "#8C8C8C",
    fontSize: "12px",
    fontWeight: "500"
  },
  "& .chipNew": {
    backgroundColor: "#D1FAE5",
    color: "#059669",
    fontWeight: 700,
    fontSize: "10px"
  },
  "& .chipPending": {
    backgroundColor: "#DCECFF",
    color: "#355EE7",
    fontWeight: 700,
    fontSize: "10px"
  },
  "& .chipInProgress": {
    backgroundColor: "#FEF3C7",
    color: "#D97706",
    fontWeight: 700,
    fontSize: "10px"
  },
  "& .chipClosed": {
    backgroundColor: "#FEE2E2",
    color: "#DC2626",
    fontWeight: 700,
    fontSize: "10px"
  },
  "& .chipStatus": {
    backgroundColor: ' #FAFAFA',
    width: '164px',
    height: '22px',
    borderRadius: '40px',
    fontWeight: 700,
    fontSize: "10px",
    padding: '10px 8px 8px 8px',
    display: "flex",
    justifyContent: "flex-start"
  },
  "& .iconButton": {
    width: "24px",
    height: "24px",
    color: "#355EE7"
  },
  "& .iconButton2": {
    width: "24px",
    height: "24px",
    color: "#BFBFBF"

  },
  "& .drawer": {
    width: "50%",
    minWidth: "812px",
  },
  "& .closeButton": {
    marginLeft: "auto",
  },
  "& .calendarIcon": {
    verticalAlign: "middle",
  },
})


const DrawerBox = styled('div')({
  minHeight: '100vh',
  width: "252px",
  borderRight: '1px solid #E2E8F0',
  "& .logobox": {
    display: 'flex', justifyContent: "start",
    height: "76px",
    padding: '17px 117px 17px 35px'
  },
  "& .logoimag":
  {
    height: '42px',
    width: '118px',
  },
  "& .settingbox":
  {
    display: 'flex',
    height: "65px",
  },
  "& .MainListBox": {
    padding: '0px 16px 8px 16px',
    position: 'relative'
  },
  "& .listbox": {
    height: '56px',
    borderRadius: '8px',
    "&:hover": {
      backgroundColor: "#ECF1FF",
      color: "#355EE7",
      "& .icondrawer2":
      {
        color: "#355EE7",
      },
    },
  },
  "& .listbox1": {
    height: '56px',
    backgroundColor: '#ECF1FF',
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    borderRadius: '8px',
    marginBottom: '10px'
  },
  "& .icondrawer1": {
    marginBottom: '7px',
    marginLeft: '32px',
    color: '#355EE7'
  },
  "& .icondrawer2": {
    marginLeft: '32px',
    marginBottom: '2px',
  },
  "& .listtile1": {
    fontSize: "12px",
    color: '#355EE7',
    fontFamily: 'Inter',
    fontWeight: 700,
    cursor: 'pointer',
    marginRight: '38px'
  },
  "& .listtile":
  {
    fontSize: "12px",
    fontFamily: 'Inter',
    fontWeight: 700,
    cursor: 'pointer',
    marginLeft: '10px'
  },
  "& .userProfile": {
    display: "flex",
    width: '252px',
    position: 'absolute',
    bottom: '10px',
    left: '32px'
  },
  "& .imageBox": {
    width: '32px',
    height: '32px',
    borderRadius: '50%',
    border: '1px solid #F0E5FF',
    marginRight: '10px',
  },
  "& .imageBox2": {
    width: '90px',
    height: '90px',
    borderRadius: '50%',
  },
  "& .userName": {
    fontFamily: 'DM Sans',
    fontSize: '14px',
    fontWeight: 700,
    color: '#0F172A'
  },
  "& .userContent": {
    fontFamily: 'DM Sans',
    fontSize: '11px',
    fontWeight: 400,
    color: '#8C8C8C'
  },
  "@media (max-width: 1100px)":
  {
    zIndex: 999,
    width: '252px',
  },
  "@media (max-width: 991px)":
  {
    zIndex: 999,
    width: '252px',
    display: 'none',
  },
});
const ButtonBox = styled('div')(
  {
    display: 'none',
    "@media (max-width: 991px)":
    {
      width: '100%',
      display: 'block', minHeight: '70px',
      "& .buttonbox":
      {
        height: '70px', width: '100vw'
      },

      "& .iconbtn":
      {
        marginTop: '10px', marginLeft: '10px'
      }
    }
  })
// Customizable Area End
