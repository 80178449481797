// Customizable Area Start
import React from "react";
import {
  Box,
  TextField,
  InputAdornment,
  Table,
  TableHead,
  TableBody,
  TableContainer,
  TableCell,
  TableRow,
  Paper,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  CircularProgress,
  DialogActions,
  Button,
  Checkbox,
  Chip,
  MenuItem,
  Menu,
  Grid,
  Drawer,
  Avatar,
  InputLabel,
  ListItemAvatar,
  Link,
  Popover,
  createTheme,
  ThemeProvider,
} from "@material-ui/core";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowDropDownRoundedIcon from '@material-ui/icons/ArrowDropDownRounded';
import StarRateIcon from '@material-ui/icons/StarRate';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { styled } from '@material-ui/core/styles';
import Select from "react-select";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CloseIcon from '@material-ui/icons/Close';
import { logo, userpic, right,toDo, filter,backk,file,Services} from "./assets";
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import MenuIcon from '@material-ui/icons/Menu';
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import SearchIcon from '@material-ui/icons/Search';
import VpnLockIcon from '@material-ui/icons/VpnLock';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import ProjectReportingController, {
    Props,
} from "./ProjectReportingController.web";

const StyledChip = styled(Chip)({
  borderRadius: 40,
  boxSizing: "border-box",
  width: 112,
  textAlign: "center",
  padding: "2px 8px",
  textTransform: "uppercase",
  fontFamily: "DM sans",
  fontWeight: 700,
  fontSize: 10,
  lineHeight: "18px",
  '&[data-state="pending"]': {
    width: 100,
    backgroundColor: "#DCECFF",
    color: "#355EE7",
  },
  '&[data-state="inprogress"]': {
    backgroundColor: "#FEF3C7",
    color: "#D97706"
  },
  '&[data-state="completed"]': {
    backgroundColor: "#D1FAE5",
    color: "#059669",
  },
})

const StyledChipButton = styled("button")({
  outline: "none",
  border: 0,
  backgroundColor: "transparent",
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  gap: 6,
  margin: 0,
  padding: 0,
  '&[aria-expanded="true"] svg': {
    rotate: "180deg"
  },
})

const TODO_STATUS = [
  {
    label: "pending",
    value: "pending"
  },
  {
    label: "inprogress",
    value: "inprogress"
  },
  {
    label: "completed",
    value: "completed"
  }
]

const statusTheme = createTheme({
  overrides: {
    MuiPopover: {
      paper: {
        width: 220,
        maxWidth: 220,
        minWidth: 220,
        borderRadius: 8,
        padding: "6px 0",
      },
    },
    MuiPaper: {
      elevation8: {
        boxShadow: "0px 8px 32px 0px #01175F0F, 0px 4px 8px 0px #01175F08",
      },
      rounded: {
        borderRadius: 8,
      },
    },
  },
})

class ProjectReporting extends ProjectReportingController {
    constructor(props: Props) {
        super(props);
    }
   
    drawer = () => (
      <DrawerBox style={{ display: this.state.mobileOpen ? 'block' : '' }}>
        <div className="toolbar" />
        <Divider />
        <div className="logobox">
          <img src={logo} alt="logo" className="logoimag" data-test-id="logoImg" />
        </div>
        {this.state.mobileOpen && <CloseIcon style={{ position: 'fixed', top: '30px', left: '210px', cursor: 'pointer' }} onClick={this.handleclosesidebar} />}
        <div className="settingbox">
        </div>
        <List className="MainListBox">
          <ListItem onClick={this.handleNavigateDashboard} className="listbox">
            <ListItemIcon><HomeOutlinedIcon className="icondrawer2" /></ListItemIcon>
            <ListItemText className="listtile" data-test-id="Dashboard">
              Dashboard
            </ListItemText>
          </ListItem>
          <ListItem className="listbox1" onClick={this.handleNavigateAllocator}>
            <ListItemIcon><DescriptionOutlinedIcon className="icondrawer1" /></ListItemIcon>
            <ListItemText className="listtile1" data-test-id="Setting">
              Tasks list
            </ListItemText>
          </ListItem>
          <ListItem className="listbox" onClick={this.handleSettingIn}>
            <ListItemIcon><SettingsOutlinedIcon className="icondrawer2" /></ListItemIcon>
            <ListItemText className="listtile" data-test-id="Setting">
              Settings
            </ListItemText>
          </ListItem>
        </List>
        <Box className="userProfile">
          <Box><img src={userpic} className="imageBox" /></Box>
          <Box>
            <Typography className="userName">sass</Typography>
            <Typography className="userContent">Side subnav item</Typography>
          </Box>
        </Box>
      </DrawerBox>
    )
    buttonMenu = () => (
      <ButtonBox className="buttonbox">
        <IconButton
          className="iconbtn"
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={this.handleDrawerToggle}
          style={{ marginRight: '24px', color: '#000' }}
        >
          <MenuIcon />
  
        </IconButton>
      </ButtonBox>
    )
    sidebarr = () => {
      return (this.state.mobileOpen &&
        <>
          <div className="offcanvascss" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
            {this.drawer()}
          </div>
        </>
  
      )
    }
  
    sideBarr2 = () => {
      return (
        this.state.mobileOpen && <div className="offcanvasbackdrop" onClick={this.handleclosesidebar}></div>
      )
    }
   

  
    renderTableRows = () => {
      const { loading,anchorEl ,searchResults,todoList} = this.state;
      const open = Boolean(anchorEl);
      const formatDate = (dateString:string) => {
        if (!dateString) return ''; 
        return new Date(dateString).toLocaleDateString('en-GB');
      };
      if (loading) {
        return (
          <TableRow>
            <TableCell colSpan={7} align="center">
              <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "50vh" }}>
                <CircularProgress />
              </Box>
            </TableCell>
          </TableRow>
        );
      }
  
      if (todoList.length === 0) {
        return (
          <TableRow>
            <TableCell colSpan={7} align="center">
              <Box style={{
                display: "flex",
                justifyContent: "center",
                height: "43.4vh",
                alignItems: "center"
              }}>
                <Typography style={{
                  textAlign: 'center',
                  padding: '20px',
                  fontWeight: 'bold',
                  display: "flex",
                  flexDirection: "column",
                }}>
             
                  <span style={{
                    fontSize: "12px",
                    fontWeight: "normal",
                    color: "grey",
                    marginTop: "10px",
                    fontFamily:"Dm sans"
                  }}>
                 No To-Dos created, click add to-do to add a row
                  </span>
                </Typography>
              </Box>
            </TableCell>
          </TableRow>
        );
      }

      return todoList?.map((task, id) => (
        <TableRow  
          data-drag-index={id}
          draggable
          onDragStart={() => this.handleDragStartToDo(id)}
          onDragOver={this.handleDragOverToDo}
          onDrop={() => this.handleDropToDo(id)}
          data-test-id={`todo-${task.id}`}
          style={{
            borderSpacing: '0px !important',
            borderCollapse: 'unset',
            boxShadow:"none",
            position:"relative",
            cursor: "grabbing",
          }}
          key={id} >
          <TableCell style={{
              paddingLeft:"2.5%"
          }} >
           
            {task.attributes?.to_do_name}
          </TableCell>
          <TableCell>
            <Typography style={{
              display: "flex",
              gap: "6px",
              alignItems: "center",
              fontSize: "12px",
              fontWeight: 400,
              fontFamily: "Dm sans",
              color: "#0F172A",
              textTransform: "capitalize",
            }}>
             {formatDate(task.attributes?.deadline)} <CalendarTodayIcon style={{
          width:"16px",
          height:"16px",
          color:"#BFBFBF",
          marginBottom:"1.5px",
          marginRight:"6px"
        }}/>
            </Typography>
          </TableCell>
          <TableCell
            style={{
            
            }}
            key={task?.attributes?.id}
          >
            <StyledChip data-test-id={`todo-status-${task.id}`} data-state={`${task.attributes.status}`.toLowerCase()} label={task.attributes.status} style={{width: 122}} />   
          </TableCell>
          <TableCell
            style={{
              fontSize: "12px",
              fontWeight: 400,
              fontFamily: "Dm sans",
              color: "#0F172A;",
              whiteSpace: "nowrap",
              overflow: "hidden",
              maxWidth: "100px",
              textTransform: "capitalize"
            }}
          >
              {task.attributes?.priority === 'Medium' && (
            <Chip
              label='MEDIUM'
              className={'chipMedium'}
              style={{ width: 'auto',
                height: '22px',
                borderRadius: '40px',
                fontFamily: "Dm sans",
                padding: '11px 8px 8px 8px', }} />
          )}
          {task.attributes?.priority === 'High' && (
            <Chip
              label='HIGH'
              className={'chipHigh'}
              style={{
                width: 'auto',
                height: '22px',
                borderRadius: '40px',
                fontFamily: "Dm sans",
                padding: '11px 8px 8px 8px',}} />
          )}
          {task.attributes?.priority === 'Low' && (
            <Chip
              label='LOW'
              className={'chipLow'}
              style={{
                width: 'auto',
                height: '22px',
                borderRadius: '40px',
                fontFamily: "Dm sans",
                padding: '11px 8px 8px 8px', }} />
          )}
          </TableCell>
          <TableCell
            style={{
              fontSize: "12px",
              fontWeight: 400,
              fontFamily: "Dm sans",
              color: "#0F172A;",
              whiteSpace: "nowrap",
              overflow: "hidden",
              maxWidth: "100px",
              textTransform: "capitalize",
          
            }}
          >
{task?.attributes?.assigned_lawyers?.data?.length > 0 ? (
  task.attributes.assigned_lawyers.data.map((lawyer, index) => (
    <div key={index} style={{ display: "flex", alignItems: "center", gap: "6px" }}>
      <Avatar
        style={{
          width: "23px",
          height: "23px",
          borderRadius: "50%",
          fontSize: "14px",
          padding: "3px",
        }}
        src={
          lawyer.attributes.associated_lawyer_details.data.attributes.profile_image?.url
        }
        alt={
          lawyer.attributes.associated_lawyer_details.data.attributes.first_name || "Lawyer"
        }
      />
      <span
        style={{
          fontWeight: 400,
          fontFamily: "DM sans",
          color: "#0F172A",
          fontSize: "14px",
          gap:"3px"
        }}
      >
        {lawyer?.attributes.associated_lawyer_details.data.attributes.first_name} {lawyer?.attributes.associated_lawyer_details.data.attributes.last_name}
      </span>
    </div>
  ))
) : (
  <span> </span>
)}




          </TableCell>
          <TableCell style={{
              position:"relative"
          }}>
            <IconButton
            data-test-id={`open-menu-${task.id}`}
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={(event) => this.handleClick(event, task.id)}
        style={{
          color:"#BFBFBF"
         }} >
        <MoreVertIcon />
      </IconButton>
      {this.state.open && this.state.taskViewId === task.id && (
        <>
        <Box
        style={{position:"absolute",top:"3rem",right:"2rem",
        backgroundColor:"#FFFFFF",
        zIndex:9999,borderRadius:"8px",
        width:"220px",
        padding:"15px",
        boxShadow:"rgba(149, 157, 165, 0.2) 0px 8px 24px",
        height:"auto",
        marginTop:"7px",
        }}>
        <Box
        style={{backgroundColor:"#FFFFFF",
        zIndex:9999,borderRadius:"8px"}}>
          <Box
          data-test-id={`view-todo-${task.id}`}
          className="menuItem"  onClick={this.toggleDrawer} ><VisibilityIcon style={{
            width:"16px",
            height:"16px",
            color:"#BFBFBF",
            marginBottom:"1.5px",
            marginRight:"6px"
          }} /><Typography>View To-Do</Typography></Box>
          <Box data-test-id="editToggle"
          className="menuItem" onClick={() => this.handleEditToggle(task)}><SystemUpdateAltIcon style={{
            width:"16px",
            height:"16px",
            color:"#BFBFBF",
            marginBottom:"1.5px",
            marginRight:"6px"
          }} /><Typography>Edit To-Do </Typography></Box>
          <Box
          className="menuItem" onClick={this.handleDeleteTodo}><DeleteOutlineIcon style={{
          width:"16px",
          height:"16px",
          color:"#BFBFBF",
          marginBottom:"1.5px",
          marginRight:"6px"
        }} />Delete To-Do
          </Box>
          </Box> </Box></>
      )}

          </TableCell>
        </TableRow>
      ));
    };
    getTaskStatus = (state: string) => {
      
      switch (state) {
        case 'new_task':
          return {
            label: 'NEW',
            style: {
              height: '34px',
              backgroundColor: "#D1FAE5",
              color: "#059669",
              fontWeight: 700,
              fontSize: "10px",
              width: '64px',
              borderRadius: '40px',
              padding: '11px 8px 8px 8px',},
          };
        case 'pending':
          return {
            label: 'PENDING',
            className: 'chipPending',
            style: {
              backgroundColor: "#DCECFF",
              borderRadius: '40px',
              padding: '11px 8px 8px 8px',
              fontWeight: 700,
              fontSize: "10px",
              color: "#355EE7",
              width: '105px',
              height: '22px',}};
        case 'Inprogress':
          return {
            label: 'IN PROGRESS',
            className: 'chipInProgress',
            style: {
              backgroundColor: "#FEF3C7",
              color: "#D97706",
              width: '113px',
              fontWeight: 700,
              height: '22px',
              borderRadius: '40px',
              padding: '11px 8px 8px 8px',
              fontSize: "10px",},};
        case 'closed':
          return {
            label: 'CLOSED',
            className: 'chipClosed',
            style: {
              borderRadius: '40px',
              padding: '11px 8px 8px 8px',
              backgroundColor: "#FEE2E2",
              color: "#DC2626",
              width: '102px',
              height: '22px',
              fontWeight: 700,
              fontSize: "10px",},
          };
          case 'High':
            return {
              label: 'HIGH',
              className: 'chipHigh',
              style: {
                borderRadius: '40px',
                padding: '11px 8px 8px 8px',
                backgroundColor: "#FEE2E2",
                color: "#DC2626",
                fontWeight: 700,
                fontSize: "10px",
                width: '102px',
                height: '22px',
                },
            };
            case 'Low':
              return {
                label: 'LOW', className: 'chipLow',
                style: {borderRadius: '40px',
                  padding: '11px 8px 8px 8px',
                  backgroundColor: "#F0F0F0",
                  color: "#262626",
                  fontWeight: 700,
                  fontSize: "10px",
                  width: '102px',
                  height: '22px',
                },};
              case 'Medium':
              return { label: 'MEDIUM',className: 'chipMedium',
                style: {
                  borderRadius: '40px',
                  padding: '11px 8px 8px 8px',
                  backgroundColor: "#FFD7B9",
                  color: "#FF6C29",
                  fontWeight: 700,
                  fontSize: "10px",
                  width: '102px',
                  height: '22px',},
              };
              case 'Completed':
                return {
                  label: 'COMPLETED',className: 'chipNew',
                  style: {
                    borderRadius: '40px',
                    padding: '11px 8px 8px 8px',
                    backgroundColor: "#D1FAE5",
                    color: "#059669",
                    fontWeight: 700,
                    fontSize: "10px",
                    width: 'auto',
                    height: '22px',},};
        default:
          return {
            label: 'NO STATUS',
            className: 'chipNoStatus',
            style: {
              backgroundColor: "#E2E3E5",
              color: "#6C757D",
              fontWeight: 700,
              fontSize: "10px",
            },
          };
        }
    };
    getStatus = (task:any) => {
      return task?.attributes?.status?.replace(/_/g, " ") || "unknown_status";
    };
    getStatus2 = () => {
      return this.state?.ViewTodoList?.attributes?.status?.replace(/_/g, " ") || "unknown_status";
    };
    getSeeMoreButtonText = () => {
      return this.state.isExpanded ? "See less" : "See more...";
    };
    shouldShowSeeMoreButton = () => {
      const description =  this.state.ViewTodoList?.attributes?.to_do_description;;
      return this.checkIfContentIsLong(description);
    };
    fullText = () => {
      const desc = this.state.ViewTodoList?.attributes?.to_do_description;
      return desc;
    };
    renderDescription = () => {
      const fullText = this.fullText();
  
      if (this.state.isExpanded) {
        return fullText; }
   const truncatedText = `${fullText?.substring(0, 200)}...`;
      return (
        <>
          {truncatedText}{this.shouldShowSeeMoreButton() && this.renderSeeMoreButton()}
        </>); };
  
    renderSeeMoreButton = () => (
      <button onClick={this.handleSeeMoreClick}
        style={{
          position: "absolute",
          bottom: "2px",
          right: "-8px",
          padding: "3px",
          color: "#355EE7",
          border: "none",
          background: "white",
          cursor: "pointer",
          fontSize: "11px",
          fontWeight: 400,
          zIndex: 1,
          width: "12%",
          display: "flex",
          justifyContent: "flex-start",}}>
        {this.getSeeMoreButtonText()}</button>
    );
  
    checkIfContentIsLong = (description: string) => {
      const maxLength = 200;
      return description && description.length > maxLength;};
    formatDate = () => {
      const createdAt = this.state.ViewTodoList.attributes?.deadline;
     
      const formattedDate = createdAt
        ? new Date(createdAt).toLocaleDateString('en-GB', {
        })
        : '';
      return formattedDate;
    };
    seeMore = () => {
      const description = this.state.ViewTodoList.attributes?.to_do_description;
      return this.checkIfContentIsLong(description);
    };

    drawerContents = () => (
      <Box style={{
          width: "100vh",
          maxWidth: "812px",
          padding: "16px",
          paddingLeft: "31px",
          height: "auto",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          fontSize: "Dm sans", }} >
        <Grid  container
          style={{ display: "flex", justifyContent: "space-between",marginBottom:"11px" }} >
          <Box style={{
            display:"flex"
          }}> 
            <IconButton style={{
              cursor: "pointer",padding:"7px"}} onClick={this.toggleDrawerClose}>
              <img src={backk} alt=""
               style={{
                width: "18px",
                height: "18px"}} />
            </IconButton>
            </Box>
          <Box style={{ display: "flex", alignItems: "center" }}>
            <Typography style={{ color: "#8C8C8C", fontSize: "14px",fontFamily:"DM sans",
             fontWeight: 400, 
             fontStyle: "Dm sans" }}>
              Created: {this.formatDate()}</Typography>
            <IconButton
              className={"closeButton"}
              onClick={this.toggleDrawerClose} >
              <CloseIcon style={{
                color: "#BFBFBF",
                width: "18px",
                height: "18px"  }} />
            </IconButton></Box>
        </Grid>
  
        <Box style={{ width: "100%",
         height: "105px", 
         gap: "12px" ,marginBottom:"9px"}}>
         <Chip
              label='TO-DO'
              className={'chipLow2'}
              style={{
                width: 'auto',
                height: '34px',
                borderRadius: '40px',
                padding: '11px 8px 8px 8px',
                fontWeight:700,
                fontFamily:"DM sans",
                fontSize:"14px" }} />
          <Grid container style={{ display: "flex", 
          marginTop: "24px",alignItems:"center"}}>
            <Grid item> <img src={file} alt="file" /> </Grid>
            <Grid item style={{ marginLeft: '10px' }}> <Typography
                variant="body1"
                style={{fontFamily: "DM sans",
                  fontSize: "18px",
                  fontWeight: "400",
                  lineHeight: "23.44px",
                  textAlign: "left",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  maxWidth: "calc(30ch + 1em)",
                  textTransform: "capitalize",
                  whiteSpace: "nowrap",}}
                 >
               {this.state.ViewTodoList.attributes?.to_do_name}
              </Typography>
               </Grid>
          </Grid>  </Box>
        <Box style={{
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around", }}  >
          <Box style={{ margin: "8px 0" }}>
            <Typography style={{ color: "#8C8C8C",fontFamily:"Dm sans",
             fontSize: "12px", fontWeight: 400,
             fontStyle: "Dm sans",marginBottom:"1%" }}>
              Status </Typography>


              <StyledChipButton data-test-id="todo-status-btn" aria-expanded={Boolean(this.state.statusAnchorEl)} onClick={this.handleOpenStatusPopover}>
                <StyledChip data-state={this.state.ViewTodoList.attributes.status.toLowerCase()} label={this.state.ViewTodoList.attributes.status} />
                <ArrowDropDownRoundedIcon htmlColor="#262626" />
              </StyledChipButton>
              <ThemeProvider theme={statusTheme} >
                <Popover 
                  data-test-id="status-popover" 
                  anchorEl={this.state.statusAnchorEl}
                  open={Boolean(this.state.statusAnchorEl)}
                  onClose={this.handleCloseStatusPopover}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  {
                    TODO_STATUS.map((status) => (
                      <StyledChipButton key={status.value} data-test-id={`select-${status.label}`} style={{padding: "8px 16px"}} onClick={this.handleUpdateToDoStatus(status.value)} >
                        <StyledChip data-state={status.label} label={status.label} />
                      </StyledChipButton>
                    ))
                  }
                </Popover>
              </ThemeProvider>


        </Box>
          <Box style={{ margin: "8px 0" }}>
            <Typography style={{ color: "#8C8C8C",fontFamily:"Dm sans",
             fontSize: "12px", fontWeight: 400,
              fontStyle: "DM Sans",marginBottom:"1%"}}>
              Assignee </Typography>
              <Typography style={{
  fontWeight: 400,
  fontFamily: "DM sans",
  color: "#0F172A",
  fontSize: "14px",
  display: "flex",
  alignItems: "center",
  gap: "6px"
}}>

</Typography>
          </Box><Divider style={{
            margin: "12px 0px"
          }} />
          <Box style={{ margin: "8px 0" }}>
            <Typography style={{ color: "#8C8C8C", fontSize: "12px", fontWeight: 400, 
            fontStyle: "Dm sans",marginBottom:"1%" }}>
             Priority
            </Typography>
            <Typography
              style={{ fontSize: "16px", fontWeight: 500,
               fontStyle: "Dm sans" }}>
                 <Chip
            label={this.getTaskStatus(this.state.ViewTodoList?.attributes?.priority).label}
            style={this.getTaskStatus(this.state.ViewTodoList?.attributes?.priority).style}
             />
            </Typography>
          </Box>
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              margin: "8px 0",}} >
            <Typography
              style={{ color: "#8C8C8C", fontSize: "12px", fontWeight: 400, fontStyle: "Dm sans",marginBottom:"1%"}} >
              Due Date:
            </Typography>
            <Box style={{ display: "flex", gap: "4px" }}>
              <Typography style={{ fontSize: "16px", fontWeight: 500, fontStyle: "Dm sans" }}>
              {this.formatDate()}
              </Typography>
              <Box style={{ color: "#8C8C8C" }}>
                <CalendarTodayIcon 
                style={{
                  color: "#BFBFBF",
                  width: "18px",
                  height: "18px" }} />
              </Box>
            </Box>
          </Box>
          <Divider style={{
            margin: "16px 0px"
          }} />
          <Box style={{ width: "100%", marginTop: "16px" }}>
            <Typography
              style={{ color: "#8C8C8C", fontSize: "12px", fontWeight: 400, fontFamily:"DM sans",}}
            >
              Description
            </Typography>
            <Typography
              variant="subtitle2"
              style={{
                color: "#8C8C8C",
                marginLeft: 4,
                display: "flex",
                justifyContent: "flex-end",
                paddingRight: "1vw",
                textTransform: "capitalize",
                fontFamily:"Dm sans"
              }}
            >
             
              {this.formatDate()}
            </Typography>
            <Typography
              style={{
                fontSize: "16px",
                fontWeight: 500,
                wordWrap: 'break-word',
                whiteSpace: 'normal',
                overflow: 'hidden',
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: this.state.isExpanded ? 'none' : 2,
                position: "relative",
                fontFamily:"DM sans",
              }}
              className="description-text"
            >
              {this.renderDescription()}
            </Typography>
  
          </Box>
  
          <Box style={{ width: "100%", marginTop: "16px" }}>
           
            <Box style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "94px",
                top: "125%",
                right: "0%",
                gap: "12px",
                position:"relative" }} >  
              <button onClick={this.finishTodo} style={{
                  color: "#FFFF",
                  width: "145px",
                  height: "44px",
                  textTransform: "none",
                  borderRadius: "8px",
                  fontSize: "16px",
                  fontWeight: "400",
                  fontFamily: "DM Sans",
                  cursor: "pointer",
                  border: "none",
                  backgroundColor: "#355EE7",}}
                >
                Finish To-Do
              </button>
             </Box>
        </Box>
        
        </Box>
      </Box>
       );
       
    
      handleCreateToDos = () => {
        const { to_do_name, deadline } = this.state.ViewTodoList?.attributes;
        const isValid = to_do_name?.trim() !== '' && deadline?.trim() !== '';
    
        if (isValid) {
            if (this.state.isEditMode) {
                this.editTodo();
            } else {
                this.createToDo();
            }
        } else {
            this.setState({
                validationErrors: {
                    to_do_name: to_do_name.trim() === '',
                    deadline: deadline.trim() === '',
                },
                isFormValid: false, 
            });
        }
    };
    
     getStylesForPriority = (priority: string,type:string) => {
      switch (priority) {
        case 'High':
          case 'Pending':
            return {
              backgroundColor: '#FEE2E2',
              color: '#DC2626',
            };
        case 'Medium':
          return {
            backgroundColor: type !== "Priority"?'#FEF3C7':'#FFD7B9',
            color: type !== "Priority"?'#D97706':'#FF6C29',
          };
        case 'Low':
          return {
            backgroundColor: type !== "Priority"?'#D1FAE5':'#F0F0F0',
            color: type !== "Priority"?'#059669':'#262626',
          };
        case 'Inprogress':
          return {
            backgroundColor: '#FEF3C7',
            color: '#D97706',
          };
        case 'Completed':
          return {
            backgroundColor: '#D1FAE5',
            color: '#059669',
          };
        default:
          return {
            backgroundColor: 'transparent',
            color: '#000',
          };
      }
    };
     getMainDivStyles = (mobileOpen: boolean) => ({
      maxHeight: mobileOpen ? '100vh' : '',
      overflow: mobileOpen ? 'hidden' : '',
    });
    getResponseMessage = () => {
      const { responseShow } = this.state;
      return responseShow.success || responseShow.errors;
    };
    getEditTitle = () => {
      const { isEditMode } = this.state;
      return isEditMode?"Edit To-Do" : "New To-Do"
    };
    getEditButton = () => {
      const { isEditMode } = this.state;
      return isEditMode?"Update" : "Create"
    };
  
      getBorderStyle = (field: string, validationErrors: any) => {
        if (field === 'to_do_name') {
          return validationErrors.to_do_name ? '1px solid red' : '1px solid #C3D1FF';
        }
      
        if (field === 'deadline') {
          return validationErrors.deadline ? '1px solid red' : '1px solid #C3D1FF';
        }
      
        return '1px solid #C3D1FF';
      };
      getInput = (field: string, validationErrors: any) => {
        if (field === 'to_do_name') {
          return validationErrors.to_do_name ? 'red' : 'black';
        }
      
        if (field === 'deadline') {
          return validationErrors.deadline ? 'red' : 'black';
        }
      
        return 'black';
      };
      getButtonColors = () => {
        return {
          backgroundColor: this.state.isFormValid ? '#355EE7' : '#F0F0F0',
          color: this.state.isFormValid ? 'white' : '#8C8C8C',
        };
      };
      
    
    render() {
      const options = [
        { value: 'Low', label: 'Low' },
        { value: 'Medium', label: 'Medium' },
        { value: 'High', label: 'High' },
      ];
      const options2 = [
        { value: 'High', label: 'High' },
        { value: 'Medium', label: 'Medium' },
        { value: 'Low', label: 'Low' },
      ];
      const options1 = [
        { value: 'Completed', label: 'COMPLETED' },
        { value: 'Inprogress', label: 'INPROGRESS' },
        { value: 'Pending', label: 'PENDING' },
      ];
  
     const  SingleValue = (props: any) => {
        const  {data,selectProps} = props
        const styles = this.getStylesForPriority(data.value,selectProps.placeholder);
        return (
          <div
            style={{
            position:"absolute",
           left:"1.5%",
           width:"89px",
           display:"flex",
           justifyContent:"center",
           alignItems:"center",
           ...styles,
              padding: '5px 10px',
              borderRadius: '20px',
              fontSize: '12px',
              fontWeight: 'bold',
            }}
          >
            {data.label}
          </div>
        );
      };
      const Option = (props: any) => {
        const { data, innerRef, innerProps,selectProps } = props;
        const styles = this.getStylesForPriority(data.value,selectProps.placeholder);
        return (
          <div
            ref={innerRef}
            {...innerProps}
            style={{
              left:"1.5%",
              width:"89px",
              display:"flex",
              justifyContent:"center",
              alignItems: "center",
              ...styles,
                  padding: '5px 10px',
                  borderRadius: '20px',
                  fontSize: '12px',
                  fontWeight: 'bold',
              margin: '5px',
              marginLeft:"25px",
              cursor: 'pointer',
            }}
          >
            {data.label}
          </div>
        );
      };
      
 
      const { validationErrors } = this.state;
        return (
         <><MainDiv data-test-id="projectReporting" style={this.getMainDivStyles(this.state.mobileOpen)}>
            <Box className="sidebar">
              {this.drawer()}
              {this.buttonMenu()}
              {this.sidebarr()}
            </Box>
            {this.sideBarr2()}
            <Box className="rightbar">
              <Box className="topbox">
             
                <Typography data-test-id="backbtn" className="titleuser2">
                <ArrowBackIcon onClick={this.handleNavigateAllocator} style={{
                color: "blue",
                cursor: "pointer",fontFamily:"Dm sans"
              }} />To-Do List
                </Typography>
              
             
              </Box>
              <Box className="topbox2">
            <Typography className="titleuser3">
              <Breadcrumbs separator={<NavigateNextIcon fontSize="small" style={{marginBottom:"2px"}} />} aria-label="breadcrumb">
                <Link onClick={this.handleNavigateAllocator} style={{
                  fontSize: "14px",
                  fontWeight: 400,
                  color: "#475569",
                  cursor: "pointer",
                  fontFamily:"DM Sans"
                }}  >
                  Task
                </Link>
                <Typography
                  style={{
                    fontSize: "14px",
                    fontWeight: 400,
                    color: "#0F172A",
                    fontFamily:"DM Sans"
                  }}
                  color="textPrimary"
                >
               To-Dos
                </Typography>

              </Breadcrumbs>

            </Typography>



          </Box>
             <Box style={{
              position:"relative"
             }}>
              <Box style={{
                width: "92.5%",
                display: "flex",
                marginLeft: "60px",
                marginTop: "20px",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: "10px"
              }}>
    
                <TextField
              
                  variant="outlined"
                  placeholder="Search by to-do name or assignee"
                  style={{
                    width: "100%"
                  }}
                  InputProps={{
                    style: {
                      height: '44px',
                      borderRadius: '12px',
                      fontSize: '16px',
                      fontWeight: 400,
                    },
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon style={{
                          color: "#BFBFBF",
                          width: "24px",
                          height: "24px"
                        }} />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={this.handleFilterPopupOpen}>
                          <img src={filter} alt="" />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <button
                onClick={this.handleOpenToDo}
                style={{
                  backgroundColor:  "#355EE7", 
                  color:  "white" , 
                  width: "175px",
                  height: '44px',
                  textTransform: "none" as "none",
                  borderRadius: "8px",
                  fontSize: "16px",
                  fontWeight: "400",
                  fontFamily: "DM Sans",
                  cursor: "pointer",
                  border: 'none',
                 gap:"3px",
                 display:"flex",
                 justifyContent:"center",
                 alignItems:"center",
                }}
                 data-test-id="signup" className="landingSignupBtn"  >
                  <img style={{width:"18px",height:"18px"}} src={toDo} alt="" />  Add to-do
                </button>
               
              </Box>
              {this.state.isFilterPopupOpen &&(
                
                <Box
           className="filterpopupbox"      
           >
         <Box style={{display:"flex",justifyContent:"end",zIndex:1500}} onClick={this.handleFilterPopupOpen}>
<CloseOutlinedIcon />
          </Box>
         Filters
<Box 
className="filterSearchBox" >
                    <Box 
                    className="selectBoxes">
       <Select
    placeholder="Status"
    value={options1.find((option) => option.value === this.state.filterStatus)}
    onChange={this.handleStatusChange}
    data-test-id="viewPriorityCha"
    options={options1}
    classNamePrefix="react-select"
    className="selectLegal"
    components={{SingleValue,Option }} />

                </Box>

                    <Box 
                     className="selectBoxes">
       <Select
    placeholder="Priority"
    value={options2.find((option) => option.value === this.state.filterPriority)}
    onChange={this.handlePriorityChange}
    data-test-id="PrioritySelect"
    options={options2}
    classNamePrefix="react-select"
    className="selectLegal"
    components={{SingleValue,Option }} />

                </Box>
                    <Box
                    className="selectBoxes"
                      >
                    <Typography
  className="selectLegal"
  component="input"
  type="date"
  data-test-id="FilterDate"
  placeholder="Due"
  value={this.state.filterDate}
  onChange={this.handleDateChange}
  min={new Date().toISOString().split("T")[0]} 
  style={{
    borderRadius: "12px",
    marginBottom: "8px",
    backgroundColor: "#fff",
    fontSize: "16px",
    height: "44px",
    padding: "8px",
    fontWeight: 400,
    fontFamily: "DM sans",
  }}
></Typography>

                    </Box>
</Box>
</Box>
          )}
              
                <Box style={{
      display: "flex",
      alignItems: "center",  
      gap: "4px",  marginLeft:"60px"
    }}>
    </Box>
                
                </Box>
              <Box className="userdetailbox">
                <Typography style={{
                  fontSize: "18px",
                  fontFamily: "Dm sans",
                  fontWeight: 700,
                }}>To-Dos List</Typography>
                <TableContainer className="scrollable-box"
                  component={Paper}
              
                  style={{
                    borderRadius: '12px',
                    border: '1px solid #F0F0F0',
                    marginTop: '25px',
                    boxShadow: 'none',
                  }}
                >
                  <Table  stickyHeader >
                    <TableHead >
                      <TableRow>
                      
                        <TableCell
                          style={{
                            color: '#8C8C8C',
                            fontSize: '12px',
                            fontWeight: '500',
                            alignItems:"center", position: 'sticky',
                            top: 0,
                            backgroundColor: '#FFF',
                            zIndex: 100,
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: '8px',
                            }} >
                        <CheckCircleOutlineIcon style={{marginLeft:"10px",width:"24px",height:"24px",color:"BFBFBF",marginBottom:"2px"}} />
                          
                          <Typography style={{
                            color: '#8C8C8C',
                            fontSize: '12px',
                            fontWeight: '500',
                            alignItems:"center",
                            fontFamily:"DM Sans"
                          
                          }}> 
                          To-Do Name
                            </Typography>
                            </div>
                        </TableCell>
                        <TableCell
                          style={{
                            color: '#8C8C8C',
                            fontSize: '12px',
                            fontWeight: '500',
                            fontFamily:"Dm sans"
                     
                          }}
                        >
                          Due Date
                        </TableCell>
                        <TableCell style={{
                          color: "#8C8C8C",
                          fontSize: "12px",
                          fontWeight: "500",
                          fontFamily:"Dm sans"
                       
                        }}>Status</TableCell>
                        <TableCell style={{
                          color: "#8C8C8C",
                          fontSize: "12px",
                          fontWeight: "500",
                          fontFamily:"Dm sans"
                       
                        }}>Priority</TableCell>
                        <TableCell style={{
                          color: "#8C8C8C",
                          fontSize: "12px",
                          fontWeight: "500",
                          fontFamily:"Dm sans"
                         
                        }}>Assignee</TableCell>
                         <TableCell style={{
                          width:"4px"
                         }}></TableCell>
                       
                      
                      </TableRow>
                    </TableHead>
                
              
                    <TableBody  style={{
                        borderSpacing:0,
                    }}>
                 {this.renderTableRows()}
                    </TableBody>
                  
                    </Table>
                    <Drawer data-test-id="todo-drawer"  anchor="right"
              open={this.state.drawerOpen}
              onClose={this.toggleDrawerClose} >
              {this.drawerContents()} </Drawer>
                </TableContainer>
              </Box>
            </Box>
       
            <Dialog
              PaperProps={{
                style: {
                  width: '600px',
                  maxWidth: '600px',
                  height: "239px",
                  borderRadius: "12px",
                  padding: "12px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }
              }}  open={this.state.openDialog} onClose={this.handleCloseDelete}
              aria-labelledby="responsive-dialog-title" >
    
              <Box style={{
                border: "1px solid #D1FAE5",
                width: "576px",
                height: "215px",
                borderRadius: "8px",
    
              }}>
                <DialogTitle id="responsive-dialog-title" style={{
                  textAlign: 'right',
                  padding: "7px"
                }}><CloseIcon style={{ cursor: 'pointer' }} onClick={this.handleCloseDelete} /></DialogTitle>
                <DialogContent
                  style={{
                    padding: "0px"
                  }}
                >
                  <DialogContentText  >
                    <Typography style={{
                      backgroundColor: '#ffffff',
                      color: '#0F172A',
                      fontSize: '19px',
                      fontWeight: 400,
                      fontFamily: 'DM Sans',
                      marginBottom: '20px',
                    }}>
                      <Box style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                      }}>
                        <img src={right} className="imageBox2" alt="success" />
                      </Box>
                      <Typography style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "25px",
                        fontSize: "22px",
                        fontFamily: "Dm sans",
                        fontWeight: 400
                      }}>
                       
                       {this.getResponseMessage()}
                      </Typography>
                    </Typography>
                  </DialogContentText>
                </DialogContent></Box>
    
    
            </Dialog>
       
            {this.state.openToDo && (
            <CustomizeDialog data-test-id="customizeDialog" >

              <Box   data-test-id="closePop"  style={{
                marginLeft: "24px", fontFamily: "Dm sans", fontWeight: 500,
                fontSize: "22"
              }}>
                <Typography style={{
                  display: "flex",
                  height: "30px"
                }}>
                  <IconButton
                    onClick={this.handleCloseBox}
                    style={{ marginLeft: "auto", marginBottom: "8%", width: "24px", height: "24px" }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Typography>

                <Typography style={{
                  fontSize:"22px",
                  fontWeight:500,
                  fontFamily:"DM Sans"
                }} data-test-id="accept1" >{this.getEditTitle()}</Typography>
             
              </Box>
              <DialogContent style={{
                marginTop: "11px",
                overflowY:"visible"
              }} >
                 <Box display="flex" flexDirection="column">
                  <Box>
                    <InputLabel

                      style={{
                        fontWeight: 700,
                        fontSize: "14px",
                        marginBottom: "4px",
                        fontFamily: "Dm sans",
                        color: this.getInput('to_do_name', validationErrors),
                       
                      }}
                    >
                      To-Do Name
                      <span style={{ color: "red" }}>*</span>
                    </InputLabel>

                    <Box
                      style={{ display: 'flex', alignItems: 'center', position: 'relative', width: '100%', marginTop: "10px" }}>
                     <Typography
                          component="input"
                          placeholder="Enter the sub-task name"
                          data-test-id="viewChange"
                          value={this.state.ViewTodoList.attributes.to_do_name}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleChange(e, 'to_do_name')}
                           style={{
                        width: '100%',
                        border: this.getBorderStyle('to_do_name', validationErrors),
                         borderRadius: "12px",
                        marginBottom: '8px',backgroundColor: '#fff',
                        fontSize: '16px', height: "44px",
                        padding: "8px",
                        fontWeight:400,
                        fontFamily:"DM sans" }}></Typography>
                    </Box>
                  </Box>

                
                </Box>
                <Box>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                      marginTop:"13px"
                    }}
                  >
                    <Typography style={{
                      fontWeight: 700,
                      fontSize: "14px",
                      marginBottom: "4px",
                      fontFamily: "Dm sans",
                      color: "black"
                    }}>
                      To-Do description
                    </Typography >


                  </Box>
                  <textarea
                     value={this.state.ViewTodoList.attributes.to_do_description}
                     onChange={(e) => this.handleChange(e, 'to_do_description')}
                     data-test-id="viewDesc"
                    placeholder="Describe the To-Do description"
                    style={{
                      width: "100%",
                      height: "150px",
                      borderRadius: "12px",
                      border: "1px solid #C3D1FF",
                      fontSize: "16px",
                      fontWeight: 400,
                      fontFamily: "Dm sans",
                      marginBottom: "10px",
                      padding: "8px"
                    }}>

                  </textarea>
                </Box>
                <Box display="flex" flexDirection="column">
                  <Box style={{
                        marginTop:"13px"
                  }}>
                    <InputLabel

                      style={{
                        fontWeight: 700,
                        fontSize: "14px",
                        marginBottom: "4px",
                        fontFamily: "Dm sans",
                        color: "black"
                      }}
                    >
                      Priority
                    </InputLabel>

                    <Box
                      style={{ display: 'flex', alignItems: 'center', position: 'relative', width: '100%', marginTop: "10px" }}>
       <Select
    placeholder="Select a task priority"
    value={options.find((option) => option.value === this.state.ViewTodoList.attributes.priority)}
    onChange={this.handleChanged}
    data-test-id="viewPriority"
    options={options}
    className="selectLegal"
    components={{SingleValue,Option }} />
                    </Box>
                  </Box>

                </Box>
                <Box  style={{
                        marginTop:"26px"
                  }}>
                    <InputLabel

                      style={{
                        fontWeight: 700,
                        fontSize: "14px",
                        marginBottom: "4px",
                        fontFamily: "Dm sans",
                        color: this.getInput('deadline', validationErrors),
                      }}
                    >
                      Deadline
                      <span style={{ color: "red" }}>*</span>
                    </InputLabel>

                    <Box
                      style={{ display: 'flex', alignItems: 'center', position: 'relative', width: '100%', marginTop: "10px" }}>
                     <Typography
                          component="input"
                          min={new Date().toISOString().split('T')[0]}
                          type="date"
                          data-test-id="viewDeadline"
                          placeholder="Set a deadline"
                          value={this.state.ViewTodoList.attributes.deadline}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleChange(e, 'deadline')}
                           style={{
                        width: '100%',
                        border: this.getBorderStyle('deadline', validationErrors),
                         borderRadius: "12px",backgroundColor: '#fff',
                        fontSize: '16px', height: "44px",
                        padding: "8px",
                        fontWeight:400,
                        fontFamily:"DM sans" }}></Typography>
                    </Box>
                  </Box>
                <Box  style={{
                        marginTop:"13px"
                  }}>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%"
                    }}
                  >
                    <Typography style={{
                      fontWeight: 700,
                      fontSize: "14px",
                      marginBottom: "4px",
                      fontFamily: "Dm sans"
                    }}>
                      Assign Lawyer
                    </Typography >
                  </Box>

                 <Box style={{
                  position:"relative"
                 }}>
                  <TextField
                    placeholder="Search by Lawyer name, service or jurisdiction"
                    value={this.state.searchQuery}
                    onChange={this.handleSearchChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon style={{
                            color: "DADADA",
                            width: "24px",
                            height: "24px"
                          }} />
                        </InputAdornment>
                      ),
                      disableUnderline: true,
                      inputProps: {
                        style: {

                        },
                      },
                    }}
                    inputProps={{
                      style: {
                        color: "black",
                      },
                    }}
                    className="selectBox"
                  />
                  {this.state.openPop && (
            
            <Box
              style={{
                borderRadius: "12px",
                display: "flex",
                flexDirection: "column",
                position: "absolute",
                top: "96.5%",
                left: "50%",
                transform: "translate(-50%,1%)",

                zIndex: 1300,
                boxShadow: "0 4px 12px rgba(0, 0, 0, 0.2)",
                backgroundColor: "white",
                height: "250px",
                overflowY: "auto",
                width: "98%"
              }}
            >

              <List style={{ maxHeight: "200px", overflowY: "auto" }}>
                {this.state.searchData.length > 0 ? (
                  this.state.searchData?.map((lawyer, index) => {

                    return (
                      <>
                        <ListItem style={{
                          height: "45%",
                          marginTop:"8px"
                        }} key={index} 
                        button data-test-id="clickButton" onClick={() => this.handleLawyerSelect(lawyer)}>
                          <Checkbox data-test-id="onChangeClick" style={{
                            marginBottom: "11%"
                          }}
                            checked={this.state.searchLawyer.some((selected) => selected?.attributes?.associated_lawyer_details?.data?.attributes?.first_name === lawyer?.attributes?.associated_lawyer_details?.data?.attributes?.first_name)}
                            onChange={() => this.handleLawyerSelect(lawyer)}
                          />
                          <Box style={{
                            width: "99%",

                            display: "flex",
                            borderBottom: "1px solid #C3D1FF",
                            padding: "8px",
                            gap: "6px"
                          }}>
                            <ListItemAvatar>
                              <Avatar style={{
                                marginBottom: "3%",
                                width: "56px",
                                height: "56px",
                                marginLeft: "3px"
                              }} src={userpic} />
                            </ListItemAvatar>
                            <Box style={{
                              display: "flex",
                              flexDirection: "column"
                            }} >
                              <Box
                                style={{
                                  display: "flex",

                                  gap: "15px",
                                  fontFamily: "Dm sans",

                                }}
                              >
                                <Typography style={{ fontWeight: 400, fontSize: "14px", fontFamily: "Dm sans", marginLeft: "2px" }}>
                                  {lawyer.attributes?.associated_lawyer_details?.data?.attributes?.first_name} {lawyer.attributes.associated_lawyer_details.data.attributes.last_name}
                                </Typography>
                                <Typography style={{ fontWeight: 400, fontSize: "16px", fontFamily: "Dm sans", color: "#8C8C8C", gap: "3px", display: "flex", height: "1px" }}>
                                  4.2<Typography><StarRateIcon style={{
                                    color: "#F59E0B",
                                    width: "19px",
                                    height: "25px"
                                  }} /></Typography>
                                </Typography>
                              </Box>
                              <Box style={{
                                display: "flex",

                              }}>
                                <Typography
                                  variant="body2"
                                  style={{
                
                                    fontFamily: "Dm sans",
                                    display: "flex",
                                    alignItems: "center",
                                    padding: "2px",
                                    gap: "3px"}}>
                                  <VpnLockIcon style={{
                                    height: "18px",
                                    width: "18px",
                                    color: "#355EE7",}}/>
                                     <span 
                                     style={{
                                    fontSize: "11px",
                                    fontFamily: "Dm sans",
                                    fontWeight: 400}}>
                                      {lawyer?.attributes?.associated_lawyer_details?.data?.attributes?.jurisdiction}
                                      </span></Typography>
                              </Box><Box 
                              style={{
                                display: "flex",
                                gap: "8px"  }}>

{lawyer?.attributes?.associated_lawyer_details?.data?.attributes?.legel_service_type?.slice(0, 3)?.map((service, index) => (
                                <Typography
                                  style={{ color: "#355EE7",
                                    backgroundColor: "#ECF1FF",
                                    height: "auto",
                                    fontSize: "11px",
                                    fontWeight: 400,
                                    display: "flex",
                                    alignItems: "center",
                                    borderRadius: "6px",
                                    padding: "2px 6px",
                                    fontFamily: "Dm sans",
                                    marginTop: "3px",
                                    marginLeft: "2px",
                                    width: "auto",
                                 
                                    gap: "4px", }}
                                  key={index} >

                                  <img
                                    src={Services}
                                    style={{ width: "14px",
                                      backgroundColor: "none",
                                      height: "14px",
                                      marginRight: "4px", }}alt=""/>
                                  <span style={{fontFamily:"Dm sans"}}>{service.legal_service_name}</span> </Typography>))}
                               { lawyer && lawyer.attributes.associated_lawyer_details.data.attributes.legel_service_type?.length > 1 && (
                      <Typography
                        style={{ color: "#355EE7",
                        borderRadius: "6px",
                          backgroundColor: "#ECF1FF",
                          fontFamily: "Dm sans",
                          padding: "2px 6px",
                          marginLeft: "2px",
                          marginTop: "3px",
                          width: "auto",
                          height: "auto",
                          fontWeight: 400,
                          fontSize: "11px",
                          display: "flex",
                          alignItems: "center",
                          gap: "4px", }}>
                        <img
                          src={Services}
                          style={{
                            width: "14px",
                            backgroundColor: "none",
                            height: "14px",
                            marginRight: "4px", }}
                          alt=""/>
                        <span>+{lawyer?.attributes.associated_lawyer_details.data.attributes?.legel_service_type.length - 1}</span> </Typography>
                    )}
                              </Box>
                              </Box></Box>
                        </ListItem></>)
                  })
                ) : (
                  <Typography style={{ padding: "16px",fontFamily: "Dm sans" }}>No Current Associated Lawyers
                  </Typography>
                )} </List></Box>
      
        )}
                  </Box>
                  {this.state.searchLawyer.length > 0 && (
                    <Box mt={2} className="selectedUser">
                      {this.state.searchLawyer?.map((lawyer, index) => (
                        <Box data-test-id={`mapFunction${index}`}
                        key={lawyer?.attributes?.associated_lawyer_details.data.id}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            border: '1px solid #C3D1FF',
                            borderRadius: "10px",
                            alignItems: "center",
                            gap: "8px",
                            padding: "6px",
                            fontFamily: "Dm sans",
                          }}>
                          <Avatar src={userpic} style={{
                            marginBottom: "3%",
                            width: "56px",
                            height: "56px",
                            marginLeft: "3px"
                          }} />
                          <Box>
                            <Box
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "15px",
                                fontFamily: "Dm sans",
                                width: "100%"
                              }}
                            >
                              <Typography style={{ fontWeight: 400, fontSize: "14px", fontFamily: "Dm sans", marginLeft: "2px" }}>
                                {lawyer.attributes.associated_lawyer_details.data.attributes.first_name}  {lawyer.attributes.associated_lawyer_details.data.attributes.last_name}
                              </Typography>
                              <Typography style={{ fontWeight: 400, fontSize: "16px", fontFamily: "Dm sans", color: "#8C8C8C", gap: "3px", display: "flex" }}>
                                4.2 <Typography><StarRateIcon style={{
                                  color: "#F59E0B",
                                  width: "19px",
                                  height: "25px"
                                }} /></Typography>
                              </Typography>
                            </Box>
                            <Box style={{
                              display: "flex",
                              flexDirection: "column"
                            }}>
                              <Typography
                                variant="body2"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  padding: "2px",
                                  fontFamily: "Dm sans",
                                  gap: "3px"
                                }}
                              >
                                <VpnLockIcon style={{
                                  height: "18px",
                                  width: "18px",
                                  color: "#355EE7",
                                }} /> <span style={{
                                  fontSize: "11px",
                                  fontFamily: "Dm sans",
                                  fontWeight: 400
                                }}>{lawyer?.attributes?.associated_lawyer_details?.data?.attributes?.jurisdiction}</span>
                              </Typography>
                            </Box>
                            <Box style={{
                              display: "flex",
                              gap: "8px"
                            }}>
                                      {lawyer?.attributes?.associated_lawyer_details?.data?.attributes?.legel_service_type?.slice(0, 3)?.map((service, index) => (
                                <Typography
                                  style={{
                                    color: "#355EE7",
                                    backgroundColor: "#ECF1FF",
                                    borderRadius: "6px",
                                    padding: "2px 6px",
                                    fontFamily: "Dm sans",
                                    marginTop: "3px",
                                    marginLeft: "2px",
                                    width: "auto",
                                    height: "auto",
                                    fontSize: "11px",
                                    fontWeight: 400,
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "4px",
                                  }}
                                  key={index}
                                >

                                  <img
                                    src={Services}
                                    style={{
                                      width: "14px",
                                      backgroundColor: "none",
                                      height: "14px",
                                      marginRight: "4px",
                                    }}
                                    alt=""
                                  />
                                  <span>{service.legal_service_name}</span>
                                </Typography>
                             ))}
                               { lawyer && lawyer.attributes.associated_lawyer_details.data.attributes.legel_service_type?.length > 1 && (
                      <Typography
                        style={{
                          color: "#355EE7",
                          backgroundColor: "#ECF1FF",
                          borderRadius: "6px",
                          padding: "2px 6px",
                          fontFamily: "Dm sans",
                          marginTop: "3px",
                          marginLeft: "2px",
                          width: "auto",
                          height: "auto",
                          fontSize: "11px",
                          fontWeight: 400,
                          display: "flex",
                          alignItems: "center",
                          gap: "4px",
                        }}>
                        <img
                          src={Services}
                          style={{
                            width: "14px",
                            backgroundColor: "none",
                            height: "14px",
                            marginRight: "4px",
                          }}
                          alt=""
                        />
                        <span>+{lawyer?.attributes.associated_lawyer_details.data.attributes?.legel_service_type.length - 1}</span>
                      </Typography>
                    )}
                            </Box>
                          </Box>
                          <IconButton data-test-id="iconButton"                
                         onClick={() => this.handleRemoveSelectedLawyer(lawyer)}
                            style={{ marginLeft: "auto", marginBottom: "8%", width: "24px", height: "24px" }}
                          >
                            <CloseIcon />
                          </IconButton>
                        </Box>
                      ))}

                    </Box>
                  )}
                {this.state.ViewTodoList?.attributes?.assigned_lawyers?.data?.length > 0 ? (
  <Box mt={2} className="selectedUser">
    {this.state.ViewTodoList.attributes.assigned_lawyers.data.map((lawyer, index) => {
      const associatedDetails = lawyer?.attributes?.associated_lawyer_details?.data?.attributes;

      return (
        <Box
          data-test-id={`mapFunction${index}`}
          key={index}
          style={{
            display: "flex",
            flexDirection: "row",
            border: "1px solid #C3D1FF",
            borderRadius: "10px",
            alignItems: "center",
            gap: "8px",
            padding: "6px",
            fontFamily: "Dm sans",
          }}
        >
          <Avatar
            src={userpic}
            style={{
              marginBottom: "3%",
              width: "56px",
              height: "56px",
              marginLeft: "3px",
            }}
          />
          <Box>
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                gap: "15px",
                fontFamily: "Dm sans",
                width: "100%",
              }}
            >
              <Typography
                style={{
                  fontWeight: 400,
                  fontSize: "14px",
                  fontFamily: "Dm sans",
                  marginLeft: "2px",
                }}
              >
                {associatedDetails?.first_name || "N/A"} {associatedDetails?.last_name || ""}
              </Typography>
              <Typography
                style={{
                  fontWeight: 400,
                  fontSize: "16px",
                  fontFamily: "Dm sans",
                  color: "#8C8C8C",
                  gap: "3px",
                  display: "flex",
                }}
              >
                4.2
                <Typography>
                  <StarRateIcon
                    style={{
                      color: "#F59E0B",
                      width: "19px",
                      height: "25px",
                    }}
                  />
                </Typography>
              </Typography>
            </Box>
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography
                variant="body2"
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "2px",
                  fontFamily: "Dm sans",
                  gap: "3px",
                }}
              >
                <VpnLockIcon
                  style={{
                    height: "18px",
                    width: "18px",
                    color: "#355EE7",
                  }}
                />{" "}
                <span
                  style={{
                    fontSize: "11px",
                    fontFamily: "Dm sans",
                    fontWeight: 400,
                  }}
                >
                  {associatedDetails?.jurisdiction || "N/A"}
                </span>
              </Typography>
            </Box>
            <Box
              style={{
                display: "flex",
                gap: "8px",
              }}
            >
              {associatedDetails?.legel_service_type?.slice(0, 3)?.map((service, index) => (
                <Typography
                  key={index}
                  style={{
                    color: "#355EE7",
                    backgroundColor: "#ECF1FF",
                    borderRadius: "6px",
                    padding: "2px 6px",
                    fontFamily: "Dm sans",
                    marginTop: "3px",
                    marginLeft: "2px",
                    fontSize: "11px",
                    fontWeight: 400,
                    display: "flex",
                    alignItems: "center",
                    gap: "4px",
                  }}
                >
                  <img
                    src={Services}
                    alt=""
                    style={{
                      width: "14px",
                      height: "14px",
                      marginRight: "4px",
                      fontFamily:"Dm sans"
                    }}
                  />
                  {service.legal_service_name}
                </Typography>
              ))}
              {associatedDetails?.legel_service_type?.length > 3 && (
                <Typography
                  style={{
                    color: "#355EE7",
                    backgroundColor: "#ECF1FF",
                    borderRadius: "6px",
                    padding: "2px 6px",
                    fontFamily: "Dm sans",
                    marginTop: "3px",
                    marginLeft: "2px",
                    fontSize: "11px",
                    fontWeight: 400,
                    display: "flex",
                    alignItems: "center",
                    gap: "4px",
                  }}
                >
                  <img
                    src={Services}
                    alt=""
                    style={{
                      width: "14px",
                      height: "14px",
                      marginRight: "4px",
                    }}
                  />
                  +{associatedDetails.legel_service_type.length - 3}
                </Typography>
              )}
            </Box>
          </Box>
          <IconButton
            data-test-id="iconButton"
            onClick={() => this.handleRemoveSelectedLawyer2(lawyer)}
            style={{
              marginLeft: "auto",
              marginBottom: "8%",
              width: "24px",
              height: "24px",
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      );
    })}
  </Box>
) : (
  <Typography></Typography>
)}
                </Box>

              </DialogContent>
              <DialogActions style={{
                width: "94%"
              }}>
                <Button
                  onClick={this.handleCloseBox}
                  data-test-id="cancelBtn1"
                  style={{
                    border: "1px solid #8C8C8C",
                    width: "150px",
                    height: "44px",
                    display: "flex",
                    alignItems: "center",
                    borderRadius: "8px",
                    fontSize: "16px",
                    fontWeight: 700,
                    fontFamily: "Dm sans",
                    color: "#8C8C8C",
                    textTransform: "none",
                  }}
                >
                  Cancel
                </Button>
  
 
      <Button
        data-test-id="acceptBtn-1"
        onClick={this.handleCreateToDos}
        disabled={!this.state.isFormValid}  
        style={{
          ...this.getButtonColors(),
          borderRadius: "8px",
          width: "150px",
          height: "44px",
          display: "flex",
          alignItems: "center",
          fontSize: "16px",
          fontWeight: 700,
          fontFamily: "Dm sans",
          textTransform: "none",
        }}
      >
      {this.getEditButton()}
      </Button>

              </DialogActions>

            </CustomizeDialog>
          )}
          </MainDiv>
          </>
        );
    }
}
export default ProjectReporting;

const MainDiv = styled('div')({
  "& .selectStyle": {
    width: "400px",
    marginTop: 8,
    borderRadius: "8px",
    border: "1px solid #E0E0E0",
    padding: "8px",
    backgroundColor: "#F9F9F9",
    boxSizing: "border-box",
  },
 "& .menuItemStyles": {
    display: "flex",
    alignItems: "center",
    width: "100%",
    justifyContent: "start",
  },
  "& .custom__control":{
    border: "1px solid #ddd",
    borderRadius: "5px",
    padding: "5px",
    cursor: "pointer",
    fontSize: "14px"
  },
  
  ".custom__menu":{
    border: "1px solid #ddd",
    borderRadius: "10px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    padding: "5px 0",
    marginTop: "5px"
  },
  
  "& .custom__option":{
    padding: "10px",
    textAlign: "center",
    cursor: "pointer",
    color: "#333"
  },

  "& .custom__option:first-of-type": {
    backgroundColor: "#fadbd8",
    color: "#e74c3c"
  },
  
 "& .custom__option:nth-of-type(2) ":{
    backgroundColor: "#f9e79",
    color: "#f39c12"
  },
  
 "& .custom__option:last-of-type": {
    backgroundColor: "#d5f5e3",
    color: "#27ae60"
  },
  

 "& .custom__option--is-selected ":{
    backgroundColor: "#d0d0d0 !important",
    color: "#000"
  },
  "& .menuItemList": {
    width:"16px",
    height:"16px",
    color:"#BFBFBF",
    marginBottom:"1.5px",
    marginRight:"6px"
  },
  "& .menuItem": {
    fontSize:"16px",
    fontWeight:400,
    fontFamily:"DM Sans",
    display:"flex",
    alignItems:"center",
    gap:"6px",
    marginTop:"10px",
    cursor:"pointer"
  },
 "& .menuItem:hover": {
    backgroundColor:"#F5F5F5",
    borderRadius:"4px"
  },
    "& .selectedUser": {
      border: '1px solid #F0F0F0', borderRadius: '10px', display: 'flex', flexDirection: 'column', gap: '10px',
      maxHeight: '82px',
      overflowY: 'auto',
      backgroundColor: " #FAFAFA",
      scrollbarWidth: 'thin',
      scrollbarColor: '#355EE7 #F0F0F0',
      fontFamily: "Dm sans",
      width: "97.5%",
      padding: "6px"
    },
    "& .filterpopupbox":{
      borderRadius: "12px",
      display: "flex",
      transform: "translate(-50%,1%)",
      zIndex: 1300,
      boxShadow: "0 4px 12px rgba(0, 0, 0, 0.2)",
      backgroundColor: "white",
      height: "110px",
      padding:"24px",
      width: "60%",
      position:"absolute",
      left:"50%",
      flexDirection:"column",
      fontFamily:"Dm sans",
      fontWeight:500,
      color:"355EE7",
      "@media (max-width: 1876px)": {
        width: "60%"},
      "@media (max-width: 1844px)": {width: "60%",
      left:"45%",
      },
      "@media (max-width: 1800px)":
       {
        width: "60%"
      },
      "@media (max-width: 1795px)": 
      {
        width: "60%",},
      "@media (max-width: 1596px)": {
        width: "60%",
      },
      "@media (max-width: 1500px)": 
      {
        width: "76.4%",
        fontFamily:"Dm sans",
      },
      
      "@media (max-width: 1440px)": {
        width: "75.7%",},
      "@media (max-width: 1400px)": {
        width: "75.5%",
      },
      "@media (max-width: 1385px)": {
        width: "75.5%",
        display: "flex",
      },
      "@media (max-width: 1365px)": {
        width: "74.5%",
      },
      "@media (max-width: 1300px)": {
        width: "73.7%",
      },
      "@media (max-width: 1263px)": {
        width: "73%",
        borderRadius: "12px",
      },
      "@media (max-width: 1200px)": {
        width: "71.7%",
      },
      "@media (max-width: 1120px)": {
        width: "70.1%",
      },
      "@media (max-width: 1100px)": {
        width: "67.7%",
      },
      "@media (max-width: 1000px)": {
        width: "71.7%",
      },
      "@media (max-width: 991px)": {
        width: "69.7%",
      },
      "@media (max-width: 1025px)": {
        width: "66.7%",
      },
      "@media (max-width: 885px)": {
     height:"242px",
      }
    },
    "& .selectlegal": {
      width: '100% !important',
      fontWeight: 400,
      fontFamily: "DM Sans",
      border: ' 1px solid #C3D1FF !important', 
      marginTop:"10px",
      borderRadius: '12px', fontSize: '16px',
      height: "44px",
   "&  .react-select__control ":{
       border:"none !important",
       background:"none !important",
       borderColor:"#C3D1FF",
       borderRadius:" 12px",
       height:"42px"
      },
    "&:focus ":{
        borderRadius:" 12px",
        border:"none !important",
        height:"44px"
      },
    
      "& .css-1u9des2-indicatorSeparator": {
        backgroundColor: "white"},
      "@media (max-width:500px)": {
        borderRadius: '12px', fontSize: '8px',
        fontWeight: 200,
        fontFamily: "DM Sans",
        width: '100% !important',
        height: "44px",},
    },
   
    "& .filterSearchBox":{
      display:"flex",
      gap:"67px",
      alignItems:"center",
      justifyContent:"center",
      "@media (max-width: 1167px)": {
        gap:"22px",
      },
      "@media (max-width: 1050px)": {
        gap:"15px",
      },
      "@media (max-width: 1025px)": {
        gap:"7px",
      },
      "@media (max-width: 885px)": {
       display:"flex",
       flexDirection:"column",
       alignItems:"center",
       width:"100%"
      }
    },
    "& .selectBoxes" :{
      display: 'flex', alignItems: 'center', position: 'relative', width: '280px', marginTop: "10px" ,
        
      "@media (max-width: 500px)": {
        width:"200px"
         }
    },
    "& .drawerResp": {
      width: "100vh",
      maxWidth: "812px",
      padding: "16px",
      height: "auto",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      fontSize: "Dm sans",
      "@media (max-width:900px)": {
        width: "30vh",
      },
    },
    "& .scrollable-box": {
      height: '54.5%',
      overflowY: 'auto',
    
      marginBottom: "10px",
      flexGrow: 1,
      paddingRight: '0px',
      "@media (max-width:800px)": {
        display: 'inline-block',
      },
    }, "& .scrollable-box::-webkit-scrollbar": {
      width: "8px",
    },
    "& .scrollable-box::-webkit-scrollbar-track": {
      background: "#f1f1f1",
    },
    "& .scrollable-box::-webkit-scrollbar-thumb": {
      backgroundColor: "#91AAFF",
      borderRadius: '10px',
      border: '2px solid #f1f1f1',
    },
    "& .scrollable-box::-webkit-scrollbar-thumb:hover": {
      backgroundColor: "#5D85FF",
    },
    "& .tableFont": {
      width: "12%",
      fontSize: "12px",
      fontWeight: 700,
      fontFamily: "Dm sans"
    },
    "& .custom-datepicker-input": {
      width: "100%",
      border: "1px solid #C3D1FF",
      borderRadius: "12px",
      fontSize: "16px",
      fontWeight: 400,
      outline: "none",
      height: "44px",
      padding: "10px",
    },
    "& .custom-datepicker-input.error": {
      border: "1px solid red"
    },
  
    "& .custom-datepicker-input::placeholder ": {
      color: '#BFBFBF',
      fontFamily: 'DM Sans',
      fontSize: "16px",
      fontWeight: 400
    },
   
    width: '100%',
    height: '100%',
    display: 'flex',
    "@media (max-width:991px)": {
      display: "block",
    },
    "& .selectCountry": {
      width: '100%',
      border: '1px solid #C3D1FF', borderRadius: "12px",
      marginBottom: '8px', outline: 'none', backgroundColor: '#fff',
      fontSize: '16px', height: "44px",
      backgroundRepeat: 'no-repeat', backgroundPositionX: '95%',
      backgroundImage: 'url("data:image/svg+xml;utf8,<svg fill=\'%23c5cbc6\' height=\'20\' viewBox=\'0 0 24 24\' width=\'20\' xmlns=\'http://www.w3.org/2000/svg\'><path d=\'M7 10l5 5 5-5z\'/></svg>")',
      fontWeight: 400, fontFamily: "DM Sans",
      backgroundPositionY: 'center', appearance: 'none',
      '&:hover': {
        border: '1px solid #C3D1FF', borderRadius: "12px"
      },
      "&:focus": {
        border: '2px solid #C3D1FF;', borderRadius: "12px"
      },
    },
    "& .offcanvascss": {
      position: 'fixed',
      top: '0px !important',
      bottom: '0px !important',
      left: '0% !important',
      width: '252px !important',
      height: '100vh !important',
      backgroundColor: '#fff',
      zIndex: 9999,
    },
    "& .offcanvasbackdrop": {
      position: "fixed",
      top: '0px',
      left: '252px',
      width: 'calc(100% - 252px)',
      height: '100vh',
      zIndex: 999,
      backgroundColor: '#000',
      opacity: 0.5,
    },
    "& .landingSignupBtn": {
      backgroundColor: "#355EE7",
      color: "white",
      width: "145px",
      height: '44px',
      textTransform: "none" as "none",
      borderRadius: "8px",
      fontSize: "16px",
      fontWeight: "400",
      fontFamily: "DM Sans",
      cursor: "pointer",
      border: 'none',
      '&:hover': {
        border: '2px solid #B9C5FF',
        boxShadow: 'none !important'
      }
    },
    "& .document": {
      backgroundColor: "#355EE7",
      color: "white",
      width: "179px",
      height: '46px',
      textTransform: "none" as "none",
      borderRadius: "8px",
      padding: "10px",
      fontSize: "16px",
      fontWeight: "400",
      fontFamily: "DM Sans",
      cursor: "pointer",
      border: 'none',
  
    },
    "& .landingLoginBtn": {
      backgroundColor: "#fff",
      width: "145px",
      height: '46px',
      textTransform: "none" as "none",
      borderRadius: "8px",
      padding: "10px",
      color: "#355ee7",
      fontSize: "16px",
      fontWeight: "400",
      fontFamily: "DM Sans",
      cursor: "pointer",
      border: '1px solid #d9d9d9',
      boxShadow: 'none !important',
      '&:hover': {
        border: '2px solid #B9C5FF',
        boxShadow: 'none !important'
      }
  
    },
    "& .firstNamePlaceHolder": {
      fontSize: "16px",
      fontWeight: 400
    },
    "& .sidebar": {
      height: '100%',
      position: "fixed",
  
      margin: 0,
      padding: 0,
      top: '0',
      left: '0',
      zIndex: 9999,
      "@media (max-width: 1100px)": {
        width: "300px"
      },
      "@media (max-width: 991px)": {
        width: '100vw',
        height: '80px'
      }
    },
    "& .mainheader": {
      position: 'fixed',
      left: '350px',
      width: '100%'
    },
    "& .logtxtdialog": {
      backgroundColor: "#355EE7",
      color: '#ffffff',
      borderRadius: '8px',
      fontSize: '16px',
      fontWeight: 400,
      fontFamily: 'DM Sans',
      marginLeft: '15px',
      border: 'none',
      cursor: 'pointer',
      transition: 'all 0.3s ease',
      '&:hover': {
        border: '2px solid #B9C5FF',
        boxShadow: 'none !important',
      },
    },
    "& .Header": {
      height: '50px',
      fontSize: '20px', padding: '20px',
    },
    "& .rightbar": {
      padding: "10px 0px",
      width: "100%",
      marginLeft: '253px',
      backgroundColor: "#fafafa",
      height: "auto",
      "@media (max-width: 1100px)": {
        padding: '20 40px', width: 'calc(100% - 300px)',
        marginLeft: '300px',
      },
      "@media (max-width: 991px)": {
        width: 'auto !important',
        marginLeft: '0px', marginTop: '50px',
        padding: "10px 50px",
      }
    },
    "& .userdetailbox": {
      marginTop:"44px",
      width: '90%',
      padding: "20px",
      backgroundColor: "#ffffff",
      height: "auto",
      marginLeft: "61px",
      borderRadius: "20px",
      boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;",
      marginBottom: "15px",
      "@media (max-width: 1424px)": {
        width: '89%',
        height: "auto",
        marginLeft: "51px",
        borderRadius: "20px",
        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;",
        padding: "20px",
        backgroundColor: "#ffffff",
      },
      "@media (max-width: 1366px)": {
        width: '87%',
        padding: "20px",
        backgroundColor: "#ffffff",
        marginLeft: "61px",
        borderRadius: "20px",
        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;",
        height: "auto",
      },
      "@media (max-width: 1280px)": {
        width: '87%',
        height: "auto",
        marginLeft: "61px",
        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;",
        borderRadius: "20px",
        fontSize: "20px",
        padding: "20px",
        backgroundColor: "#ffffff",
  
      },
      "@media (max-width: 780px)": {
        width: '87%',
        fontSize: "20px",
        height: "auto",
        marginLeft: "31px",
        borderRadius: "20px",
        padding: "20px",
        backgroundColor: "#ffffff",
  
        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;",
      },
      "@media (max-width: 600px)": {
  
        height: "auto",
        marginLeft: "24px",
        borderRadius: "20px",
        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;",
        width: '87%',
        fontSize: "20px",
        padding: "20px",
        backgroundColor: "#ffffff",
      },
      "@media (max-width: 520px)": {
        width: '87%',
        height: "auto",
        marginLeft: "24px",
        borderRadius: "20px",
        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;",
        fontSize: "20px",
        padding: "20px",
        backgroundColor: "#ffffff",
      },
    },
    "& .profilebox": {
      height: '156px',
      display: 'flex', borderBottom: '1px solid #D9D9D9',
      "@media (max-width: 450px)": {
        height: '120px',
      },
    },
    "& .profileImg": {
      display: 'flex',
      justifyContent: "center", width: "80%",
    },
    "& .profiletext": {
      fontSize: '16px',
      fontWeight: 400, color: '#8C8C8C', fontFamily: 'DM Sans',
    },
    "& .userimg": {
      height: '120px',
      width: '120px',
      border: '4px solid #ECF1FF',
      borderRadius: '50%',
      "@media (max-width: 450px)": {
        height: '80px',
        width: '80px',
      },
    },
    '& .topbox': {
      display: 'flex',
      width: "auto",
      height: '61px',
      justifyContent: 'space-between',
      "@media (max-width: 991px)": {
        width: "auto !important",
      },
    },
    '& .topbox2': {
      display: 'flex',
      alignItems:"center",
      width: "auto",
      height: '50px',
      justifyContent: 'space-between',
      "@media (max-width: 991px)": {
        width: "auto !important",
      },
    },
    "& .titleuser": {
      height: '30px',
      width: '230px',
      fontSize: '22px',
      fontWeight: 400,
      fontFamily: "DM Sans",
      color: '#000',
      "@media (max-width: 450px)": {
        width: '200px',
        fontSize: '16px',
      },
    },
    "& .titleuser2": {
      height: '30px',
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      gap: "13px",
      width: '100%',
      fontSize: '27px',
      fontWeight: 700,
      fontFamily: "DM Sans",
      color: '#0F172A',
      marginLeft: "60px",
      marginTop: "13px",
      "@media (max-width: 450px)": {
        width: '200px',
        fontSize: '16px',
      },
    },
    "& .titleuser3": {
      height: '30px',
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      gap: "5px",
      width: '230px',
      fontSize: '22px',
      fontWeight: 400,
      fontFamily: "DM Sans",
      marginLeft: "99px",
      color: '#000',
      "@media (max-width: 450px)": {
        width: '200px',
        fontSize: '16px',
      },
    },
    "& .titlbtn": {
      height: '44px',
      fontSize: '14px', fontWeight: 400, color: '#355EE7', fontFamily: 'DM Sans',
      width: '163px', border: '1px solid #355EE7', borderRadius: '8px', padding: '10px 12px',
      "@media (max-width: 450px)": {
        height: '40px',
        width: '140px', padding: '7px 9px',
      },
    },
    "& .detailsbox": {
      display: 'flex', marginTop: '20px', paddingBottom: '40px',
      borderBottom: '1px solid #D9D9D9', "@media (max-width: 600px)": {
        display: 'block'
      },
  
    },
    "& .detailsbox2": {
      display: 'flex', marginTop: '20px',
      "@media (max-width: 600px)": {
        display: 'block'
      },
  
    },
    "& .personaldetailtext": {
      width: '15%', fontSize: '20px', fontWeight: 700,
      fontFamily: 'DM Sans', color: 'black',
      "@media (max-width: 600px)": {
        width: '100%', marginBottom: '20px'
      },
    },
    "& .personalform": {
      width: '88%',
      position: 'relative',
      "@media (max-width: 600px)": { width: '100%' },
    },
    "& .fullName": {
      width: '100%', display: 'flex', paddingBottom: '30px',
      "@media (max-width: 650px)": {
        display: 'block', width: '100%'
      }
    },
    "& .firstname": {
      width: "49%", marginRight: "10px",
      "@media (max-width: 650px)": {
        width: "91% !important", marginBottom: '15px',
      },
    },
    "& .lastname": {
      width: "49%",
  
      "@media (max-width: 650px)": { width: "91% !important", },
    },
    "& .lawFirm": {
      width: "99%", marginBottom: '15px',
    },
    "& .country": {
      width: "91%", marginBottom: '15px',
    },
    "& .PhoneNumer": {
      width: "91%", marginBottom: '15px',
    },
    "& .Email": {
      width: "91%", marginBottom: '15px',
    },
    "& .Password": {
      width: "91%", marginBottom: '15px', height: "28px",
    },
    "& .passtext": {
      color: "#434343", fontSize: '14px',
      fontWeight: 400, fontFamily: 'DM Sans',
    },
    "& .changepasstext": {
      color: "#6200EA", fontSize: '14px',
      fontWeight: 700, fontFamily: 'DM Sans', cursor: 'pointer', marginTop: '4px',
    },
    "& .accountsbox": {
      display: 'flex', marginTop: '20px',
      "@media (max-width: 600px)": {
        display: 'block',
        marginTop: '10px',
      },
    },
    "& .accountdetailtext": {
      width: '20%', color: "#8C8C8C",
      fontSize: '16px', fontWeight: 400, fontFamily: 'DM Sans',
      "@media (max-width: 600px)": {
        width: '100%',
        marginBottom: '20px'
      },
    },
    "& .accountsactions": {
      width: '84.3%', marginBottom: '40px',
      display: "flex", justifyContent: "flex-end",
      "@media (max-width: 600px)": { width: '100%', },
    },
    "& .logouttxt": {
      display: 'flex', alignItems: 'center', margin: '0px 0px 20px 0px', gap: "20px",
      color: "#434343", fontSize: '16px', fontWeight: 400, fontFamily: 'DM Sans',
      cursor: 'pointer',
    },
    "& .deletetxt": {
      display: 'flex', alignItems: 'center',
      margin: '20px 0px', color: "#DC2626", fontSize: '16px', fontWeight: 400, fontFamily: 'DM Sans',
      cursor: 'pointer',
    },
    "& .errorOutline": {
      '& .MuiFormControl-root': {
        '& fieldset': {
          borderColor: 'red 1px solid',
        },
      },
    },
    "& .MuiInputBase-input": {
      padding: '18.5px 14px',
    },
    "& .tableContainer": {
      marginTop: " theme.spacing(4)",
      "& .MuiPaper-elevation1": {
        boxShadow: "none !important"
      }
    },
  
    "& .tableHeader": {
      color: "#8C8C8C",
      fontSize: "12px",
      fontWeight: "500"
    },
 
    "& .chipHigh": {
      backgroundColor: "#FEE2E2",
      color: "#DC2626",
      fontWeight: 700,
      fontSize: "10px"},
    "& .chipInProgress": {
      backgroundColor: "#FEF3C7",
      color: "#D97706",
      fontWeight: 700,
      fontSize: "10px" },
    "& .chipLow": {
      backgroundColor: "#F0F0F0",
      fontSize: "10px",
      color: "#262626",
      fontWeight: 700,},
    "& .chipLow2": {
      backgroundColor: "#F0F0F0",
      color: "#262626",
      fontWeight: 700,
      fontSize: "14px" },
    "& .chipClosed": {
      backgroundColor: "#FEE2E2",
      color: "#DC2626",
      fontWeight: 700,
      fontSize: "10px"
    },
    "& .chipNew": {
      backgroundColor: "#D1FAE5",
      color: "#059669",
      fontWeight: 700,
      fontSize: "10px"},
    "& .chipMedium": {
      color: "#FF6C29",
      backgroundColor: "#FFD7B9",
      fontWeight: 700,
      fontSize: "10px"
    },
    "& .chipPending": {
      backgroundColor: "#DCECFF",
      color: "#355EE7",
      fontWeight: 700,
      fontSize: "10px" },
    "& .chipStatus": {
      backgroundColor: ' #FAFAFA',
      width: '164px',
      height: '22px',
      borderRadius: '40px',
      fontWeight: 700,
      fontSize: "10px",
      padding: '10px 8px 8px 8px',
      display: "flex",
      justifyContent: "flex-start"
    },
    "& .iconButton": {
      width: "24px",
      height: "24px",
      color: "#355EE7"
    },
    "& .iconButton2": {
      width: "24px",
      height: "24px",
      color: "#BFBFBF"
  
    },
    "& .drawer": {
      width: "50%",
      minWidth: "812px",
    },
    "& .closeButton": {
      marginLeft: "auto",
    },
    "& .calendarIcon": {
      verticalAlign: "middle",
    },
  })
  
  
  const DrawerBox = styled('div')({
    minHeight: '100vh',
    width: "252px",
    borderRight: '1px solid #E2E8F0',
    "& .logobox": {
      display: 'flex', justifyContent: "start",
      height: "76px",
      padding: '17px 117px 17px 35px'
    },
    "& .logoimag":
    {
      height: '42px',
      width: '118px',
    },
    "& .settingbox":
    {
      display: 'flex',
      height: "65px",
    },
    "& .MainListBox": {
      padding: '0px 16px 8px 16px',
      position: 'relative'
    },
    "& .listbox": {
      height: '56px',
      borderRadius: '8px',
      "&:hover": {
        backgroundColor: "#ECF1FF",
        color: "#355EE7",
        "& .icondrawer2":
        {
          color: "#355EE7",
        },
      },
    },
    "& .listbox1": {
      height: '56px',
      backgroundColor: '#ECF1FF',
      display: 'flex',
      justifyContent: 'center',
      textAlign: 'center',
      borderRadius: '8px',
      marginBottom: '10px'
    },
    "& .icondrawer1": {
      marginBottom: '7px',
      marginLeft: '32px',
      color: '#355EE7'
    },
    "& .icondrawer2": {
      marginLeft: '32px',
      marginBottom: '2px',
    },
    "& .listtile1": {
      fontSize: "12px",
      color: '#355EE7',
      fontFamily: 'Inter',
      fontWeight: 700,
      cursor: 'pointer',
      marginRight: '38px'
    },
    "& .listtile":
    {
      fontSize: "12px",
      fontFamily: 'Inter',
      fontWeight: 700,
      cursor: 'pointer',
      marginLeft: '10px'
    },
    "& .userProfile": {
      display: "flex",
      width: '252px',
      position: 'absolute',
      bottom: '10px',
      left: '32px'
    },
    "& .imageBox": {
      width: '32px',
      height: '32px',
      borderRadius: '50%',
      border: '1px solid #F0E5FF',
      marginRight: '10px',
    },
    "& .imageBox2": {
      width: '90px',
      height: '90px',
      borderRadius: '50%',
    },
    "& .userName": {
      fontFamily: 'DM Sans',
      fontSize: '14px',
      fontWeight: 700,
      color: '#0F172A'
    },
    "& .userContent": {
      fontFamily: 'DM Sans',
      fontSize: '11px',
      fontWeight: 400,
      color: '#8C8C8C'
    },
    "@media (max-width: 1100px)":
    {
      zIndex: 999,
      width: '252px',
    },
    "@media (max-width: 991px)":
    {
      zIndex: 999,
      width: '252px',
      display: 'none',
    },
  });
  const ButtonBox = styled('div')(
    {
      display: 'none',
      "@media (max-width: 991px)":
      {
        width: '100%',
        display: 'block', minHeight: '70px',
        "& .buttonbox":
        {
          height: '70px', width: '100vw'
        },
  
        "& .iconbtn":
        {
          marginTop: '10px', marginLeft: '10px'
        }
      }
    })
    const CustomizeDialog = styled(Box)({
      "& .selectCountry": {
        width: '100%',
        border: '1px solid #C3D1FF',
        borderRadius: "12px",
        outline: 'none',
        backgroundColor: '#fff',
        fontWeight: 400,
        fontSize: '16px',
        height: "44px",
        fontFamily: "DM Sans",
        color: "black",
        paddingLeft: "8px",
        backgroundPositionY: 'center',
    
      },
    
      display: "flex",
      flexDirection: "column",
      boxShadow: "  0px 8px 32px 0px #0000000F, 0px 4px 8px 0px #00000008,0px 25px 50px 0px #00000017",
      borderRadius: "12px",
      padding: "10px",
      width: "600px",
      background: "white",
      height: "auto",
      position: 'fixed',
      top: '50%',        
      left: '50%',      
      transform: 'translate(-50%, -50%)',  
      zIndex: 9999,      
      
      "& .MuiPaper-rounded ": {
        bordeRadius: "17px",
      },
      "& .mainDiv": {
        background: "#FFF",
        width: "100%",
        justifyContent: "center",
        flexDirection: "column",
        display: "flex",
        borderRadius: "16px",
        padding: "32px 24px 32px 24px",
        gap: "20px",
        fontFamily: "Dm sans",
    
      },
      "& .selectlegal": {
        borderRadius: '12px', fontSize: '16px',
        fontWeight: 400,
        width: '100% !important',
        fontFamily: "DM Sans",
        height: "44px",
        "& .css-13cymwt-control": {
          borderColor: "#C3D1FF !important",
          border: "1px solid #C3D1FF",
           fontweight: 400,
          outline: "none",
          position: "relative",
          bottom: "3px",
          borderRadius: "12px",
          fonSize: "16px",
          height: "46px"},
        "& .css-t3ipsp-control": {
          border: "1px solid",
          borderRadius: "12px",
          outline: "none",
          position: "relative",
          bottom: "3px",
          height: "44px",
          boxShadow: "0px 0px 0px 0px",
          fonSize: "16px",
          fontweight: 400,},
        "& .css-1u9des2-indicatorSeparator": {
          backgroundColor: "white"},
        "&:focus": {
          border: '1px solid #C3D1FF;', 
          borderRadius: "12px"},
        "@media (max-width:500px)": {
          width: '100% !important',
          borderRadius: '12px', fontSize: '8px',
          fontWeight: 200,
          fontFamily: "DM Sans",
          height: "44px",},
      },
      "& .selectBox": {
        border: "1px solid #C3D1FF",
        color: "black", height: "44px",
        display: "flex",
        justifyContent: "center",
        position: "relative",
        width: "97%",
        borderRadius: "12px",paddingLeft: "6px",
        paddingRight: "6px",
       
      },
      "& .forGap": {
        padding: "4px",
      },
      "& . moneySlider": {
        color: "red",
      },
      "& .fontsizing": {
        fontSize: "14px",
        fontWeight: 400,
        color: "#212121",
      },
      "& .selectedUser": {
      
      },
      "& .selectedUser2": {
        border: '1px solid #C3D1FF',
        borderRadius: '10px',
        display: 'flex',
        gap: '10px',
        maxHeight: '200px',
        overflowY: 'auto',
        backgroundColor: " #FAFAFA",
        scrollbarWidth: 'thin',
        scrollbarColor: '#355EE7 #F0F0F0',
        fontFamily: "Dm sans",
        width: "100%"
      }
    });
  // Customizable Area End
// Customizable Area End
