import React from "react";
// Customizable Area Start
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from '@material-ui/icons/Menu';
import { closeIcon, confirmdeleteIcon, deleteIcon, editIcon, logo ,tooltipIcon} from "./assets";
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import GavelRoundedIcon from '@material-ui/icons/GavelRounded';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import {createTheme ,MuiThemeProvider,styled} from '@material-ui/core/styles';
import {
  Box, Typography, Button,  Dialog, DialogTitle, DialogContent,MenuItem, Grid,Avatar,Menu,
  CircularProgress,Tooltip,Chip
} from "@material-ui/core";

import CloseIcon from '@material-ui/icons/Close';
import PeopleAltOutlinedIcon from '@material-ui/icons/PeopleAltOutlined';
import BusinessCenterOutlinedIcon from '@material-ui/icons/BusinessCenterOutlined';
import FilterListIcon from '@material-ui/icons/FilterList';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ErrorSuccessPopUp from "../../../components/src/ErrorSuccessPopUp.web";
import { LegalServiceType } from "./AdminLawyerController.web";


const theme = createTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: "2em",
        backgroundColor: "#fff",
        zIndex:999,
        boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
        width:"450px",
        borderRadius:"12px",
        padding:"10px"
      }
    }
  }
});

// Customizable Area End
import ServicesManagementController, { Props } from "./ServicesManagementController";


export default class ServicesManagement extends ServicesManagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  drawerServive = () => (
    <DrawerBox style={{ display: this.state.mobileOpen ? 'block' : '' }}>
      <div className="toolbar" />
      <Divider />
      <div className="logobox">
        <img src={logo} alt="logo" className="logoimag" />
      </div>
      {this.state.mobileOpen && <CloseIcon style={{ position: 'fixed', top: '30px', left: '260px', cursor: 'pointer' }} onClick={this.handleclosesidebar} />}
      <div className="settingbox" onClick={this.handleDashboard}>
        <ChevronLeftIcon className="lefticon" />
        <Typography className="settingtext">Back</Typography>
      </div>
      <List style={{ padding: '8px 13px' }}>
        <ListItem className="listbox" onClick={this.handleAdminLawyer}>
          <ListItemIcon><PermIdentityIcon className="icondrawer2" /></ListItemIcon>
          <ListItemText className="listtile">
            Lawyer Management
            <Typography className="listtext" style={{ whiteSpace: 'none !important' }}>
              Settings related to your personal information and account credentials
            </Typography>
          </ListItemText>
        </ListItem>
        <ListItem className="listbox" onClick={this.handleLawFirmManagement}>
          <ListItemIcon><PeopleAltOutlinedIcon className="icondrawer3" /></ListItemIcon>
          <ListItemText className="listtile">
            Lawfirm Management
            <Typography className="listtext">
              View Digip’s Terms and Conditions and Privacy Policy
            </Typography>
          </ListItemText>
        </ListItem>
        <ListItem className="listbox1" data-test-id="ServiceManagement">
          <ListItemIcon><BusinessCenterOutlinedIcon className="icondrawer1" style={{marginBottom:"25px"}} /></ListItemIcon>
          <ListItemText className="listtile1">
            Services Management
            <Typography className="listtext">
              Manage all services here
            </Typography>
          </ListItemText>
        </ListItem>
      </List>
    </DrawerBox>
  )
  
  buttonMenu = () => (
    <ButtonBox className="buttonbox">
      <IconButton
        className="iconbtn"
        color="inherit"
        aria-label="open drawer"
        edge="start"
        onClick={this.handleDrawerToggle}
        style={{ marginRight: '24px', color: '#000' }}
      >
        <MenuIcon />

      </IconButton>
    </ButtonBox>
  )

  emptyServices = () => (
    <>
    <Box className="rightbar" data-test-id="rightbar">
    <div className="menubar">
        <h2 style={{textAlign:'center',fontFamily:"DM Sans",fontSize:"22px",fontWeight:400,lineHeight:"28.64px",color:"#000000", display: this.state.mobileOpen ? "none" : ""}}>
        Services
        </h2>
        <div style={{display:'flex',flexDirection:'row'}}>
            <FilterListIcon style={{paddingRight:"20px",paddingTop:'5px', display: this.state.mobileOpen ? "none" : ""}}/>
        <Button type="submit" data-test-id="saveBtn" variant="outlined" className="titlbtn1" style={{ display: this.state.mobileOpen ? "none" : ""}} onClick={this.handleAddservices}>
        Add New Service
        </Button>
        </div>
    </div>
        <h2 style={{textAlign:'center',fontFamily:"DM Sans",fontSize:"40px",fontWeight:400,lineHeight:"52.08px",color:"#8C8C8C",marginTop:"30%", display: this.state.mobileOpen ? "none" : ""}}>No Current Services</h2>
    </Box>

    </>  
  )

  listServices = () => {
    const { catalougeData } = this.state;
  
    return (
      <>
        <Box className="rightbar" data-test-id="rightbar"  style={{maxHeight:'calc(100vh - 90px)', display: 'flex', flexDirection: 'column'}}>
        <div className="menubar" style={{flexShrink: 0}}>
            <h2
              style={{
                textAlign: 'center',
                fontFamily: "DM Sans",
                fontSize: "22px",
                fontWeight: 400,
                lineHeight: "28.64px",
                color: "#000000",
                display: this.state.mobileOpen ? "none" : ""
              }}
            >
              Services
            </h2>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Button
                type="submit"
                data-test-id="saveBtn"
                variant="outlined"
                className="titlbtn1"
                onClick={this.handleAddservices}
                style={{ display: this.state.mobileOpen ? "none" : "" }}
              >
                Add New Service
              </Button>
            </div>
          </div>
          <Box className="scrollable-box" style={{ flexGrow: 1, overflowY: 'auto', paddingRight: "0px", width: "calc(100% + 170px)" }}>
          <Grid container spacing={8} style={{ display: this.state.mobileOpen ? "none" : "",width: "calc(100% - 36px)" }}>
            <Grid container item xs={12} spacing={8}>
              {catalougeData.map((service:any) => (
                <Grid item xs={12} sm={6} key={service.id}>
                  <Box className="card" style={cardStyle as React.CSSProperties}>
                    <Box style={cardHeaderStyle}>
                      <Avatar className="card-icon" style={{ backgroundColor: '#4872FF' }}>
                        <GavelRoundedIcon />
                      </Avatar>
                      <Box style={cardTitleStyle}>
                      <Typography variant="h6" style={{fontFamily:'DM Sans',fontWeight:400,fontSize:"16px",lineHeight:"20.83px", color:'#595959'}}>{service.attributes.service_name.length > 20 
  ? `${service.attributes.service_name.substring(0, 20)}...`
  : service.attributes.service_name}
</Typography>
<span style={cardAlignmentStyle} data-test-id="service-card">
  {service.attributes.legel_service_type?.slice(0, 2)?.map((service: string, index: number, array: string[]) => (
    <Typography key={index} variant="subtitle2" style={tooltipStyle.serviceTextStyles}  data-test-id={`typography-${index}`}  >
      {service}
      {index < array.length - 1 && ", "}
    </Typography>
  ))}
  {service.attributes.legel_service_type.length > 2 && (
    <span style={cardAlignmentStyle} data-test-id="tooltip-span">
      <MuiThemeProvider theme={theme}>
        <Tooltip
          title={
            <div style={tooltipStyle.tooltipContianer}>
              <div style={tooltipStyle.tooltipSubContainer}>
                <div style={tooltipStyle.tooltipGridContainer}>
                  {service.attributes.legel_service_type.slice(2).map((extraService:LegalServiceType, index:number) => (
                    <Chip key={index} label={extraService} data-test-id={`chip-${index}`} className="popup-chip" style={tooltipStyle.chipStyles} />
                  ))}
                </div>
                <div className="popup-image" style={tooltipStyle.tooltipimgcontainer}>
                  <img src={tooltipIcon} style={tooltipStyle.imgStyles} alt="" />
                </div>
              </div>
            </div>
          }
        >
          <Typography className="FamilyTextCss" style={tooltipStyle.lenghtStyle} data-test-id="tooltip-count">
            +{service.attributes.legel_service_type.length - 2}
          </Typography>
        </Tooltip>
      </MuiThemeProvider>
    </span>
  )}
</span>

       </Box>
                      <IconButton data-test-id={`service-${service.id}`} onClick={(e)=>this.handleClick2(e,service.attributes.service_name,service.id)}>
                        <MoreVertIcon />
                      </IconButton>
                    </Box>
  
                    <Typography
  variant="body2"
  style={{
    ...cardDescriptionStyle,
    display: '-webkit-box',
    WebkitLineClamp: 2, 
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '271px', 
    whiteSpace: 'none !important', 
  }}
>
  {service.attributes.additional_information}
</Typography>
  
                    <Box style={cardInfoStyle}>
                        <div style={{display:"flex",flexDirection:"column"}}>
                      <Typography variant="caption" style={{fontFamily:'DM Sans',fontWeight:400,fontSize:"11px",lineHeight:"14.32px", color:'#595959'}}>Fixed Price</Typography>
                      <Typography variant="caption" style={{fontFamily:'DM Sans',fontWeight:400,fontSize:"10px",lineHeight:"13.02px", color:'#64748B'}}>Price Range</Typography>
                      <Typography variant="body1" style={{fontFamily:'DM Sans',fontWeight:400,fontSize:"14px",lineHeight:"18.23px", color:'#64748B'}}>
                        From €<span style={{color:"#000000"}}> {service.attributes.start_price}</span> to € <span style={{color:"#000000"}}>{service.attributes.end_price}</span>
                      </Typography>
                      </div>
                      <div style={{display:"flex",flexDirection:"column"}}>
                      <Typography variant="caption" style={{fontFamily:'DM Sans',fontWeight:400,fontSize:"11px",lineHeight:"14.32px", color:'#595959'}}>Estimated Time</Typography>
                      <div style={{display:"flex",flexDirection:"row", justifyContent:"space-between",color:'#64748B',paddingTop:'10px'}}>
                      <AccessTimeIcon style={{height:'16px',width:'16px'}}/>
                      <Typography variant="body1" style={{fontFamily:'DM Sans',fontWeight:400,fontSize:"14px",lineHeight:"18.23px", color:'#64748B',paddingLeft:'5px'}}>{service.attributes.expected_time_estimate}{service.attributes.expected_time_estimate === "10" ? "+" : ""} {service.attributes.expected_time_estimate === "1" ? 'Week' : 'Weeks'}</Typography>
                      </div>
                      </div>
                    </Box>
                    <Typography variant="caption" style={{fontFamily:'DM Sans',fontWeight:400,fontSize:"11px",lineHeight:"14.32px", color:'#434343'}}>Associated Lawyers</Typography>
                    <Box style={cardLawyersStyle as React.CSSProperties}>
  {service?.attributes?.associated_lawyer_details?.slice(0, 3).map((lawyer:any, index:any) => (
    <Avatar
      key={lawyer.account_id}
      className="card-icon"
      src={lawyer.image} 
      style={{
        marginLeft: index > 0 ? '-18px' : '0',
        height: '32px',
        width: '32px',
      }}
    />
  ))}
  {service.attributes.associated_lawyer_details.length > 3 && (
    <Avatar
      style={{
        marginLeft: '-18px',
        height: '32px',
        width: '32px',
        backgroundColor: '#D9D1EF',
        fontSize: '12px',
        fontFamily: 'DM Sans !important',
        fontWeight: 700,
        color: '#684EF3',
      }}
    >
      +{service.attributes.associated_lawyer_details.length - 3}
    </Avatar>
  )}
</Box>



                  </Box>
                  <Menu
            anchorEl={this.state.anchorEl}
            open={Boolean(this.state.anchorEl)}
            onClose={this.handleClose1}
            PaperProps={{
              elevation: 0,
              style: {
                boxShadow: 'none',
                border: "1px solid #E2E8F0",
              },
            }}
          >
            <StyledMenuItem  onClick={this.handleEditServices}>
              <ListItemIcon>
                <img
                  src={editIcon}
                  alt="Edit"
                  style={{ height: "16px", width: "18px" } as React.CSSProperties}
                />
              </ListItemIcon>
              <ListItemText
                primary="Edit"
                style={{
                  fontFamily: 'DM Sans !important',
                  fontWeight: 400,
                  fontSize: "14px",
                } as React.CSSProperties}
              />
            </StyledMenuItem>
            <StyledMenuItem onClick={this.handleconfirmdeleteopen}>
              <ListItemIcon>
                <img
                  src={deleteIcon}
                  alt="Remove"
                  style={{ height: "20px", width: "18px" } as React.CSSProperties}
                />
              </ListItemIcon>
              <ListItemText
                primary="Remove"
                style={{
                  fontFamily: 'DM Sans !important',
                  fontWeight: 400,
                  fontSize: "14px",
                } as React.CSSProperties}
              />
            </StyledMenuItem>
          </Menu>
                </Grid>
              ))}
            </Grid>
          </Grid>
          </Box>
         
        </Box>
      </>
    );
  };
  


  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <MainDiv data-test-id="catalougeMain"
        style={{maxHeight: this.state.mobileOpen ? '100vh' : '',overflow: this.state.mobileOpen ? "hidden" : ""}}>
        
        <Box className="sidebar">
          {this.drawerServive()}{this.buttonMenu()}
          {this.state.mobileOpen &&
            <>
              <div className="offcanvascss" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel"> {this.drawerServive()}  </div>
            </>
          }
        </Box>
        {this.state.mobileOpen && <div className="offcanvasbackdrop" onClick={this.handleclosesidebar}></div>}
        {this.state.isLoading ? (
  <span  className="rightbar" style={{display:'flex', justifyContent:'center', alignItems:'center',marginLeft: '555px'}}>
    <CircularProgress />
  </span>
) : (
  this.state?.catalougeData?.length > 0 ? (
    this.listServices()
  ) : (
    this.emptyServices()
  )
)}

        
        
        <Dialog onClose={this.handleconfirmdelete}  open={this.state.confirmdelete}
        aria-labelledby="responsive-dialog-title"
        PaperProps={{
          style: {
            borderRadius: '16px'
          }
        }}>
        <DialogTitle id="responsive-dialog-title">
  <Box display="flex" justifyContent="space-between" alignItems="center">
    <Box display="flex" alignItems="center" style={{fontFamily:'DM Sans',fontWeight:400,fontSize:"22px",lineHeight:"28.64px"}}>
      <img
        src={confirmdeleteIcon}
        alt="Delete Icon"
        style={{ height: "27px", width: "21px", paddingRight: "20px" }}
      />
      Remove Service
    </Box>
    <img
      src={closeIcon}
      alt="Close Icon"
      style={{ height: "16px", width: "16px", cursor: "pointer" }}
      onClick={this.handleconfirmdelete}
    />
  </Box>
</DialogTitle>
        <DialogContent dividers>
          <Typography style={{ textAlign: 'left',fontFamily:'DM Sans',fontWeight:400,fontSize:"19px",lineHeight:"24.74px", color:'#595959',paddingTop:"20px",paddingBottom:'20px' }}>
                Are you sure you want to remove <span style={{fontFamily:'DM Sans',fontWeight:400,fontSize:"19px",lineHeight:"24.74px", color:'#2348C5'}}>{this.state.serviceName.length > 10 
  ? `${this.state.serviceName.slice(0, 10)}...` 
  : this.state.serviceName} Service</span> from legal services list?
          </Typography>
        </DialogContent>
        <div style={{display:"flex",flexDirection:"row",padding:"20px",justifyContent:'space-between'}}>
        <Button type="submit" data-test-id="editBtn" variant="outlined"style={{
                color: '#595959',
                borderColor:"#595959",
                borderRadius: "8px ",
                fontSize: '16px ',
                fontWeight: 400,
                paddingRight:'20px',
                fontFamily: 'DM Sans ',
                width: '45%',
                textTransform:"none"
              }}onClick={this.handleconfirmdelete} > 

        No , Keep it
</Button> 
        <ButtonBox2
            type="submit"
            data-test-id="saveBtn"
            className="titlbtn2"
            style={{
              backgroundColor: "#355EE7",
              color: '#ffffff',
              borderRadius: "8px",
              fontSize: '16px',
              fontWeight: 400,
              fontFamily: 'DM Sans',
              marginLeft: '15px',
              boxShadow: 'none !important',
              border: '2px solid #355EE7 ',
              textDecoration: 'none',
              padding:'10px 2px',
              cursor:'pointer', 
                width: '50%',
                textTransform:"none",
              }}
            onClick={this.handleconfirmdeleteOk} 
          >
            Yes , Remove it
          </ButtonBox2>
          </div>
      </Dialog>


        {this.state.popUpShoww && <ErrorSuccessPopUp
                  showPop={this.state.popUpShoww}
                  setShowPop={(newState: boolean) => this.setState({ popUpShoww: newState })}
                  showResponse={this.state.ShowResponsee}
                  termfunction={  () => {}}
                  deletePop={this.state.LawfirmNav}
                  loginNav={this.handleLawFirmManagement}
                />}
      </MainDiv>

      // Customizable Area End
    );
  }
}

// Customizable Area Start
const StyledMenuItem = styled(MenuItem)({
    "&:hover": {
      backgroundColor: "#ECF1FF",
    },
  });
  const ButtonBox2 = styled('button')({
    '&:hover': {
      border:'2px solid #B9C5FF !important',
      boxShadow:'none !important'
    },
  })
const cardStyle = {
    display: 'flex',
    flexDirection: 'column',
    padding: '16px',
    backgroundColor: '#fff',
    borderRadius: '12px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    position: 'relative',
    borderLeft:'6px solid #9AB1FF',
    borderRight:'6px solid #9AB1FF'
  };
  
  const cardHeaderStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '16px',
  };
  
  const cardTitleStyle = {
    flexGrow: 1,
    marginLeft: '8px',
  };

  const cardAlignmentStyle={ display: "flex", alignItems: "center" }
  
  const cardDescriptionStyle = {
    marginBottom: '16px',ontFamily:'DM Sans',fontWeight:400,fontSize:"14px",lineHeight:"18.23px", color:'#595959'
  };
  
  const cardInfoStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '16px',
  };
  
  const cardLawyersStyle = {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '8px',
    paddingTop:'10px'
  };
const MainDiv = styled('div')({
  width: '100%',
  height: '100vh',
  display: 'flex',
  "@media (max-width:991px)": {
    display: "block",
  },
  "& .MuiSelect-selectMenu:focus": {
    backgroundColor: "#fff"
  }, "& .scrollable-box": {
    flexGrow: 1,
    overflowY: 'auto',
    paddingRight: '0px',
    width: 'calc(100% + 130px)', // Add this if you need the box to extend
  },"& .lefticon":
  {
    marginTop: '4px',
    marginLeft: '30px',
  },
  "& .scrollable-box::-webkit-scrollbar": {
    width: "8px", // Scrollbar width
  },
  "& .scrollable-box::-webkit-scrollbar-track": {
    background: "#f1f1f1", // Scrollbar track background
  },
  "& .scrollable-box::-webkit-scrollbar-thumb": {
    backgroundColor: "#91AAFF", // Scrollbar color
    borderRadius: '10px', // Round scrollbar edges
    border: '2px solid #f1f1f1', // Space between scrollbar and the track
  },
  "& .scrollable-box::-webkit-scrollbar-thumb:hover": {
    backgroundColor: "#5D85FF", // Darker scrollbar on hover
  },
  "& .sidebar": {
    width: '355px',
    height: '100vh',
    position: 'fixed',
    top: '0',
    left: '0',
    borderRight: "1px solid #C3D1FF",
    "@media (max-width: 1100px)": {
      width: "355px"
    },
    "@media (max-width: 991px)": {
      width: '100vw',
      height: '80px'
    }
  },
  "& .rightbar::-webkit-scrollbar": {
    width: "8px", 
  },
  
  "& .rightbar::-webkit-scrollbar-track ":{
    background: "#f1f1f1", 
  },
  
  "& .rightbar::-webkit-scrollbar-thumb": {
    backgroundColor: '#91AAFF', 
    borderRadius: '10px', 
    border: '2px solid #f1f1f1', 
  },
  
 "& .rightbar::-webkit-scrollbar-thumb:hover": {
    backgroundColor: "#5D85FF", 
  },
  "& .offcanvasbackdrop": {
    position: "fixed",
    top: '0px',
    left: '355px',
    width: 'calc(100% - 355px)',
    height: '100vh',
    zIndex: 999,
    backgroundColor: '#000',
    opacity: 0.5,
  },"& .menubar": {
    display:'flex',
    flexDirection:'row',
    justifyContent:'space-between',
    "@media (max-width: 1100px)": {
        display:'flex',
        flexDirection:'row',
        justifyContent:'space-between',
    },
    "@media (max-width: 451px)": {
        display:'flex',
        flexDirection:'column',
    }
  },
  "& .rightbar": {
    width: 'calc(100% - 355px)',
    padding: "0px 170px",
    marginLeft: '355px',
    marginTop: '90px',
    "@media (max-width: 1100px)": {
      padding: '0px 30px',
      width: 'calc(100% - 355px)',
      marginLeft: '355px',
    },
    "@media (max-width: 991px)": {
      width: 'auto !important',
      marginLeft: '0px',
      marginTop: '50px',
      padding: "20px 40px",
    }
  },

  "& .titlbtn": {
    height: '44px',
    width: '163px',
    border: '1px solid #595959',
    borderRadius: '8px',
    padding: '10px 12px',
    fontSize: '14px',
    fontWeight: 400,
    color: '#595959',
    fontFamily: 'DM Sans',
    "@media (max-width: 450px)": {
      height: '40px',
      width: '140px',
      padding: '7px 9px',
    },
  },
  "& .titlbtn1": {
    height: '44px', width: '163px',
    border: '1px solid #355EE7',  borderRadius: '8px',
    padding: '10px 12px',  fontSize: '14px',
    fontWeight: 400,  color: '#fff',
    backgroundColor: "#355EE7",  fontFamily: 'DM Sans',
    textTransform: "none",
    '&:hover': {
      border:'2px solid #B9C5FF',    boxShadow:'none !important'
    },
    "@media (max-width: 450px)": {
      height: '40px',    width: '140px',     padding: '7px 9px',
    },
  },
  "& .titlbtn2": {
    height: '44px', width: '163px',
    border: '1px solid #355EE7',  borderRadius: '8px',
    padding: '10px 12px',  fontSize: '14px',
    fontWeight: 400,  color: '#fff',
    backgroundColor: "#355EE7",  fontFamily: 'DM Sans',
    textTransform: "none",
    '&:hover': {
      border:'2px solid #B9C5FF',    boxShadow:'none !important'
    },
    "@media (max-width: 450px)": {
      height: '40px',    width: '140px',     padding: '7px 9px',
    },
  },
})

const DrawerBox = styled('div')({
  minHeight: '100vh',
  width: "355px",
  "& .logobox": {
    display: 'flex', justifyContent: "start",
    height: "76px", marginTop: '20px',
    marginLeft: '20px'
  },
  "& .logoimag":
  {
    height: '42px',
    width: '118px',
  },
  "& .settingtext": {
    color: '#262626', fontSize: '22px',
    marginLeft: '6px',
    fontWeight: 400, fontFamily: 'DM Sans',
  },
  "& .settingbox":
  {
    display: 'flex',
    height: "65px",
    cursor: 'pointer'
  },
  "& .listbox": {
    height: '110px',
    borderRadius: '8px',
    "&:hover": {
      backgroundColor: "#ECF1FF",
      color: "#355EE7",
      "& .icondrawer2":
      {
        backgroundColor: "#ECF1FF",
        color: "#355EE7",
      },
      "& .icondrawer3":
      {
        backgroundColor: "#ECF1FF",
        color: "#355EE7",
      },
      "& .listtile": {
        color: "#355EE7",
      }
    },
  },
  "& .listbox1": {    height: '110px',    backgroundColor: '#ECF1FF',    borderRadius: '8px',
  },
  "& .icondrawer1": {    marginBottom: '45px',    color: '#355EE7',    marginLeft: '18px',
  },
  "& .icondrawer3": {    marginLeft: '18px',    marginBottom: '25px',    color: '#434343',
  },
  "& .icondrawer2": {    marginLeft: '18px',    marginBottom: '42px',    color: '#434343',
  },

  "& .listtext": {    fontSize: '14px',    color: '#334155',    fontFamily: 'DM Sans',    fontWeight: 400,
  },
  "& .listtile1": {    fontSize: "16px",    color: '#355EE7',    fontFamily: 'DM Sans',    fontWeight: 400,    cursor: 'pointer'
  },
  "& .listtile":
  {    fontSize: "16px",
    fontFamily: 'DM Sans',  fontWeight: 400,   cursor: 'pointer',   color: '#434343',
  },
  "@media (max-width: 1100px)":
  {
    zIndex: 999,   width: '355px',
  },
  "@media (max-width: 991px)":
  {
    zIndex: 999,    width: '355px',    display: 'none',
  },
});

const ButtonBox = styled('div')(
  {  display: 'none',
    "@media (max-width: 991px)":
    {    width: '100%',    display: 'block', minHeight: '70px',
      "& .buttonbox":
      {      height: '70px', width: '100vw'
      },

      "& .iconbtn":
      {      marginTop: '10px', marginLeft: '10px'
      }
    }
  })

  const tooltipStyle={
    tooltipContianer:{
       width:"100%"
     },
      
     tooltipSubContainer:{
     display:"flex",gap:"20px",width:"100%"
     },
     tooltipGridContainer:{display: "grid",gridTemplateColumns:" repeat(2, 1fr)",gap:"10px",width:"80%",marginTop:"40px"},
     chipStyles:{ backgroundColor: '#ECF1FF ',
       color: '#355EE7 ',fontSize:"14px",textTransform:"capitalize" as "capitalize",wordWrap: "break-word" as "break-word"},
       tooltipimgcontainer:{ justifyContent: "flex-end", display: "flex"},
     imgStyles:{
       width: "40px",
       height: "40px",
       marginBottom:"1px",
     },
     lenghtStyle:{
      fontFamily: "DM Sans",
      fontWeight: 400,
      fontSize: "11px",
      lineHeight: "14.32px",
      color: "#64748B",
      padding: "3px 5px",
    },
    serviceTextStyles:{
      fontFamily: "DM Sans",
      fontWeight: 400,
      fontSize: "11px",
      lineHeight: "14.32px",
      color: "#64748B",
    }
   
   
   }
   

// Customizable Area End
