import React from "react";
// Customizable Area Start
import { styled } from "@material-ui/core/styles";
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from '@material-ui/icons/Menu';
import { logo, userpic } from "./assets";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import EditIcon from '@material-ui/icons/Edit';
import {
  Box, Typography, Button, TextField,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Dialog, 
} from "@material-ui/core";
import { Field, Formik } from "formik";
import * as Yup from "yup";
import CloseIcon from '@material-ui/icons/Close';
import StarIcon from '@material-ui/icons/Star';
import HourglassEmptyOutlinedIcon from '@material-ui/icons/HourglassEmptyOutlined';
import CameraAltOutlinedIcon from '@material-ui/icons/CameraAltOutlined';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import PhoneInput from "react-phone-input-2";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import PeopleAltOutlinedIcon from '@material-ui/icons/PeopleAltOutlined';
import BusinessCenterOutlinedIcon from '@material-ui/icons/BusinessCenterOutlined';


import EditCategoriesController, {
  CountryDatatype,
    ExperienceDataType,
    Props,
    DataLegal
} from "./EditCategoriesController.web";
import Select, { MultiValue } from "react-select";
// Customizable Area End


export default class Editlawyer extends EditCategoriesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
    drawer = () => (
        <DrawerBox style={{ display: this.state.mobileOpen ? 'block' : '' }}>
            <div className="toolbar" />
            <Divider />
            <div className="logobox" >
                <img src={logo} alt="logo" className="logoimag" />
            </div>
            {this.state.mobileOpen && <CloseIcon style={{ position: 'fixed', top: '30px', left: '260px', cursor: 'pointer' }} onClick={this.handleclosesidebar} />}
            <div className="settingbox" onClick={this.handleDashboard} data-test-id="backbtn">
                <ChevronLeftIcon className="lefticon" />
                <Typography className="settingtext">Back</Typography>
            </div>
            <List style={{ padding: '8px 13px' }}>
                <ListItem className="listbox1">
                    <ListItemIcon><PermIdentityIcon className="icondrawer1" /></ListItemIcon>
                    <ListItemText className="listtile1">
                        Lawyer Management
                        <Typography className="listtext" style={{ whiteSpace: 'none !important' }}>
                            Settings related to your personal information and account credentials
                        </Typography>
                    </ListItemText>
                </ListItem>
                <ListItem className="listbox" onClick={this.handleLawfirmManagement}>
                    <ListItemIcon><PeopleAltOutlinedIcon className="icondrawer2" /></ListItemIcon>
                    <ListItemText className="listtile">
                        Lawfirm Management
                        <Typography className="listtext">
                            View Digip’s Terms and Conditions and Privacy Policy
                        </Typography>
                    </ListItemText>
                </ListItem>
                <ListItem className="listbox" onClick={this.handleServicesManagement}>
                    <ListItemIcon><BusinessCenterOutlinedIcon className="icondrawer3" /></ListItemIcon>
                    <ListItemText className="listtile">
                        Services Management
                        <Typography className="listtext">
                            Manage all services here
                        </Typography>
                    </ListItemText>
                </ListItem>
            </List>
        </DrawerBox>
    )
    topbox = () => (
        <Box className="topbox">
            <Typography className="titleuser" data-test-id="Lawfirm_Management">
                Lawyer Management
            </Typography>
            {!this.state.editToggel ?
                <Button type="submit" data-test-id="editBtn" variant="outlined" className="titlbtn" style={{ display: this.state.mobileOpen ? "none" : "" }}>

                    <EditIcon style={{ marginRight: "8px" }} /> Edit
                </Button> :
                <Button type="submit" data-test-id="saveBtn" variant="outlined" className="titlbtn1" style={{ display: this.state.mobileOpen ? "none" : "" }}>
                    Save Changes
                </Button>}
        </Box>
    )

    profilebox = () => (
        <Box className="profilebox">
            <Typography className="profiletext">
                Profile picture
            </Typography>
            {this.state.editToggel ?
                <Box className="profileImg">
                    <Box className="positionrel" style={{ display: this.state.mobileOpen ? "none" : "" }}>
                        <label htmlFor="image" className="imagenot" style={{ backgroundImage: this.state.changeimage ? `url(${this.state.changeimage})` : `url(${userpic})` }}>
                            <>
                                <CameraAltOutlinedIcon className="usericon" />
                                <Typography className="edittxt">Edit picture</Typography>
                            </>
                        </label>
                        <input
                            type="file"
                            id="image"
                            name="image"
                            style={{ display: 'none' }}
                            onChange={this.handleImageChange}
                        />
                    </Box>
                </Box>
                :
                <Box className="profileImg">
                    <img src={this.state.changeimage || userpic} alt="" className="userimg" />
                </Box>
            }
        </Box>
    )
    buttonMenu = () => (
        <ButtonBox className="buttonbox">
            <IconButton
                className="iconbtn"
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={this.handleDrawerToggle}
                style={{ marginRight: '24px', color: '#000' }}
            >
                <MenuIcon />

            </IconButton>
        </ButtonBox>
    )
    handleGetCountryData = (item: CountryDatatype, index: number) => {
        return (
            <option value={item?.attributes?.name}>{item?.attributes?.name}</option>
        )
    }
    handleGetExperienceData = (item: ExperienceDataType, index: number) => {
        return (
            <option value={item?.name}>{item?.name}</option>
        )
    }
    handlePersonalDetails = (values: any, handleChange: any,setFieldValue:any) => {
        return (
            <>
                <Box className="personalform">
                    <Box className="fullName">
                        <Box className="firstname">
                            <Typography variant="subtitle1" data-test-id="firstname" className="firstNamePlaceHolder">
                                First Name
                            </Typography>
                            <Field
                                as={TextField}
                                data-test-id="firstname1"
                                InputProps={{
                                    style: {
                                        borderRadius: '8px'
                                    }
                                }}
                                name="firstname"
                                fullWidth
                                variant="outlined"
                                placeholder="Enter First Name"
                                style={{ display: this.state.mobileOpen ? "none" : "" }}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    const { value } = event.target;
                                    if (/^[A-Za-z\s]*$/.test(value)) {
                                        handleChange(event);
                                    }
                                }}
                                value={values.firstname}
                                disabled={true}
                            />
                        </Box>
                        <Box className="lastname">
                            <Typography variant="subtitle1" className="firstNamePlaceHolder">
                                Last Name
                            </Typography>
                            <TextField
                                name="lastname"
                                data-test-id="lastname"
                                InputProps={{
                                    style: {
                                        borderRadius: '8px'
                                    }
                                }}
                                style={{ display: this.state.mobileOpen ? "none" : "" }}
                                fullWidth
                                variant="outlined"
                                placeholder="Enter Last Name"
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    const { value } = event.target;
                                    if (/^[A-Za-z\s]*$/.test(value)) {
                                        handleChange(event);
                                    }
                                }}
                                value={values.lastname}
                                disabled={true}
                            />
                        </Box>
                    </Box>

                    <Box className="country">
                        <Typography variant="subtitle1" className="firstNamePlaceHolder">
                            Jurisdiction
                        </Typography>
                        <select
                            className="selectCountry"
                            data-test-id="country"
                            name="country"
                            value={values.country}
                            disabled={true}
                            onChange={(event) => setFieldValue("country", event.target.value)}
                        >
                            {this.state.countryData?.map(this.handleGetCountryData)}
                        </select>
                    </Box>
                    <Box className="PhoneNumer">
                        <Typography variant="subtitle1" className="firstNamePlaceHolder">
                            Phone Number
                        </Typography>
                        <PhoneInputStyle className="phoneInput" style={{ display: this.state.mobileOpen ? "none" : "" }}>
                            <PhoneInput
                                country={"in"}
                                placeholder={""}
                                inputProps={{
                                    required: true,
                                    id: "contactNumber",
                                    name: "contactNumber",
                                }}
                                data-test-id="PhoneNumberModel"
                                enableSearch={true}
                                disableSearchIcon={true}
                                jumpCursorToEnd={true}
                                countryCodeEditable={false}
                                inputClass={"PhoneInputPackgeClass"}
                                value={values.contactNumber}
                                disabled={true}
                                onChange={(phone, country) => {
                                    const newPhone = this.handleCountryChange(phone, country);
                                    setFieldValue("contactNumber", newPhone);
                                }}
                            />
                        </PhoneInputStyle>
                    </Box>
                    <Box className="Email">
                        <Typography variant="subtitle1" className="firstNamePlaceHolder">
                            Email address
                        </Typography>
                        <TextField
                            data-test-id="email"
                            InputProps={{
                                style: {
                                    borderRadius: '8px'
                                }
                            }}
                            style={{ display: this.state.mobileOpen ? "none" : "" }}
                            name="email"
                            type="email"
                            fullWidth
                            variant="outlined"
                            placeholder="Your email"
                            onChange={handleChange}
                            value={values.email}
                            disabled={true}
                        />
                    </Box>
                </Box>
            </>
        )
    }
    updateManagement = () => {
      
        return (
            <Box className="rightbar" data-test-id="rightbar">
                <Formik
                    data-test-id="userboxForm"
                    validationSchema={this.validationSchemaUser()}
                    initialValues={{
                        firstname: this.state.userdata?.first_name || "",
                        contactNumber: this.state.userdata?.full_phone_number || "",
                        lastname: this.state.userdata?.last_name || "",
                        email: this.state.userdata?.email || "",
                        country: this.state.userdata?.jurisdiction || "",
                        firm: this.state.userdata?.firm || "",
                        legel_service_type_id: this.state.optionsSelected || "",
                        years_of_experience: this.state.userdata?.years_of_experience || "",
                        accomplishments: this.state.userdata?.accomplishments || "",
                        lawyer_status:this.state.userdata?.lawyer_status||""
                    }}
                    onSubmit={(values, { setStatus }) => {
                        if (this.state.editToggel) {
                            if (!values.accomplishments|| !values.lawyer_status||!values.years_of_experience ||values.legel_service_type_id.length<1
                                ) {
                                setStatus(` Please complete your profile with valid information to proceed.`);
                            }
                            else {
                                setStatus(null);
                                this.UpdateUserData(values)
                            }
                        }
                        else{
                            this.setState({ editToggel: !this.state.editToggel })
                        }
                    }}
                >
                    {({ values, status, handleSubmit, handleChange, setFieldValue }) => {
                        return (
                            <form className="thirdForm"
                                onSubmit={handleSubmit}>
                                {this.topbox()}
                                <Box mb={2}>
                                    {status && (!values.accomplishments|| !values.lawyer_status||!values.years_of_experience ||values.legel_service_type_id.length<1
                                    ) && (
                                            <Box className="statusError" borderRadius={4} textAlign="center">
                                                {<ErrorOutlineIcon style={{ marginRight: '3px' }} />}{status}
                                            </Box>
                                        )}
                                </Box>
                                <Box className="userdetailbox">
                                    <Box>
                                        {this.profilebox()}
                                        <Box className="detailsbox">
                                            <Box className="personaldetailtext">
                                                <Typography data-test-id="personal">Personal details</Typography>
                                            </Box>
                                            {this.handlePersonalDetails(values, handleChange,setFieldValue)}
                                        </Box>
                                        <Box className="detailsbox" style={{ paddingBottom: '18px' }}>
                                            <Box className="personaldetailtext">
                                                <Typography>Work Details</Typography>
                                            </Box>
                                            <Box className="accountsactions1">
                                                <Box className="Email">
                                                    <Typography variant="subtitle1" className="firstNamePlaceHolder">
                                                        Company/Law firms
                                                    </Typography>
                                                    <TextField
                                                        data-test-id="firm"
                                                        InputProps={{
                                                            style: {
                                                                borderRadius: '8px'
                                                            }
                                                        }}
                                                        style={{ display: this.state.mobileOpen ? "none" : "" }}
                                                        name="firm"
                                                        type="text"
                                                        fullWidth
                                                        variant="outlined"
                                                        placeholder="National Law Court"
                                                        onChange={handleChange}
                                                        value={values.firm}
                                                        disabled={true}
                                                    />
                                                </Box>
                                                <Box className="country">
                                                    <Typography variant="subtitle1" className="firstNamePlaceHolder">
                                                        Legal Service Type<span style={{color:'red'}}>*</span>
                                                    </Typography>
                                                    <Select
                                                        isMulti
                                                        name="legel_service_type_id"
                                                        data-test-id="legal_services"
                                                        isDisabled={!this.state.editToggel}
                                                        options={this.state.optionsShow?.map(item => ({
                                                            value: item.value,
                                                            label: item.label,
                                                            id: item.id
                                                        }))}
                                                    className="selectlegal"
                                                       placeholder="Choose legal service type"
                                                        onChange={(selectedOptions: MultiValue<DataLegal>) => {
                                                            setFieldValue("legel_service_type_id", selectedOptions|| []);
                                                        }}
                                                        value={values.legel_service_type_id}
                                                       
                                                    />
                                                </Box>
                                                <Box className="country">
                                                    <Typography variant="subtitle1" className="firstNamePlaceHolder">
                                                        Years of experience<span style={{color:'red'}}>*</span>
                                                    </Typography>
                                                    <select
                                                        className="selectCountry"
                                                        data-test-id="experience"
                                                        name="years_of_experience"
                                                        disabled={!this.state.editToggel}
                                                        value={values.years_of_experience}
                                                        style={{color:values.years_of_experience ==""||undefined?"#BFBFBF":"#000"}}
                                                        onChange={(event) => setFieldValue("years_of_experience", event.target.value)}
                                                    >
                                                        <option value="" >Select Experience</option>
                                                       {this.state.experienceData?.map(this.handleGetExperienceData)}
                                                    </select>
                                                </Box>
                                                <Box className="Email">
                                                    <Typography variant="subtitle1" className="firstNamePlaceHolder">
                                                        Accomplishments<span style={{color:'red'}}>*</span>
                                                    </Typography>
                                                    <TextField
                                                        data-test-id="accomplishments"
                                                        InputProps={{
                                                            style: {
                                                                borderRadius: '8px'
                                                            }
                                                        }}
                                                        style={{ display: this.state.mobileOpen ? "none" : "" }}
                                                        name="accomplishments"
                                                        type="text"
                                                        fullWidth
                                                        variant="outlined"
                                                      
                                                        multiline
                                                        minRows={4}
                                                        onChange={handleChange}
                                                        disabled={!this.state.editToggel}
                                                        value={values.accomplishments}
                                                    />
                                                </Box>
                                                <Box className="country">
                                                    <Typography variant="subtitle1" className="firstNamePlaceHolder">
                                                        Status<span style={{color:'red'}}>*</span>
                                                    </Typography>
                                                    <select
                                                        className="selectCountry"
                                                        data-test-id="status"
                                                        name="lawyer_status"
                                                        disabled={!this.state.editToggel}
                                                        value={values.lawyer_status}
                                                        style={{color:values.lawyer_status ==""||undefined?"#BFBFBF":"#000"}}
                                                        onChange={(event) => setFieldValue("lawyer_status", event.target.value)}
                                                    >
                                                        <option value="" >Select Status</option>
                                                        <option value="active" >Active</option>
                                                        <option value="inactive" >Inactive</option>
                                                    </select>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </form>
                        )
                    }}
                </Formik>
                <Box className="accountsbox">
                    <Box className="accountdetailtext">
                        <Typography>Ratings</Typography>
                    </Box>
                    <Box className="accountsactions">
                        <Box className="logouttxt" data-test-id="logoutdBtn" >
                            <Typography style={{ color: '#434343', fontWeight: 700 }}>Rate</Typography>
                            <StarIcon style={{ color: '#8C8C8C', fontSize: '18px' }} />
                        </Box>
                        <Box className="deletetxt" >
                            <Typography style={{ color: '#434343', fontWeight: 700 }}>Average Response Rate</Typography>
                            <HourglassEmptyOutlinedIcon style={{ color: '#8C8C8C', fontSize: "18px" }} />
                        </Box>
                    </Box>
                </Box>

            </Box>
        )
    }


    validationSchemaUser = () => {
        return Yup.object().shape({
            firstname: Yup.string().matches(/^[A-Za-z\s]*$/, 'Invalid characters in First Name'),
            lastname: Yup.string().matches(/^[A-Za-z\s]*$/, 'Invalid characters in Last Name'),
            email: Yup.string().email('Invalid email format').required('Email is required'),
            country: Yup.string().required('Country is required'),
            contactNumber: Yup.string().required('Phone number is required'),
        });
    };
    ;
  // Customizable Area End

  render() {
    // Customizable Area Start
      return (
          <MainDiv data-test-id="EditCategories1"
              style={{
                  maxHeight: this.state.mobileOpen ? '100vh' : '',
                  overflow: this.state.mobileOpen ? "hidden" : ""
              }}>
              {!this.state.loadData &&
                  <>
                      <Box className="sidebar" >
                          {this.drawer()}
                          {this.buttonMenu()}
                          {this.state.mobileOpen &&
                              <>
                                  <div className="offcanvascss" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
                                      {this.drawer()}
                                  </div>
                              </>
                          }
                      </Box>
                      {this.state.mobileOpen && <div className="offcanvasbackdrop" onClick={this.handleclosesidebar}></div>}

                      {
                          this.state.servicePresnet &&
                          this.updateManagement()}

                      <Dialog
                          open={this.state.openDialog}
                          onClose={this.handleClosePopUp}
                          aria-labelledby="responsive-dialog-title"
                          PaperProps={{
                              style: {
                                  borderRadius: '16px',
                                  minWidth: '430px',
                                  textAlign: 'center'
                              }
                          }}
                      >
                          <DialogTitle id="responsive-dialog-title" style={{ textAlign: 'right' }}>
                              <CloseIcon onClick={this.handleClosePopUp} />
                          </DialogTitle>
                          <DialogContent>
                              <DialogContentText>
                                  <Typography style={{ textAlign: 'center' }}>
                                      {this.state.showResponse.success ? <CheckCircleIcon style={{
                                          color: '#34D399',
                                          fontSize: '40px',
                                          fontWeight: 500,
                                          marginBottom: '20px'
                                      }} /> :
                                          <ErrorOutlineIcon style={{
                                              color: '#DC2626',
                                              fontSize: '40px',
                                              fontWeight: 500,
                                              marginBottom: '20px'
                                          }} />}
                                  </Typography>
                                  <Typography
                                      style={{
                                          color: '#262626',
                                          fontSize: '16px',
                                          fontWeight: 400,
                                          fontFamily: 'DM Sans',
                                          marginBottom: '10px'
                                      }}
                                  >
                                      {this.state.showResponse?.error || this.state.showResponse?.success}
                                  </Typography>
                              </DialogContentText>
                          </DialogContent>
                          <DialogActions style={{ padding: '20px', borderTop: '1px solid #E2E8F0' }}>
                              <Button
                                  variant="contained"
                                  onClick={this.handleClosePopUp}
                                  autoFocus
                                  style={{
                                      backgroundColor: "#355EE7",
                                      color: '#ffffff',
                                      borderRadius: "8px",
                                      fontSize: '16px',
                                      fontWeight: 400,
                                      fontFamily: 'DM Sans',
                                      width: '100%',
                                      textTransform: "none"
                                  }}
                              >
                                  Ok
                              </Button>
                          </DialogActions>
                      </Dialog>
                  </>
              }
          </MainDiv>
      );
    // Customizable Area End
  }
}

// Customizable Area Start

const PhoneInputStyle = styled(Box)({
    "& .country-list": {
        top: "0px !important",
        height: "155px !important"
    },
    "& .react-tel-input .country-list .country-name": {
        fontFamily: "DM Sans"
    },
    "& .react-tel-input": {
        fontFamily: "DM Sans"
    },
    "& .react-tel-input .country": {
        marginBottom: "0px"
    },
    "& .PhoneInputPackgeClass": {
        width: "97.3%",
        height: "56px",
        borderRadius: "5px",
        fontSize: "16px",
        boder: "none !important",
        backgroundColor: "white",
        marginLeft: "20px",

    },
    "& .flag-dropdown": {
        boder: "none !important",
        width: '65px',
    },
    "& .react-tel-input .flag-dropdown": {
        boder: "none !important",
    },
    "& .selected-flag": {
        width: '60px !important',
        boder: "none !important",
    },
    "& .PhoneInput": {
        position: "relative",
        "& .PhoneError": {
            fontSize: "18px",
            color: "red",
            margin: "6px 14px",
            position: "absolute"
        },
    },
    '&:hover': {
        border: '1px solid #000',
        opacity: .8,
        borderRadius: "4px"
    },
    "&:focus-within": {
        border: '2px solid #3f51b5',
        borderRadius: "6px",
    }
});
const MainDiv = styled('div')({
    "& .selectlegal":{
        fontFamily: 'DM Sans',
    },
    "& .selectlegal .react-select__control" :{
        padding: "8px",
        borderRadius: "8px",
        fontSize: "16px",
        background: "#fff",
        backgroundColor: "#fff",
        height: "53px",
        "& .css-16xfy0z-control" :{
            padding: "8px",
            borderRadius: "8px",
            fontSize: "16px",
            backgroundColor: "none",
            height: "53px", 
            
          },
      },
      
      
    "&.selectlegal .react-select__control--is-focused": {
        border: "2px solid #3f51b5",
        backgroundColor: "#fff",
        height: "53px",
      },
    "& .selectlegal .react-select__control:hover": {
        border: "1px solid #000",
        backgroundColor: "#fff",
        height: "53px",
      },
    width: '100%',
    height: '100%',
    display: 'flex',
    "@media (max-width:991px)": {
        display: "block",
    },
    "& .MuiSelect-selectMenu:focus": {
        backgroundColor: "#fff"
    },
    "& .sidebar": {
        width: '355px',
        height: '100vh',
        position: 'fixed',
        top: '0',
        left: '0',
        borderRight: "1px solid #C3D1FF",
        "@media (max-width: 1100px)": {
            width: "355px"
        },
        "@media (max-width: 991px)": {
            width: '100vw',
            height: '80px'
        }
    },
    "& .rightbar": {
        width: 'calc(100% - 355px)',
        padding: "20px 70px",
        marginLeft: '355px',
        marginTop: '100px',
        "@media (max-width: 1100px)": {
            padding: '20 40px',
            width: 'calc(100% - 355px)',
            marginLeft: '355px',
        },
        "@media (max-width: 991px)": {
            width: 'auto !important',
            marginLeft: '0px',
            marginTop: '50px',
            padding: "10px 50px",
        }
    },
    "& .userdetailbox": {
        width: 'auto',
        margin: "0px auto",
    },
    "& .profilebox": {
        height: '156px',
        display: 'flex',
        borderBottom: '1px solid #D9D9D9',
        "@media (max-width: 450px)": {
            height: '120px',
        },
    },
    "& .profileImg": {
        display: 'flex',
        justifyContent: "center",
        width: "80%",
    },
    "& .profiletext": {
        fontSize: '16px',
        fontWeight: 400,
        color: '#8C8C8C',
        fontFamily: 'DM Sans',
    },
    "& .userimg": {
        height: '120px',
        width: '120px',
        border: '4px solid #ECF1FF',
        borderRadius: '50%',
        "@media (max-width: 450px)": {
            height: '80px',
            width: '80px',
        },
    },
    "& .positionrel": {
        position: "relative",
    },
    "& .imagenot": {
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        border: '4px solid #ECF1FF',
        padding: '70px',
        position: "absolute",
        borderRadius: '50%',
        "@media (max-width: 450px)": {
            padding: '50px'
        },
        '&::after': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: '#000',
            opacity: 0.7,
            borderRadius: '50%',
            zIndex: 0
        }
    },
    "& .edittxt": {
        color: '#fff',
        position: 'absolute',
        top: '80px',
        left: '31px',
        fontSize: '14px',
        fontWeight: 700,
        fontFamily: 'DM Sans',
        zIndex: 1,
        "@media (max-width: 450px)": {
            position: 'absolute',
            top: '50px',
            left: '11px',
        },
    },
    "& .usericon": {
        position: 'absolute',
        top: '52px',
        left: '55px',
        fontSize: '22px',
        fontWeight: 700,
        fontFamily: 'DM Sans',
        color: '#fff',
        zIndex: 1,
        "@media (max-width: 450px)": {
            position: 'absolute',
            top: '28px',
            left: '37px',
        },
    },
    '& .topbox': {
        display: 'flex',
        width: "auto",
        height: '70px',
        justifyContent: 'space-between',
        "@media (max-width: 991px)": {
            width: "auto !important",
        },
    },
    "& .titleuser": {
        height: '30px',
        width: '230px',
        fontSize: '22px',
        fontWeight: 400,
        fontFamily: "DM Sans",
        color: '#000',
        "@media (max-width: 450px)": {
            width: '200px',
            fontSize: '16px',
        },
    },
    "& .titlbtn": {
        height: '44px',
        width: '163px',
        border: '1px solid #355EE7',
        borderRadius: '8px',
        padding: '10px 12px',
        fontSize: '14px',
        fontWeight: 400,
        color: '#355EE7',
        fontFamily: 'DM Sans',
        "@media (max-width: 450px)": {
            height: '40px',
            width: '140px',
            padding: '7px 9px',
        },
    },
    "& .titlbtn1": {
        height: '44px',
        width: '163px',
        border: '1px solid #355EE7',
        borderRadius: '8px',
        padding: '10px 12px',
        fontSize: '14px',
        fontWeight: 400,
        color: '#fff',
        backgroundColor: "#355EE7",
        fontFamily: 'DM Sans',
        textTransform: "none",
        '&:hover': {
            border: '2px solid #B9C5FF',
            boxShadow: 'none !important'
        },
        "@media (max-width: 450px)": {
            height: '40px',
            width: '140px',
            padding: '7px 9px',
        },
    },
    "& .detailsbox": {
        display: 'flex',
        marginTop: '20px',
        borderBottom: '1px solid #D9D9D9',
        "@media (max-width: 600px)": {
            display: 'block'
        },
    },
    "& .personaldetailtext": {
        width: '20%',
        fontSize: '16px',
        fontWeight: 400,
        fontFamily: 'DM Sans',
        color: '#8C8C8C',
        "@media (max-width: 600px)": {
            width: '100%',
            marginBottom: '20px'
        },
    },
    "& .personalform": {
        width: '80%',
        "@media (max-width: 600px)": {
            width: '100%'
        },
    },
    "& .MuiFormHelperText-root.Mui-error": {
        marginLeft: "0px"
    },
    "& .fullName": {
        width: '100%',
        display: 'flex',
        marginBottom: '10px',
        "@media (max-width: 650px)": {
            display: 'block',
            width: '100%'
        }
    },
    "& .firstname": {
        width: "45%",
        marginRight: "10px",
        "@media (max-width: 650px)": {
            width: "91% !important",
            marginBottom: '15px',
        },
    },

    "& .lastname": {
        width: "45%",
        "@media (max-width: 650px)": {
            width: "91% !important",
        },
    },
    "& .lawFirm": {
        width: "91%",
        marginBottom: '15px',
    },
    "& .country": {
        width: "91%",
        marginBottom: '15px',
    },
    "& .PhoneNumer": {
        width: "91%",
        marginBottom: '15px',
    },
    "& .Email": {
        width: "91%",
        marginBottom: '15px',
    },
    "& .Password": {
        width: "91%",
        marginBottom: '15px',
    },
    "& .passtext": {
        color: "#434343",
        fontSize: '14px',
        fontWeight: 400,
        fontFamily: 'DM Sans',
    },
    "& .changepasstext": {
        color: "#6200EA",
        fontSize: '14px',
        fontWeight: 700,
        fontFamily: 'DM Sans',
        cursor: 'pointer',
        marginTop: '4px',
    },

    "& .accountsbox": {
        display: 'flex',
        marginTop: '20px',
        marginBottom: '30px',
        paddingBottom: '20px',
        borderBottom: '1px solid #D9D9D9',
        "@media (max-width: 600px)": {
            display: 'block',
            marginTop: '10px',
        },
    },
    "& .accountdetailtext": {
        width: '20%',
        color: "#8C8C8C",
        fontSize: '16px',
        fontWeight: 400,
        fontFamily: 'DM Sans',
        "@media (max-width: 600px)": {
            width: '100%',
            marginBottom: '20px'
        },
    },
    "& .accountsactions": {
        width: '80%',
        display: 'flex', gap: "150px",
        "@media (max-width: 600px)": {
            width: '100%',
            display: 'block'
        },
    },
    "& .accountsactions1": {
        width: '80%',
        "@media (max-width: 600px)": {
            width: '100%',
        },
    },
    "& .logouttxt": {
        color: "#434343",
        fontSize: '14px',
        fontWeight: 700,
        fontFamily: 'DM Sans',
        "@media (max-width: 600px)": {
            paddingBottom: '15px'
        },
    },
    "& .deletetxt": {
        color: "#434343",
        fontSize: '14px',
        fontWeight: 700,
        fontFamily: 'DM Sans',
        marginRight: '100px',
        "@media (max-width: 600px)": {
            width: '100%',
            marginRight: '10px',
        },
    },
    "& .errorOutline": {
        '& .MuiFormControl-root': {
            '& fieldset': {
                borderColor: 'red 1px solid',
            },
        },
    },
    "& .offcanvascss": {
        position: 'fixed',
        top: '0px !important',
        bottom: '0px !important',
        left: '0% !important',
        width: '355px !important',
        height: '100vh !important',
        backgroundColor: '#fff',
        zIndex: 9999,
    },
    "& .offcanvasbackdrop": {
        position: "fixed",
        top: '0px',
        left: '355px',
        width: 'calc(100% - 355px)',
        height: '100vh',
        zIndex: 999,
        backgroundColor: '#000',
        opacity: 0.5,
    },
    "& .uploadbox": {
        width: '220px',
        height: '150px',
        border: '1px dashed #355EE7',
        borderRadius: '16px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '12px',
        flexDirection: 'column',
        "@media (max-width: 1300px)": {
            width: '200px',
            padding: '10px',
        },
        "@media (max-width: 1100px)": {
            width: '170px',
            padding: '10px',
        },
    },
    "& .customtxt": {
        background: '#ECF1FF',
        color: '#595959',
        fontSize: '16px',
        fontWeight: 400,
        fontFamily: 'DM Sans',
        padding: '10px 8px',
        height: '28px',
        width: '230px',
        border: '1px solid #D9D9D9',
        borderRadius: '8px',
        margin: '10px 0px',
        "@media (max-width: 1300px)": {
            width: '200px',
            height: '28px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '10px',
        },
        "@media (max-width: 1100px)": {
            width: '170px',
            height: '28px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '10px',
        },
    },
    "& .roletxt": {
        border: "0px solid #D9D9D9",
        paddingLeft: '13px',
        borderLeft: 'none',
        width: "25%",
        height: '56px',
        display: 'flex', justifyContent: 'space-between',
        alignItems: 'center',
        background: '#fff',
        color: '#000',
        fontSize: '16px',
        fontWeight: 400,
        fontFamily: 'DM Sans',
        borderBottomRightRadius: '8px !important',
        borderTopRightRadius: '8px !important',
        borderTopLeftRadius: "0px !important",
        borderBottomLeftRadius: "0px !important",
        "@media (max-width: 1200px)": {
            width: "30%",
        },
        "@media (max-width: 1000px)": {
            width: "35%",
        },
    },
    "& .invitebtn": {
        color: '#FFFFFF',
        fontSize: '16px',
        fontFamily: 'DM Sans',
        borderRadius: '8px',
        marginLeft: "2%",
        height: '55px',
        width: "120px",
        textTransform: "none",
        fontWeight: "400",
        cursor: "pointer",
        border: "none",
        '&:hover': {
            border: '2px solid #B9C5FF',
            boxShadow: 'none !important'
        },
        "&:disabled": {
            border: "none !important",
            boxShadow: "none",
            textTransform: "none",
            "&:hover": {
                border: 'none !important',
                boxShadow: 'none !important',
            }
        }
    },
    "& .lawboxinvite": {
        marginBottom: '15px',
        display: 'flex',
        width: '100%'

    },

    "& .statusError": {
        display: 'flex', alignItems: 'center',
        fontFamily: "DM Sans", fontWeight: "400", fontSize: "16px", textAlign: "left",
        color: "#DC2626", marginBottom: "20px"

    },
    "& .statusErrorLogin": {
        backgroundColor: "#FEE2E2",
        padding: "20px",
        marginBottom: "20px",
        borderLeft: "4px solid #F87171",
        color: "#F87171",
        borderRadius: '4px',
        fontFamily: "DM Sans",
        fontSize: "14px",
        fontWeight: 400

    },
    "& .statusErrorLoginAlert": {
        backgroundColor: "#FEE2E2",
        borderLeft: "4px solid #F87171",
        color: "#F87171",
        borderRadius: '4px',
        fontFamily: "DM Sans",
        fontSize: "14px",
        fontWeight: 400,
        width: "404px",
        position: "absolute",
        left: "30px",
        bottom: "8px"


    },
    "& .selectCountry": {
        width: '100%',
        padding: '15px',
        border: '0.4px solid #c5cbc6',  // Keeps the border
        borderRadius: '4px',
        fontSize: '16px',
        marginBottom: '8px',
        outline: 'none',
        backgroundColor: '#fff',
        backgroundImage: 'url("data:image/svg+xml;utf8,<svg fill=\'%23c5cbc6\' height=\'20\' viewBox=\'0 0 24 24\' width=\'20\' xmlns=\'http://www.w3.org/2000/svg\'><path d=\'M7 10l5 5 5-5z\'/></svg>")',
        backgroundRepeat: 'no-repeat',
        backgroundPositionX: '95%',
        backgroundPositionY: 'center',
        appearance: 'none',
        fontWeight: 400,
        fontFamily: "DM Sans",
        '&:hover': {
            border: '1px solid #000',
            borderRadius: "8px"
        },
        "&:focus": {
            border: '2px solid #3f51b5;',
            borderRadius: "8px"
        },
        "&:disabled": {
            boxShadow: "none",
            textTransform: "none",
            color:"#BFBFBF !important",
            "&:hover":{
                border:'1px solid #BFBFBF',
              boxShadow: 'none !important',
            }
          }

    },

    "& .greenInvite": {
        marginRight: '250px',
        "@media (max-width: 1100px)": {
            marginRight: '200px',
        },
        "@media (max-width: 1000px)": {
            marginRight: '150px',
        },
        "@media (max-width: 600px)": {
            marginRight: '100px',
        },
    }
})

const DrawerBox = styled('div')({
    minHeight: '100vh',
    width: "355px",
    "& .logobox": {
        display: 'flex', justifyContent: "start",
        height: "76px", marginTop: '20px',
        marginLeft: '20px'
    },
    "& .logoimag":
    {
        height: '42px',
        width: '118px',
    },
    "& .settingtext": {
        color: '#262626', fontSize: '22px',
        marginLeft: '6px',
        fontWeight: 400, fontFamily: 'DM Sans',
    },
    "& .settingbox":
    {
        display: 'flex',
        height: "65px",
        cursor: 'pointer'
    },

    "& .lefticon":
    {
        marginTop: '4px',
        marginLeft: '30px',
    },
    "& .listbox": {
        height: '110px',
        borderRadius: '8px',
        "&:hover": {
            backgroundColor: "#ECF1FF",
            color: "#355EE7",
            "& .icondrawer2":
            {
                backgroundColor: "#ECF1FF",
                color: "#355EE7",
            },
            "& .icondrawer3":
            {
                backgroundColor: "#ECF1FF",
                color: "#355EE7",
            },
            "& .listtile": {
                color: "#355EE7",
            }
        },
    },
    "& .listbox1": {
        height: '110px',
        backgroundColor: '#ECF1FF',
        borderRadius: '8px',
    },
    "& .icondrawer1": {
        marginBottom: '45px',
        color: '#355EE7',
        marginLeft: '18px',
    },
    "& .icondrawer3": {
        marginLeft: '18px',
        marginBottom: '25px',
        color: '#434343',
    },
    "& .icondrawer2": {
        marginLeft: '18px',
        marginBottom: '42px',
        color: '#434343',
    },

    "& .listtext": {
        fontSize: '14px',
        color: '#334155',
        fontFamily: 'DM Sans',
        fontWeight: 400,
    },
    "& .listtile1": {
        fontSize: "16px",
        color: '#355EE7',
        fontFamily: 'DM Sans',
        fontWeight: 400,
        cursor: 'pointer'
    },
    "& .listtile":
    {
        fontSize: "16px",
        fontFamily: 'DM Sans',
        fontWeight: 400,
        cursor: 'pointer',
        color: '#434343',
    },
    "@media (max-width: 1100px)":
    {
        zIndex: 999,
        width: '355px',
    },
    "@media (max-width: 991px)":
    {
        zIndex: 999,
        width: '355px',
        display: 'none',
    },
});

const ButtonBox = styled('div')(
    {
        display: 'none',
        "@media (max-width: 991px)":
        {
            width: '100%',
            display: 'block', minHeight: '70px',
            "& .buttonbox":
            {
                height: '70px', width: '100vw'
            },

            "& .iconbtn":
            {
                marginTop: '10px', marginLeft: '10px'
            }
        }
    })

// Customizable Area End
