// Customizable Area Start
Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Documentation";
exports.labelBodyText = "Documentation Body";

exports.btnExampleTitle = "CLICK ME";
exports.rename="Rename";
exports.delete="Delete";
exports.document="Upload Document";
exports.folder="Add Folder";
exports.documents="Documents";
exports.deleteFolder="Delete Folder";
exports.addFolder="Add Folder";
exports.cancelText="Cancel";
exports.createText="Create";
exports.renameText="Rename";
exports.deleteText="Delete";
exports.closeText="CLOSE";
exports.renameFile="RENAME";
exports.deleteFile="DELETE";
exports.yesModal='YES';
exports.noModal='NO';
exports.file='Do you want to delete the File ?';
exports.files='Do you want check file ?';
exports.deletefolder='Do you want to delete the Folder ?';
exports.folderDocument='THIS FOLDER IS EMPTY !';
exports.documentation="DATA NOT FOUND!";
exports.GET="GET";
exports.POST="POST";
exports.PUT="PUT";
exports.DELETE="DELETE";

// Customizable Area End
