// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

import { Alert, Platform } from "react-native";
import DocumentPicker from 'react-native-document-picker';
import { getStorageData } from "../../../framework/src/Utilities";
import * as helpers from "../../../framework/src/Helpers";


const navigation = require("react-navigation");

interface Account {
  id: string;
  type: string;
  attributes: {
    activated: boolean;
    country_code: string;
    email: string;
    full_phone_number: string;
    first_name: string;
    last_name: string;
    phone_number: string;
    type: string | null;
    created_at: string;
    updated_at: string;
    device_id: string | null;
    unique_auth_id: string;
    firm: string | null;
  };
}

interface FileType {
  id: number;
  name: string;
  account_id: number | null;
  account_name: string | null;
  account_profile_image: string | null;
  byte_size: number;
  record_type: string;
  record_id: number;
  created_at: string;
  filename: string;
  content_type: string;
  url: string;
}

interface FolderAttributes {
  folder_name: string;
  parent_id: number | null;
  is_archived: boolean;
  account_id: number | null;
  task_id: number;
  archived_at: string | null;
  created_at: string;
  restore_id: string | null;
  profile: string | null;
  account: { data: Account | null };
  subfolder: { data: FolderType }[];
  files: FileType[];
}

interface FolderType {
  id: string;
  type: string;
  attributes: FolderAttributes;
}

export interface GalleryMedias {
  url?: string,
  blob_id?: number,
  filename?: string,
  gallery_id?: string | number,
  id?: number,
  attributes: {
    folder_name: string,
    gallery?: {
      id?: number
    }
  }
}

export interface FolderItemsData {
  id?: number,
  type?: string,
  attributes: { folder_name: string }
}

export interface DataItems {
  id: string,
  attributes: {
    gallery_medias: Array<GalleryMedias>;
    folder: {
      data: Array<FolderItemsData>
    }
  }
}

interface FileMenu {
  id?: number | string,
  file?: string,
  blob_id?: number,
  fileAndFolderName?: string,
}

export interface AllDocumentsResponse {
  data: Array<DataItems>;
}

export interface AllDocumentData {
  gallery_id?: string,
  filename?: string,
  blob_id?: string
  id?: number,
  attributes: {
    folder_name: string,
    gallery?: {
      id?: number
    }
  }
}

export interface MapData {
  id: number
  url: string,
  blob_id: number,
  filename: string,
  gallery_id: number,
  attributes: {
    gallery_medias: Array<GalleryMedias>;
    folder_name: string,
    gallery: {
      id: number
    }
  }
}

interface ApiCallData {
  contentType?: string,
  method: string,
  endPoint: string,
  body?: Object,
  type?: string
}

interface SuccessResponse {
  message: string;
}

interface ErrorResponse {
  errors: string;
}

interface TokenExpire {
  errors: Array<TokenAlert>
}

interface TokenAlert {
  token: string
}

// For Mobile
export interface Galleryobjectuniqueid {
  gallery_type: string;
  account_id: number;
}
export interface Innerobjectoffolder {
  folder_name: string;
  folder_type: string;
  gallery: Galleryobjectuniqueid;
  folder_medias: Array<GalleryMedia>
}
export interface Foldermedia {
  type: string;
  attributes: Innerobjectoffolder
}
export interface FolderInterface {
  data: Array<Foldermedia>
}
export interface GalleryMedia {
  url: string;
  blob_id: number;
  filename: string;
}
export interface Innerobject {
  gallery_type: string;
  gallery_medias: Array<GalleryMedia>
  folder: FolderInterface;
}
export interface Versionitems {
  data: Array<ObjectMedia>;
}
export interface ObjectMedia {
  type: string;
  attributes: Innerobject;
}

export interface Itemmedia {
  concat(data: Itemmedia[]): Itemmedia[];
  url: string,
  blob_id: number,
  filename: string
}

export interface DocumentlistItemapi {
  data: Array<GetAllDocumentlistapi>;
}

export interface GetAllDocumentlistapi {
  type: string;
  attributes: Objectattributes;
}

export interface Objectattributes {
  folder: ObjectOffolder;
  gallery_medias: Itemmedia;
}

export interface ObjectOffolder {
  data: Itemmedia[];
}

interface GetAllDocumentResponseJson {
  data: Array<Versionitems>;
  message: string
}

interface Errorobject {
  errors: string;
}

interface Tokenexpire {
  errors: Array<Tokenalert>
}
export interface Tokenalert {
  token: string
}

export interface Itemmedia {
  url: string;
  blob_id: number;
  filename: string;
  id: string,
  type: string,
  attributes: Folderobject
}
export interface Folderobject {
  folder_name: string;
  folder_type: string;
  gallery: Uniqeidnumberobject;
  folder_medias: Array<Fileinfolder>;
  cover_photo: null;
}
export interface Fileinfolder {
  url: string;
  blob_id: number;
  filename: string;
}

export interface Uniqeidnumberobject {
  gallery_type: string,
  account_id: number
}

export const configJSON = require("./config");

export interface Props {
  navigation: typeof navigation;
  id: string;
}

export interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  loader: boolean;
  fileMenu: FileMenu | null;
  addFolderModal: boolean;
  renameModal: FileMenu | boolean | null;
  deleteModal: null | FileMenu;
  uploadedFiles: [];
  folderName: string;
  renameValue: string;
  menuOption: string;
  folderMenuOptions: boolean;
  allDocuments: Array<FolderItemsData | GalleryMedias>,
  folderId: number;
  galleryId: number | string;
  blobId: number;
  isSizeExceeded:boolean

  // For Mobile

  modalDots: boolean;
  folderModal: boolean;
  folderNameNative: string;
  cancel: boolean;
  create: boolean;
  rename: boolean;
  delete: boolean;
  modalRename: boolean;
  fileRenameText: string;
  deleteModalnative: boolean;
  fileDeleteText: string;
  documentList: Array<Itemmedia>;
  blobIdNumber: number;
  floderId: string;
  documentId: string;
  isFolder: boolean;
  isDelete: boolean;
  isloading: boolean;
  yesNoModal: boolean;
  yesNoModalFile: boolean;
  responseFailureData: boolean;

  mobileOpen : boolean;
  closeOption: false;
  openOption: false;
  openOptionIndex: number;
  openOptionIndexF: number;
  closebox: boolean;

  LoadedFiles: File[],
  LoadedFilesPreview: any
  uploadProgress: any,
  uploadingFiles: any,
  Files: any,
  uploadMimetype: any,
  uploadResponse:any,
  upLoading:boolean,
  isDeleting:boolean,
  isDeleteID:string,
  folders:any,
  files:any,
  viewMode:string,
  moreOptions:boolean,
  infoModal:boolean,
  dateInput1:string,
  inFilterModal:boolean,
  isDateUploadedRange:boolean,
  filterTypeValue:string,
  isFilterType:boolean,
  filterOwnerValue:string,
  filterOwner:any
  isFilterOwner:boolean,
  filterUploadedDateRangeStartValue:string,
  filterUploadedDateRangeEndValue:string,
  filterList:any,
  filterOwnerSearchResult:any,
  searchTerm:string,
  moveFolders:FolderType[],
  folderLevel:any;
  selectedFolderForMove:string|number;
  moveModalPopUp:boolean;
  renameModalPopUp:boolean;
  selectedForRename:string;
  newFolderModalPopUp:boolean;
  deleteModalPopUp:boolean;
  isNoPermission:boolean;
  taskID:string|number;
  taskIdFrom:string;
  uploadFolderId:string|number;
  archiveFolder:any;
  folderPath:any;
  isArchiveFolder:boolean;
  addNewFolderInput:string;
  deleteFileOrFolderDetails:{
    id:string|number,
    shouldDelete:boolean,
    type:string,
    name:string
  };
  workLoading:boolean;
  renameInput:string;
  downloadUrl:string;
  itemList:any;
  pageLoading:boolean;
  openOptionIndexL:number;
  isWrongFileMimeType: boolean;
  showUndo: boolean;
}

interface SS {
  id: string;
}

export default class DocumentationController extends BlockComponent<
  Props,
  S,
  SS
> {
  uploadControllers: { [key: string]: AbortController } = {};

  addFolderApiCallId: string = "";
  renameFileApiCallId: string = "";
  deleteFileApiCallId: string = "";
  allDocumentsApiCallId: string = "";
  renameFolderApiCallId: string = "";
  deleteFolderApiCallId: string = "";
  uploadFilesAndFoldersApiCallId: string = "";
  moveFolderMargin:number = 0;
  getAllFoldersApiCallId: string = "";
  getAllSubFoldersApiCallId: string = "";
  getTaskDetailsApiCallId: string = "";
  getSearchDetailsApiCallId: string = "";
  postRootFolderApiCallId: string = "";
  postSubFolderApiCallId: string = "";
  postArchiveFolderApiCallId: string = "";
  putRenameFolderApiCallId: string = "";
  deleteFileOrFolderApiCallId: string = "";
  deleteAllItemsFromArchive: string = "";
  PostBulkUploadEndPointCallId: string = "";
  putMoveToArchiveFolderApiCallId: string = "";
  putRestoreFileOrFolderApiCallId: string = "";
  putMoveFileApiCallId: string = "";
  putMoveFolderApiCallId: string = "";

  permissionErrorText: string = "";
  timeout: any= 0;
  currentFolderId: string | number = "";
  currentFileFolderType: string = "";
  

  // For Mobile

  getAllDocumentsApiCallId: string = "";
  createDocumentApiCallId: string = "";
  getDeleteFileApiCallId: string = "";
  getPatchRenameFileApiCallId: string = "";
  getAddFolderApiCallId: string = "";
  getDeleteFolderApiCallId: string = "";
  getRenameFolderApiCallId: string = "";
  willFocusScreen!: string

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
    ];

    this.state = {
      isSizeExceeded:false,
      isWrongFileMimeType: false,
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      deleteModal: null,
      allDocuments: [],
      addFolderModal: false,
      uploadedFiles: [],
      loader: false,
      folderName: "",
      renameModal: null,
      galleryId: 0,
      renameValue: "",
      menuOption: "",
      folderMenuOptions: false,
      folderId: 0,
      fileMenu: null,
      blobId: 0,

      // For Mobile

      modalDots: false,
      folderModal: false,
      cancel: false,
      create: false,
      rename: false,
      delete: false,
      folderNameNative: '',
      modalRename: false,
      documentList: [],
      documentId: '512',
      blobIdNumber: 0,
      fileRenameText: '',
      deleteModalnative: false,
      fileDeleteText: '',
      floderId: '',
      isFolder: false,
      isDelete: false,
      isloading: false,
      yesNoModal: false,
      yesNoModalFile: false,
      responseFailureData: false,

      mobileOpen: false,
      closeOption: false,
      openOption: false,
      openOptionIndex: -1,
      openOptionIndexF: -1,
      closebox: false,

      LoadedFiles: [],
      LoadedFilesPreview: [],
      uploadProgress: [],
      uploadingFiles: {},
      Files: [],
      uploadMimetype: [],
      uploadResponse:[],
      upLoading:false,
      isDeleting:false,
      isDeleteID:"",
      folders:[],
      files:[],
      viewMode:'grid',
      moreOptions:false,
      infoModal:false,
      dateInput1:'',
      inFilterModal:false,
      isDateUploadedRange:false,
      filterTypeValue:'',
      isFilterType:false,
      filterOwnerValue:'',
      filterOwner:[],
      isFilterOwner:false,
      filterUploadedDateRangeStartValue:'',
      filterUploadedDateRangeEndValue:'',
      filterList:[
        {type:''},
        {owner:''},
        {dateUploaded:''},
        {dateUploadedRange:''}
      ],
      filterOwnerSearchResult:[],
      searchTerm:'',
      moveFolders:[],
      folderLevel:[],
      selectedFolderForMove:'',
      moveModalPopUp:false,
      renameModalPopUp:false,
      selectedForRename:'',
      newFolderModalPopUp:false,
      deleteModalPopUp:false,
      isNoPermission:false,
      taskID:'',
      taskIdFrom:'',
      uploadFolderId:'',
      archiveFolder:{},
      folderPath:[],
      isArchiveFolder:false,
      addNewFolderInput:'',
      renameInput:'',
      deleteFileOrFolderDetails:{id:'',shouldDelete:false,type:'',name:''},
      workLoading:false,
      downloadUrl:'',
      itemList:[],
      pageLoading:false,
      openOptionIndexL:-1,
      showUndo: false,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      // For Mobile
      if (responseJson.status === 500) {
        this.showAlert("Error", "Internal Server Error");
        return;
      }

      if (responseJson && !responseJson.errors) {
        this.getAllSuccessFunctionCallBack(apiRequestCallId, responseJson);
      } else if (responseJson && responseJson.errors) {
        this.getAllErrorFunctionCallBack(apiRequestCallId, responseJson);
      }

      // For Web

      switch(apiRequestCallId){
        case this.getAllFoldersApiCallId : this.checkResponseForgetAllFoldersApiCallId(responseJson) ; break;
        case this.PostBulkUploadEndPointCallId : this.checkResponsePostBulkUploadEndPointCallId(responseJson); break;
        case this.postRootFolderApiCallId : this.checkResponsepostRootFolderApiCallId(responseJson); break;
        case this.postArchiveFolderApiCallId : this.checkResponsepostArchiveFolderApiCallId(responseJson); break;
        case this.postSubFolderApiCallId : this.checkResponsepostSubFolderApiCallId(responseJson); break;
        case this.getAllSubFoldersApiCallId : this.checkResponsegetAllSubFoldersApiCallId(responseJson); break;
        case this.putMoveToArchiveFolderApiCallId : this.checkResponseputMoveToArchiveFolderApiCallId(responseJson); break;
        case this.putRestoreFileOrFolderApiCallId : this.checkResponseputRestoreFileOrFolderApiCallId(responseJson); break;
        case this.putRenameFolderApiCallId : this.checkResponseputRenameFolderApiCallId(responseJson); break;
        case this.putMoveFileApiCallId : this.checkResponseputMoveFileApiCallId(responseJson); break;
        case this.putMoveFolderApiCallId : this.checkResponseputMoveFolderApiCallId(responseJson); break;
        case this.deleteFileOrFolderApiCallId : this.checkResponsedeleteFileOrFolderApiCallId(responseJson); break;
        case this.deleteAllItemsFromArchive : this.checkResponsedeleteAllItemsFromArchive(responseJson); break;
      }

      
    }
  }

  async componentWillUnmount() {
    clearTimeout(this.timeout)
  }
  
  async componentDidMount(): Promise<void> {
    if (Platform.OS !== 'web') {
      this.willFocusScreen = this.props.navigation.addListener(
        'willFocus',
        () => {
          this.getAllDocumentList()
        }
      );
    }

    if(helpers.getOS() === "web") {
      const taskID = sessionStorage.getItem("taskID")
      if(taskID) {
        this.setState({taskID: taskID, pageLoading: true}, () => this.getAllFolders())
      }
    }
  }

  async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>) {
    if (this.state.uploadProgress.every((item:any) => item.progress === 100)) {
      if(!this.state.upLoading){
      this.uploadBulkAttachements(this.state.taskID,this.state.uploadFolderId)
      }
      
    }
  }

  handleBackNavigationOrDashboardNavigation = (type: "back" | "dashboard") => async() => {
    const navigationObj: Record<typeof type, Record<string, string>> = {
      "back": {
        client: "TaskList",
        lawyer: "TaskListLawyer",
        lawfirm_admin: "TaskListLawfirm"
      },
      "dashboard": {
        client: "ClientDashboard",
        lawyer: "LawyerDashboard",
        lawfirm_admin: "DashBoard"
      },
    }

    const userDetail = await getStorageData("token", true)
    let baseNav = navigationObj[type].client;
    let userRole = "client"
    
    if(userDetail) {
      userRole = userDetail.user_role
      baseNav = navigationObj[type][userRole]
    }

    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage),
    baseNav);
    message.addData(getName(MessageEnum.NavigationPropsMessage),
      this.props);

    this.send(message);

    sessionStorage.removeItem("taskID")
  }

  checkResponseForgetAllFoldersApiCallId = async (responseJson: any) => {
    this.setState({pageLoading:true})

    if (responseJson.data.length === 0) {
      await this.createRootFolder(this.state.taskID);
      return;
    }

    let allFolders = responseJson.data[0].attributes.subfolder.filter((item: any) => {
      return item.data.attributes.folder_name !== "Archive Section";
    });

    let moveFolders = responseJson.data

    let archiveFolder = responseJson.data[0].attributes.subfolder.filter((item: any) => {
      return item.data.attributes.folder_name === "Archive Section";
    });

    let files = responseJson.data[0].attributes.files

    let gridFolders = responseJson.data[0].attributes.subfolder.map((x: any) => {
      return { type: 'folder', name: x.data.attributes.folder_name, createdAt: x.data.attributes.created_at, owner: x.data.attributes.account?.data?.attributes.first_name }
    })


    let gridFiles = responseJson.data[0].attributes.files.map((x: any) => {
      return { type: 'file', name: x.filename, createdAt: x.created_at, owner: x.account_name }
    })

    this.setState({
      pageLoading:false,
      uploadFolderId:responseJson.data[0].id,
      itemList: [...gridFolders, ...gridFiles],
      files,
      folders: allFolders,
      moveFolders: moveFolders,
      archiveFolder: archiveFolder[0],
      folderPath: [...this.state.folderPath, { id: responseJson.data[0].id, folderName: 'Document Folder' }]
    })


  }

  checkResponsePostBulkUploadEndPointCallId = async (responseJson:any) =>{

    if(responseJson.data){
      let fid = await this.state.folderPath[this.state.folderPath.length - 1];
      this.getAllSubFolders(this.state.taskID, fid.id)
      await this.cancelFilesData()
    }
    else{
      await this.cancelFilesData()
    }
  
  }

  checkResponsepostRootFolderApiCallId = async (responseJson:any)=>{
    if(responseJson.data){
      await this.createArchiveFolder(this.state.taskID)
    }
  }

  checkResponsepostArchiveFolderApiCallId = async (responseJson:any)=>{

    if(responseJson.data){
      await this.getAllFolders()
    }
  
  }

  checkResponsepostSubFolderApiCallId = async (responseJson:any)=>{

    if(responseJson.data){
      this.setState({addNewFolderInput:'',newFolderModalPopUp:false})
      let fid = await this.state.folderPath[this.state.folderPath.length - 1];
      await this.getAllSubFolders(this.state.taskID, fid.id)
    }
  
  }

  checkResponsegetAllSubFoldersApiCallId = async (responseJson:any)=>{
    this.setState({pageLoading: false})
    if(responseJson.data){
      let allFolders = responseJson.data.attributes.subfolder.filter((item:any) => {
        return item.data.attributes.folder_name !== "Archive Section";
      });
      let files = responseJson.data.attributes.files
      this.setState({
        folders: allFolders,
        files,
        uploadFolderId:responseJson.data.id,
      })
    }
  }

  checkResponseputMoveToArchiveFolderApiCallId = async (responseJson:any)=>{
    if (responseJson.message === "You don't have permission to move this folder" || responseJson.message === "You don't have permission to move this file"){
      this.permissionErrorText = `You cannot delete this ${this.state.deleteFileOrFolderDetails.type}, you do not have permissions on items created by other users.`
      this.setState({ isNoPermission:true })
      this.handleCloseDeleteModalPopUp()
      return;
    }

    if (responseJson.message === "File moved successfully" || responseJson.message === "Folder moved successfully") {
      let fid = this.state.folderPath[this.state.folderPath.length - 1];
      this.currentFileFolderType = this.state.deleteFileOrFolderDetails.type
      this.currentFolderId = this.state.deleteFileOrFolderDetails.id
      this.setState({ workLoading: false , showUndo: true})
      this.timeout = setTimeout(() => {
        this.setState({showUndo: false})
      }, 5000)
      this.handleCloseDeleteModalPopUp()
      this.getAllSubFolders(this.state.taskID, fid.id)
    }
  
  }

  checkResponseputRestoreFileOrFolderApiCallId = async (responseJson:any)=>{

    if(responseJson.message === "You don't have permission to move this folder" || responseJson.message === "You don't have permission to move this file" ) {
      this.permissionErrorText = `You cannot restore this ${this.state.deleteFileOrFolderDetails.type}, you do not have permissions on items created by other users.`
      this.setState({ deleteFileOrFolderDetails: { id: '', name: '', type: '', shouldDelete: false }, isNoPermission: true })
      return;
    }

    if(responseJson.message === "File moved successfully" || responseJson.message === "Folder moved successfully"){
      let fid = await this.state.folderPath[this.state.folderPath.length - 1];
      this.setState({workLoading: false, showUndo: false})
      clearTimeout(this.timeout)
      this.getAllSubFolders(this.state.taskID, fid.id)
    }
  
  }

  checkResponseputRenameFolderApiCallId = async (responseJson:any)=>{

    if(responseJson.message === "Folder renamed successfully." || responseJson.message === "File renamed successfully."){
      let fid = await this.state.folderPath[this.state.folderPath.length - 1];
      this.setState({ workLoading: false, deleteFileOrFolderDetails:{id:"",shouldDelete:false,type:'',name:''} })
      this.handleRenameModalPopUp()
      this.getAllSubFolders(this.state.taskID, fid.id)
    }
    else if(responseJson.error === "Folder not found or unauthorized access." || responseJson.error === "File not found or unauthorized access."){
      this.permissionErrorText = `You cannot rename this ${this.state.deleteFileOrFolderDetails.type}, you do not have permissions on items created by other users.`
      this.setState({ workLoading: false, deleteFileOrFolderDetails:{id:"",shouldDelete:false,type:'',name:''} })
      this.handleRenameModalPopUp()
      this.setState({isNoPermission:true})
    }
  
  }

  handleShowMoveNoPermissionModal = () => {
    this.permissionErrorText = `You cannot move this ${this.state.deleteFileOrFolderDetails.type}, you do not have permissions on items created by other users.`
    this.handleMoveModalPopUp()
    this.setState({workLoading: false, isNoPermission: true, deleteFileOrFolderDetails:{ id:"", shouldDelete:false, type:'', name:'' }})
  }

  checkResponseputMoveFileApiCallId = async (responseJson:any)=>{

    if(responseJson.error === "You don't have permission to move this file.") {
      this.handleShowMoveNoPermissionModal()
      return;
    }

    if(responseJson.message === "File moved successfully." || responseJson.message === "Folder moved successfully."){
      let fid = await this.state.folderPath[this.state.folderPath.length - 1];
      this.setState({workLoading:false, deleteFileOrFolderDetails:{ id:"", shouldDelete:false, type:'', name:'' }})
      this.handleMoveModalPopUp()
      this.getAllSubFolders(this.state.taskID, fid.id)
    }

  
  }

  checkResponseputMoveFolderApiCallId = async (responseJson:any)=>{

    if(responseJson.message === "You can only move your own folders") {
      this.handleShowMoveNoPermissionModal()    
      return;
    }

    if(responseJson.message === "File moved successfully." || responseJson.message === "Folder moved successfully."){
      let fid = await this.state.folderPath[this.state.folderPath.length - 1];
      this.handleMoveModalPopUp()
      this.setState({workLoading:false, deleteFileOrFolderDetails:{ id:"", shouldDelete:false, type:'', name:'' }})
      this.getAllSubFolders(this.state.taskID, fid.id)
    }

  
  }

  checkResponsedeleteFileOrFolderApiCallId = async (responseJson:any)=>{

    if(responseJson.error === "Folder not found or unauthorized access." || responseJson.error === "File not found or unauthorized access.") {
      
      this.permissionErrorText = `You cannot delete this ${this.state.deleteFileOrFolderDetails.type}, you do not have permissions on items created by other users.`
      this.setState({
        isNoPermission: true,
        deleteFileOrFolderDetails: { id: '',name: '', type: '', shouldDelete: false }
      })
    }

    if(responseJson.message === "File deleted successfully." || responseJson.message === "Folder deleted successfully."){
      let fid = await this.state.folderPath[this.state.folderPath.length - 1];
      this.setState({workLoading:false})
      this.getAllSubFolders(this.state.taskID, fid.id)
    }
  
  }

  checkResponsedeleteAllItemsFromArchive = async (responseJson:any)=>{

    let fid = await this.state.folderPath[this.state.folderPath.length - 1];
    this.setState({workLoading:false})
    this.getAllSubFolders(this.state.taskID, fid.id)
  
  }

  getToken = async () =>{
    const CloginTokens: string = await getStorageData("clientTokes");
    const signupTokens: string = await getStorageData("userdetails");
    const loginTokens: string = await getStorageData("token");
    const metaSignUpToken = JSON.parse(signupTokens);
    const metaLoginToken = JSON.parse(loginTokens);
    const maintokens = this.determineMainToken(metaSignUpToken, metaLoginToken);
    return maintokens?.serialized_data?.meta?.token || maintokens?.meta?.token || CloginTokens
  }

  handleFolderMoreOption = (index:number,x:any) =>{
    this.handleOpenOption(index)
    this.setState(
      {
        deleteFileOrFolderDetails:
        {id:x.data.id,shouldDelete:false,type:'folder',name:x.data.attributes.folder_name}
      }
    )
  }

  handleFileMoreOption = (index:number,x:any) =>{
    this.handleOpenOptionF(index)
    this.setState(
      {deleteFileOrFolderDetails:
        {id:x.id,shouldDelete:false,type:'file',name:x.filename},downloadUrl:x.url
      }
    )
  }

  cancelFilesData = async () =>{
    this.setState({ 
      LoadedFiles: [], 
      LoadedFilesPreview: [], 
      uploadProgress: [],
      upLoading:false
    })
  }

  formatDateTime = (dateTime: string) => {
    const date = new Date(dateTime);

    const monthNames = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];

    const day = String(date.getUTCDate()).padStart(2, '0'); 
    const month = monthNames[date.getUTCMonth()]; 
    const year = date.getUTCFullYear();

    return `${day} ${month} ${year}`;
  }

  formatDateTimeA = (dateTime: string) => {
    const date = new Date(dateTime);

    const monthNames = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];

    const day = String(date.getUTCDate()).padStart(2, '0'); 
    const month = monthNames[date.getUTCMonth()]; 
    const year = date.getUTCFullYear();

    return `${day}/${month}/${year}`;
  }

  handleListMoreoptions = (i:number,x:any) =>{
    if(x.data){
      this.setState(
        {
        deleteFileOrFolderDetails:
          {id:x.data.id,shouldDelete:false,type:'folder',name:x.data.attributes.folder_name},
        closebox:true,
        infoModal:false,
        openOptionIndexL: this.state.openOptionIndexL === i ? -1 : i 
        }
      )
    }
    else{
      this.setState(
        {
          deleteFileOrFolderDetails:
            {id:x.id,shouldDelete:false,type:'file',name:x.filename},
          downloadUrl:x.url,
          closebox:true,
          infoModal:false,
          openOptionIndexL: this.state.openOptionIndexL === i ? -1 : i 
        }
      )
    }
  }

  openFolder = async (x:any) =>{
    this.setState({
      folderPath:[...this.state.folderPath, {id:x.data.id, folderName:x.data.attributes.folder_name}],
      pageLoading: true,
      folders: [],
      files: [],
    })
    this.getAllSubFolders(this.state.taskID,x.data.id)
  }

  updateFolderPath = async (updatedLegalServiceType:any) =>{
    this.setState({folderPath: [...updatedLegalServiceType]});
  }

  handleFileFolderPath = async (index:number) => {
    const updatedLegalServiceType = this.state.folderPath.slice(0, index+1);
    
    await this.updateFolderPath(updatedLegalServiceType)

    let fid = await this.state.folderPath[this.state.folderPath.length - 1];
    this.setState({pageLoading: true})
    this.getAllSubFolders(this.state.taskID,fid.id)
  };

  handleChangeAddNewFolderInputValue = (e:any) =>{
    this.setState({addNewFolderInput:e.target.value})
  }

  handleRenameInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ renameInput: event.target.value })
  }

  handleCreateFolder = async () => {
    if (this.state.addNewFolderInput) {
      let fid = await this.state.folderPath[this.state.folderPath.length - 1] 
      this.createSubFolders(fid.id, this.state.taskID, this.state.addNewFolderInput)
    }
  }


  apiCall = async (data: ApiCallData) => {
    const { contentType, method, endPoint, body, type } = data;
    const header = {
      "Content-Type": contentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    body && type != 'formData' ?
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      )
      : requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  //  For Mobile
  getAllSuccessFunctionCallBack = (apiRequestCallId: string, responseJson: GetAllDocumentResponseJson & DocumentlistItemapi) => {
    if (apiRequestCallId === this.getAllDocumentsApiCallId) {
      this.getAllDocListSuccesCallBack(responseJson);
    }
    else if (apiRequestCallId === this.createDocumentApiCallId) {
      this.createDocumentSuccesCallBack(responseJson);
    }
    else if (apiRequestCallId === this.getDeleteFileApiCallId) {
      this.deleteFileSuccesCallBack(responseJson);
    }
    else if (apiRequestCallId === this.getPatchRenameFileApiCallId) {
      this.renameNewFileSuccesCallBack(responseJson);
    }
    else if (apiRequestCallId === this.getAddFolderApiCallId) {
      this.addNewFolderSuccesCallBack(responseJson);
    }
    else if (apiRequestCallId === this.getDeleteFolderApiCallId) {
      this.deleteFolderSuccesCallBack(responseJson);
    }
    else if (apiRequestCallId === this.getRenameFolderApiCallId) {
      this.renameFolderSuccesCallBack(responseJson);
    }
  }

  getAllErrorFunctionCallBack = (apiRequestCallId: string, responseJson: Tokenexpire & string & Errorobject) => {
    if (apiRequestCallId === this.getAllDocumentsApiCallId) {
      this.getAllDocListFailureCallBack(responseJson);
    }
    else if (apiRequestCallId === this.createDocumentApiCallId) {
      this.createDocumentFailureCallBack(responseJson);
    }
    else if (apiRequestCallId === this.getDeleteFileApiCallId) {
      this.deleteFileFailureCallBack(responseJson);
    }
    else if (apiRequestCallId === this.getPatchRenameFileApiCallId) {
      this.renameNewFileFailureCallBack(responseJson);
    }
    else if (apiRequestCallId === this.getAddFolderApiCallId) {
      this.addNewFolderFailureCallBack(responseJson);
    }
    else if (apiRequestCallId === this.getDeleteFolderApiCallId) {
      this.deleteFolderFailureCallBack(responseJson);
    }
    else if (apiRequestCallId === this.getRenameFolderApiCallId) {
      this.renameFolderFailureCallBack(responseJson);
    }
  }

  //  For Web

  getAllFolders= async () => {
    const header = {
      "Content-Type": "application/json",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAllFoldersApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_documentstorage/folders?task_id=${this.state.taskID}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getAllSubFolders= async (taskId:string|number,folderId:string|number) => {
    const header = {
      "Content-Type": "application/json",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAllSubFoldersApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_documentstorage/folders/all_subfolders?folder_id=${folderId}&task_id=${taskId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  createRootFolder = async (taskId:string|number ) =>{
    const header = {
      "Content-Type": "application/json",
    };

    const raw = JSON.stringify({
      "folder_name": "root_folder",
      "task_id": taskId
    });

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.postRootFolderApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_documentstorage/folders`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      raw
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.POST
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  createSubFolders = async (folderId:string|number,taskId:string|number,folderName:string) =>{
    const header = {
      "Content-Type": "application/json",
      "token":await this.getToken(),
    };

    const raw = JSON.stringify({
      "folder_name": folderName,
      "task_id": taskId
    });

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.postSubFolderApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_documentstorage/folders/create_sub_folders?folder_id=${folderId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      raw
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.POST
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  createArchiveFolder = async (taskId:string|number) =>{
    const header = {
      "Content-Type": "application/json",
    };

    const raw = JSON.stringify({
      "folder_name": "Archive Section",
      "task_id": taskId
    });

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.postArchiveFolderApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_documentstorage/folders/archive_folder`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      raw
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.POST
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  renameFileFolder = async (fId:string|number,type:string,newName:string) =>{

    this.setState({workLoading:true})

    const header = {
      "Content-Type": "application/json",
      "token":await this.getToken()
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.putRenameFolderApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_documentstorage/folders/${fId}/rename_file_folder?type=${type}&new_name=${newName}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PUT
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  moveFolder = async (fId:string|number,folID:string|number) =>{

    this.setState({workLoading:true})

    const header = {
      "Content-Type": "application/json",
      "token":await this.getToken()
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.putMoveFolderApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_documentstorage/folders/move_folders?from_folder_id=${fId}&to_folder_id=${folID}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PUT
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  moveFile = async (fId:string|number,folID:string|number) =>{

    this.setState({workLoading:true})

    const header = {
      "Content-Type": "application/json",
      "token":await this.getToken()
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.putMoveFileApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_documentstorage/folders/move_file?file_id=${fId}&to_folder_id=${folID}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PUT
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  moveToArchiveFileFolder = async (taskId:string|number,from_folder_id:string|number,type:string) =>{

    this.setState({workLoading:true})

    const header = {
      "Content-Type": "application/json",
      "token":await this.getToken()
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.putMoveToArchiveFolderApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_documentstorage/folders/move_to_archive_file_folder?task_id=${taskId}&${type === 'file' ? 'file_id' : 'from_folder_id'}=${from_folder_id}&type=${type}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PUT
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  
  }

  deleteFileOrFolder = async (fId:string|number,type:string) =>{

    this.setState({workLoading:true})

    const header = {
      "token":await this.getToken()
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteFileOrFolderApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_documentstorage/folders/${fId}/delete_file_folder?type=${type}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.DELETE
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleDeleteFileFolder = () =>{
    this.deleteFileOrFolder(this.state.deleteFileOrFolderDetails.id,this.state.deleteFileOrFolderDetails.type)
  }

  handleRestoreFileOrFolder = () =>{
    this.restoreFileOrFolder(this.state.taskID,this.state.deleteFileOrFolderDetails.id,this.state.deleteFileOrFolderDetails.type)
  }

  handleUndoClick = () => {
    this.timeout = 0;
    this.setState({workLoading: true})
    this.restoreFileOrFolder(this.state.taskID, this.currentFolderId, this.currentFileFolderType)
  }

  restoreFileOrFolder = async (taskId:string|number,fromId:string|number,type:string) =>{

    const header = {
      "Content-Type": "application/json",
      "token":await this.getToken()
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.putRestoreFileOrFolderApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_documentstorage/folders/restore_file_folder?task_id=${taskId}&${type === 'file' ? 'file_id' : 'from_folder_id'}=${fromId}&type=${type}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PUT
    );

    this.currentFileFolderType = ""
    this.currentFolderId = ""

    runEngine.sendMessage(requestMessage.id, requestMessage);
  
  }

  handleEmptyArchive = () =>{
    this.emptyArchive(this.state.archiveFolder.data.id,this.state.taskID)
  }

  emptyArchive = async (fId:string|number,taskId:string|number) =>{

    this.setState({workLoading:true})

    const header = {
      "token":await this.getToken()
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteAllItemsFromArchive = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_documentstorage/folders/${fId}/empty_archive?task_id=${taskId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.DELETE
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  determineMainToken = (
    metaSignUpToken: { id?: string; data?: { id?: string }; serialized_data?: { meta?: { token?: string } }; meta?: { token?: string } },
    metaLoginToken: { id?: string; data?: { id?: string }; serialized_data?: { meta?: { token?: string } }; meta?: { token?: string } }
  ): { id?: string; data?: { id?: string }; serialized_data?: { meta?: { token?: string } }; meta?: { token?: string } } | null => {
    if (metaSignUpToken?.data?.id) {
      if (metaLoginToken?.id && metaSignUpToken.data.id !== metaLoginToken.id) {
        return metaLoginToken;
      }
      return metaSignUpToken;
    }

    if (metaLoginToken?.id) {
      return metaLoginToken;
    }

    return null;
  };

  uploadBulkAttachements = async (taskId:string|number,folderId:string|number)=>{
    
    const allNotUploading = this.state.uploadProgress.length > 0 ? 
    this.state.uploadProgress.every((obj:any) => obj.isUploading === false):false;
    if(!allNotUploading){
      return;
    }
    this.setState({upLoading:true})
    const formdata = new FormData(); 
    const filesArray = Array.from(this.state.LoadedFiles);
    filesArray.forEach((file) => {
        formdata.append("file[]", file, file.name);
    });
    const header = {
      token: await this.getToken()
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.PostBulkUploadEndPointCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_documentstorage/folders/upload_file?folder_id=${folderId}&task_id=${taskId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.POST
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleNoPermissionModalPopUp = () => {
    this.setState({isNoPermission:!this.state.isNoPermission})
  }

  handleOpenDeleteModalPopUp = () =>{
    this.setState({deleteModalPopUp:true})
  }

  handleCloseDeleteModalPopUp = () => {
    this.setState({
      deleteModalPopUp:false,
      deleteFileOrFolderDetails:{id:'',shouldDelete:false,type:'',name:''},
      workLoading: false,
    })
  }

  handleDeleteFileORFolder = () =>{
    this.moveToArchiveFileFolder(this.state.taskID,this.state.deleteFileOrFolderDetails.id,this.state.deleteFileOrFolderDetails.type)
  }

  handleNewFolderModalPopUp = () => {
    this.setState({newFolderModalPopUp:!this.state.newFolderModalPopUp})
  }

  handleRenameModalPopUp = () => {
    this.setState({renameModalPopUp:!this.state.renameModalPopUp, renameInput:this.state.deleteFileOrFolderDetails.name, workLoading: false})
  }

  handleRenameFileFolder = () =>{
    this.renameFileFolder(this.state.deleteFileOrFolderDetails.id,this.state.deleteFileOrFolderDetails.type,this.state.renameInput)
  }

  handleMoveModalPopUp = () => {
    this.setState({moveModalPopUp:!this.state.moveModalPopUp,selectedFolderForMove:'',folderLevel:[], workLoading: false})
  }

  handleMoveFileFolder = () =>{
    if(this.state.deleteFileOrFolderDetails.type === 'file'){
      this.moveFile(this.state.deleteFileOrFolderDetails.id,this.state.selectedFolderForMove)
    }
    else if(this.state.deleteFileOrFolderDetails.type === 'folder'){
      this.moveFolder(this.state.deleteFileOrFolderDetails.id,this.state.selectedFolderForMove)
    }
  }

  handleSelectedFolderForMove = (folderId:string | number) =>{
    this.setState({selectedFolderForMove:folderId})
  }

  toggleFolderLevel = (x:any) =>{
    this.setState((prevState:any) => {
      const { folderLevel } = prevState;
      if (folderLevel.includes(x)) {
        return { folderLevel: folderLevel.filter((num:any) => num !== x) };
      } else {
        return { folderLevel: [...folderLevel, x] };
      }
    });
  }

  showSubMenu = (event:any,x:any) =>{
    if(event.target.parentElement.nextElementSibling.classList.contains('showSubMenu')){
    if(event.target.parentElement.nextElementSibling.style.display === 'none'){
      event.target.parentElement.nextElementSibling.style.display = 'block'
      this.toggleFolderLevel(x)
    }else{
      event.target.parentElement.nextElementSibling.style.display = 'none'
      this.toggleFolderLevel(x)
    }
  }
  }

  mainSubParent = (event:any,x:any) =>{
    if(event.target.parentElement.parentElement.nextElementSibling.classList.contains('showSubMenu')){
      if(event.target.parentElement.parentElement.nextElementSibling.style.display === 'none'){
        event.target.parentElement.parentElement.nextElementSibling.style.display = 'block'
        this.toggleFolderLevel(x)
      }else{
        event.target.parentElement.parentElement.nextElementSibling.style.display = 'none'
        this.toggleFolderLevel(x)
      }
    }
  }

  mainSubParentParent = (event:any,x:any) =>{
    if(event.target.parentElement.parentElement.parentElement.nextElementSibling.classList.contains('showSubMenu')){
      if(event.target.parentElement.parentElement.parentElement.nextElementSibling.style.display === 'none'){
        event.target.parentElement.parentElement.parentElement.nextElementSibling.style.display = 'block'
        this.toggleFolderLevel(x)
      }else{
        event.target.parentElement.parentElement.parentElement.nextElementSibling.style.display = 'none'
        this.toggleFolderLevel(x)
      }
    }
  }

  showMainSub = (event:any,x:any) =>{
    if(event.target.nextElementSibling.classList.contains('showSubMenu')){
      if(event.target.nextElementSibling.style.display === 'none'){
        event.target.nextElementSibling.style.display = 'block'
        this.toggleFolderLevel(x)
      }else{
        event.target.nextElementSibling.style.display = 'none'
        this.toggleFolderLevel(x)
      }
    }
  }

  showSubSub = (event:any,x:any) =>{
    if(event.target.parentElement.classList.contains('showSubMenu')){
      if(event.target.nextElementSibling.style.display === 'none'){
        event.target.nextElementSibling.style.display = 'block'
        this.toggleFolderLevel(x)
      }else{
        event.target.nextElementSibling.style.display = 'none'
        this.toggleFolderLevel(x)
      }
    }
  }

  toggleSubMenu = (event: any, x: any) => {
    if (event.target.parentElement.classList.contains('selectFolder')) {
      return;
    } else if (event.target.classList.contains('mainFolder')) {
      this.showMainSub(event, x);
    } else if (event.target.parentElement.parentElement.parentElement.classList.contains('mainFolder')) {
      this.mainSubParentParent(event, x);
    } else if (event.target.parentElement.parentElement.classList.contains('mainFolder')) {
      this.mainSubParent(event, x);
    } else if (event.target.parentElement.classList.contains('mainFolder')) {
      this.showSubMenu(event, x);
    } else if (event.target.classList.contains('subFolder')) {
      this.showSubSub(event, x);
    } else if (event.target.parentElement.parentElement.parentElement.classList.contains('subFolder')) {
      this.mainSubParentParent(event, x);
    } else if (event.target.parentElement.parentElement.classList.contains('subFolder')) {
      this.mainSubParent(event, x);
    } else if (event.target.parentElement.classList.contains('subFolder')) {
      this.showSubMenu(event, x); 
    }
  }

  handleInfoModal = () =>{
    this.setState({infoModal:!this.state.infoModal,closebox:true})
  }

  handleMoreOptions = () =>{
    this.setState({moreOptions:!this.state.moreOptions})
  }

  handleViewMode = (mode:string) => {
    this.setState({viewMode:mode})
  }

  handleDrawerToggle = () => {
    this.setState({ mobileOpen: true });
  }

  handleclosesidebar = () => {
    this.setState({ mobileOpen: false });
  }

  handleOpenOption = (index:any) => {
    this.setState(prevState => ({
      openOptionIndex: prevState.openOptionIndex === index ? null : index 
    }));
    this.setState({closebox:true,infoModal:false})
  }
  handleOpenOptionF = (index:any) => {
    this.setState(prevState => ({
      openOptionIndexF: prevState.openOptionIndexF === index ? null : index 
    }));
    this.setState({closebox:true,infoModal:false})
  }
  handleCloseBox=()=>{
    if(this.state.closebox){
      this.setState({openOptionIndexL:-1, openOptionIndexF:-1,openOptionIndex:-1,closebox:false,infoModal:false,inFilterModal:false,filterUploadedDateRangeStartValue:''})
    }
  }

  formatFileSize(bytes: number): string {
    if (bytes < 1024) return `${bytes} B`;
    else if (bytes < 1048576) return `${(bytes / 1024).toFixed(2)} KB`;
    else if (bytes < 1073741824) return `${(bytes / 1048576).toFixed(2)} MB`;
    else return `${(bytes / 1073741824).toFixed(2)} GB`;
  }

  handleDelete = (fileName: string) => {
    this.removeFileData(fileName);
  };

  isValidFileMimeType = (files: FileList) => {
    const filesMimeType = new Set([
      "image/png",
      "image/jpg",
      "image/jpeg",
      "video/mp4",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.ms-excel",
      "application/vnd.ms-powerpoint",
      "application/vnd.openxmlformats-officedocument.presentationml.presentation",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/msword",
      "application/pdf"
    ])
    const doesFileMimeTypeExist = Array.from(files).some(file => filesMimeType.has(file.type))
    return doesFileMimeTypeExist
  }

  handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const maxFileSize = 52428800;
      const isValidFileMimeType = this.isValidFileMimeType(files)
      if(!isValidFileMimeType) {
        this.setState({isWrongFileMimeType: true})
        return;
      }
      for (const file of files) {
        if (file.size < maxFileSize) {
          this.processFiles(files);
        }
        else{
          this.setState({isSizeExceeded:true})
        }
      }
    }
  };

  handleDragOver = (event: React.DragEvent<HTMLElement>) => {
    event.preventDefault()
    event.stopPropagation()
  }

  handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation()
    const files = event.dataTransfer.files;
    const doesFileMimeTypeExist = this.isValidFileMimeType(files)
    if(!doesFileMimeTypeExist) {
      this.setState({isWrongFileMimeType: true})
      return;
    }
    if (files && files.length > 0) {
      const maxFileSize = 52428800;
      for (const file of files) {
        if (file.size < maxFileSize) {
          this.processFiles(files);
        }else{
          this.setState({isSizeExceeded:true})
        }
      }
    }
  };

  processFiles = (files: any) => {
    const fileArray = [...files];
    const previews = fileArray.map((file) => ({
      file: URL.createObjectURL(file),
      fileName: file.name,
      fileSize: file.size,
    }));

    const uploadProgress = fileArray.map((file) => ({
      fileName: file.name,
      progress: 0,
      isUploading: true,
    }));

    this.setState(
      { LoadedFiles: [...this.state.LoadedFiles, ...fileArray], LoadedFilesPreview: [...this.state.LoadedFilesPreview, ...previews], uploadProgress: [...this.state.uploadProgress, ...uploadProgress] },
      () => {
        this.uploadFiles(fileArray);
      }
    );
  };

  uploadFiles = (files: File[]) => {
    files.forEach((file) => {
      const controller = new AbortController();
      this.uploadControllers[file.name] = controller;
      const updateProgress = (progress: number) => {
        this.setState((prevState) => ({
          uploadProgress: prevState.uploadProgress.map((p: any) =>
            p.fileName === file.name ? { ...p, progress } : p
          ),
        }));
      };
      this.simulateUpload(file, updateProgress, controller);
    });
  };

  simulateUpload = (
    file: File,
    updateProgress: (progress: number) => void,
    controller: AbortController
  ) => {
    let progress = 0;
    const interval = setInterval(() => {
      if (progress < 100) {
        progress += 1;
        updateProgress(progress);
      } else {
        clearInterval(interval);
        this.setState((prevState) => ({
          uploadProgress: prevState.uploadProgress.map((p: any) =>
            p.fileName === file.name ? { ...p, isUploading: false } : p
          ),
        }));
      }
    }, 20);

    controller.signal.addEventListener("abort", () => {
      clearInterval(interval);
      updateProgress(0);
      this.removeFileData(file.name);
    });
  };

  cancelUpload = (fileName: string) => {
    const controller = this.uploadControllers[fileName];
    if (controller) {
      controller.abort();
      delete this.uploadControllers[fileName];
    }
  };

  removeFileData = (fileName: string) => {
    this.setState((prevState) => ({
      ...this.state,
      LoadedFiles: prevState.LoadedFiles.filter((file) => file.name !== fileName),
      LoadedFilesPreview: prevState.LoadedFilesPreview.filter((preview: any) => preview.fileName !== fileName),
      uploadProgress: prevState.uploadProgress.filter((progress: any) => progress.fileName !== fileName),
    }));
  };



  // API Success and Failure Callbacks for Web



  // API Success and Failure Callbacks for Mobile

  getAllDocListSuccesCallBack =  (responseJson: DocumentlistItemapi) => {
    this.setState({ isloading: false}
      ,  () => {
        this.setState({ documentList: responseJson.data[0].attributes.gallery_medias.concat(responseJson.data[0].attributes.folder.data) });
      }
    )
  };

  getAllDocListFailureCallBack =  (responseJson: string) => {
    this.setState({ isloading: false })
    Alert.alert("@@@ ==== errorResponseJson", responseJson);
  };

  createDocumentSuccesCallBack =  (responseJson: GetAllDocumentResponseJson) => {
    this.setState({ isloading: false })
    if (responseJson.data) {
      this.getAllDocumentList()
    }
  };

  createDocumentFailureCallBack =  (responseJson: Errorobject) => {
    this.setState({ isloading: false })
    if (responseJson.errors) {
      this.showAlert('Alert', responseJson.errors)
    }
  };
 
  deleteFileSuccesCallBack =  (responseJson: GetAllDocumentResponseJson) => {
    this.setState({ yesNoModalFile: false, deleteModalnative: false, blobIdNumber: 0, fileDeleteText: '', isloading: false })
    if (responseJson.message) {
      this.showAlert('Alert', responseJson.message)
    }
    this.getAllDocumentList()
  };

  deleteFileFailureCallBack =  (responseJson: string) => {
    this.setState({ isloading: false })
    Alert.alert("@@@ ==== deleteFileFailureCallBack", responseJson);
  };

  renameNewFileSuccesCallBack = async (responseJson: GetAllDocumentResponseJson) => {
    this.setState({ isloading: false, modalRename: false, blobIdNumber: 0, fileRenameText: '' })
    if (responseJson.message) {
      this.showAlert('Alert', responseJson.message)
    }
    this.getAllDocumentList()
  };

  renameNewFileFailureCallBack =  (responseJson: Errorobject) => {
    this.setState({ isloading: false, modalRename: false, })
    if (responseJson.errors) {
      this.showAlert('Alert', responseJson.errors)
    }
  };

  addNewFolderSuccesCallBack =  (responseJson: GetAllDocumentResponseJson) => {
    this.setState({ folderModal: false }, () => {
      this.getAllDocumentList()
    })
    this.setState({ isloading: false })
  };

  addNewFolderFailureCallBack =  (responseJson: string) => {
    this.setState({ isloading: false })
  };

  deleteFolderSuccesCallBack =  (responseJson: GetAllDocumentResponseJson) => {
    this.setState({ yesNoModal: false, isloading: false, deleteModalnative: false, floderId: '', fileDeleteText: '' })
    this.showAlert('Alert', responseJson.message)
    this.getAllDocumentList()
  };

  deleteFolderFailureCallBack =  (responseJson: string) => {
    this.setState({ isloading: false })
  };

  renameFolderSuccesCallBack =  (responseJson: GetAllDocumentResponseJson) => {
    this.setState({ isloading: false, modalRename: false, floderId: '', folderNameNative: '', })
    this.showAlert('Alert', responseJson.message)
    this.getAllDocumentList()
  };

  renameFolderFailureCallBack =  (responseJson: string) => {
    this.setState({ isloading: false, modalRename: false, })
  };

  getAllDocumentList = async () => {
    this.setState({ isloading: true })
    this.getAllDocumentsApiCallId = await this.apiCall({
      contentType: "application/json",
      method: 'GET',
      endPoint: `bx_block_documentation/gallery_documents/account_gallery?gallery_type=documents`,
      body: "",
      type: ""
    });
  }

  DeleteFileApiCell = async () => {
    this.setState({ isloading: true })
    let apidata = {
      data: {
        attributes: {
          blob_id: this.state.blobIdNumber
        }
      }
    }
    this.getDeleteFileApiCallId = await this.apiCall({
      contentType: "application/json",
      method: 'DELETE',
      endPoint:`/bx_block_documentation/gallery_documents/${this.state.documentId}/destroy_media_file`,
      body: apidata,
      type: ""
    });
  }

  RenameFileApiCell = async () => {
    this.setState({ isloading: true })
    const apidata = {
      data: {
        attributes: {
          blob_id: this.state.blobIdNumber,
          filename: this.state.fileRenameText
        }
      }
    }
    this.getPatchRenameFileApiCallId = await this.apiCall({
      contentType: "application/json",
      method: 'PATCH',
      endPoint:`bx_block_documentation/gallery_documents/${this.state.documentId}/update_file_name`,
      body: apidata,
      type: ""
    });
  }

  addFolderApiCell = async () => {
    if (this.state.folderNameNative.trim().length == 0) {
      this.showAlert('Alert', 'Please Enter File Name');
      return
    }
    this.setState({ isloading: true })
    let apidata = {
      data: {
        attributes: {
          gallery_id: this.state.documentId,
          folder_name: this.state.folderNameNative
        }
      }
    }

    this.getAddFolderApiCallId = await this.apiCall({
      contentType: "application/json",
      method: 'POST',
      endPoint: `bx_block_documentation/folder_documents`,
      body: apidata,
      type: ""
    });
  }

  RenameFolderApiCell = async () => {
    if (this.state.fileRenameText.trim().length == 0) {
      Alert.alert('Alert', 'Please Enter File Name');
      return
    }
    this.setState({ isloading: true })
    let apidata = {
      data: {
        attributes: {
          gallery_id: this.state.documentId,
          folder_name: this.state.fileRenameText,
        }
      }
    }
    this.getRenameFolderApiCallId = await this.apiCall({
      contentType: "application/json",
      method: 'PATCH',
      endPoint:`bx_block_documentation/folder_documents/${this.state.floderId}/update_folder_name`,
      body: apidata,
      type: ""
    });
  }

  DeleteFolderApiCell = async () => {
    this.setState({ isloading: true })
    let apidata = {
      data: {
        attributes: {
          gallery_id: this.state.documentId,
        }
      }
    }
    this.getDeleteFolderApiCallId = await this.apiCall({
      contentType: "application/json",
      method: 'DELETE',
      endPoint:`bx_block_documentation/folder_documents/${this.state.floderId}`,
      body: apidata,
      type: ""
    });
  }
  closeSizeModal=()=>{
    this.setState({isSizeExceeded:false, isWrongFileMimeType: false})
  }

  threeDotsTouchable = (item: Itemmedia) => item.type !== 'folder' ? this.onRequestClose(item.blob_id, item.filename) : this.onFolderRenameandDelete(item.id, item.attributes.folder_name)

  onRequestClose = (Item_id: number, fileName: string) => this.setState({ blobIdNumber: Item_id, fileRenameText: fileName, fileDeleteText: fileName, isFolder: false, isDelete: false }, () => { this.setState({ modalDots: true, cancel: false, delete: false, rename: false }) })

  onFolderRenameandDelete = (Items_id: string, fileName: string) => this.setState({ floderId: Items_id, fileRenameText: fileName, fileDeleteText: fileName, isFolder: true, isDelete: true }, () => { this.setState({ modalDots: true, cancel: false, delete: false, rename: false }) })

  folderClickButton = () => this.setState({ folderModal: true, create: false, cancel: false, folderNameNative: '' })

  modalDotsTrue = () => this.setState({ modalDots: false })

  modalOverMOdal = () => this.setState({ modalDots: false, modalRename: true, })

  modalDeleteOverMOdal = () => this.setState({ modalDots: false, deleteModalnative: true, })

  navigateScreen =  (item: Itemmedia) => { this.props.navigation.navigate('DocumentationFolder', { item: item, documentId: this.state.documentId }) }

  changeTextFolderModal = (text: string) => { this.setState({ folderNameNative: text }) }

  RenameChangeFileModal = (text: string) => { this.setState({ fileRenameText: text }) }

  changeDeleteFileModal = (text: string) => { this.setState({ fileDeleteText: text }) }

  cancelFolderModal = () => this.setState({ folderModal: false, cancel: true, create: false })

  cancelDeleteFile = () => this.setState({ deleteModalnative: false, cancel: true, delete: false })

  cancelFolderRename = () => this.setState({ modalRename: false, cancel: true, rename: false })

  createFolderModal = () => { this.setState({ create: true, cancel: false, }, () => this.addFolderApiCell()) }

  responseRenameFile = () => { this.setState({ rename: true, cancel: false, }, () => this.RenameFileApiCell()) }

  responseRenameFolder = () => { this.setState({ rename: true, cancel: false, }, () => this.RenameFolderApiCell()) }

  responseDeleteFile = () => { this.setState({ delete: true, cancel: false, deleteModalnative: false, yesNoModalFile: true }) }

  responseDeleteFolder = () => { this.setState({ delete: true, cancel: false, deleteModalnative: false, yesNoModal: true }) }

  responseDeleteFolderYesNo = () => { this.DeleteFolderApiCell() }

  responseDeleteFolderYesNoFile = () => { this.DeleteFileApiCell() }

  uplaodCsvFile = async () => {
    try {
      const isResponse = await DocumentPicker.pick({
        type: [DocumentPicker.types.pdf]
      })
    }
    catch (error) {
    }
  };

  yesNoModalFalse = () => this.setState({ yesNoModal: false, yesNoModalFile: false })

}

// Customizable Area End
