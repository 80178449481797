import React, { useState } from "react";
import { IconButton, Radio } from "@material-ui/core";
import { ExpandMoreRounded, Folder as FolderIcon, ExpandLessRounded } from "@material-ui/icons";
import { S } from "../DocumentationController"

const UnCheckedIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.5" y="0.5" width="23" height="23" rx="11.5" stroke="#4872FF" />
    </svg>
)

const CheckedIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="24" height="24" rx="12" fill="#355EE7" />
        <path fillRule="evenodd" clipRule="evenodd" d="M18.0732 6.50764C18.6218 6.87953 18.765 7.62571 18.3931 8.17427L12.2916 17.1743C12.0924 17.4682 11.7732 17.6588 11.4199 17.6947C11.0666 17.7307 10.7156 17.6083 10.4612 17.3606L5.76265 12.7851C5.28784 12.3228 5.27776 11.563 5.74013 11.0882C6.2025 10.6134 6.96223 10.6033 7.43704 11.0657L11.1091 14.6416L16.4066 6.82753C16.7785 6.27896 17.5247 6.13574 18.0732 6.50764Z" fill="white" />
    </svg>
)

type FolderProps = {
    subFolder: S["moveFolders"][number];
    depth?: number;
    selectedFolderId: string | number;
    disabled?: boolean;
    onChange: (folderId: string | number) => void;
    currentFolderId: string;
}

export default function Folder({ 
    subFolder, 
    depth = 0, 
    disabled = false,
    selectedFolderId,
    onChange,
    currentFolderId, 
}: FolderProps) {
    const [isExpanded, setIsExpanded] = useState(false)

    let paddingLeft = 12 + depth * 22;

    const isDisabled = currentFolderId === `${subFolder.id}` || disabled

    return (
        <div className="li" >
            <div style={{ paddingLeft: paddingLeft, borderTopWidth: subFolder.attributes.folder_name === "root_folder" ? 0 : 1 }} >
                {
                    subFolder.attributes.subfolder.length > 0 ? (
                        <IconButton data-testid={`expand-${subFolder.id}`} onClick={() => setIsExpanded(prev => !prev)} style={{ position: "relative", top: 0, right: 0 }} >
                            {isExpanded ? <ExpandLessRounded htmlColor="#262626" /> : <ExpandMoreRounded htmlColor="#262626" />}
                        </IconButton>
                    ) : <span style={{ height: 24, width: 24, display: "inline-block" }} />
                }
                <FolderIcon htmlColor="#BFBFBF" />
                <span>{subFolder.attributes.folder_name === "root_folder" ? "Document Folder" : subFolder.attributes.folder_name}</span>
                <Radio data-testid={`check-${subFolder.id}`} disabled={isDisabled} name={subFolder.attributes.folder_name} aria-checked={`${subFolder.id}` === selectedFolderId} checked={`${subFolder.id}` === selectedFolderId} onChange={() => onChange(`${subFolder.id}`)} checkedIcon={<CheckedIcon />} icon={<UnCheckedIcon />} className="radio" />
            </div>
            {
                (subFolder.attributes.subfolder.length > 0 && isExpanded) ? (
                    <div className="ul" style={{ padding: 0, width: "100%" }} >
                        {
                            subFolder.attributes.subfolder
                                .filter((z) => z.data.attributes.folder_name !== "Archive Section")
                                .map((folder) => (
                                    <Folder 
                                        key={folder.data.id} 
                                        subFolder={folder.data} depth={depth + 1} 
                                        disabled={isDisabled}
                                        selectedFolderId={selectedFolderId}
                                        onChange={onChange}
                                        currentFolderId={currentFolderId}
                                    />
                                ))
                        }
                    </div>
                ) : null
            }
        </div>
    )
}
